import React from 'react'


export default function Privacy() {
    return (
     <div>
        <small className="font-xs opacity-60">Last Updated: Sun 7 April 2024</small>
        
        <h1 className="font-bold text-xl pt-2 pb-4">1. Section Title</h1>

        <p className="pb-4">
            Paragraph
            <br /><br />
            line break
        </p>

        <h2 className="py-4 font-semibold text-lg">Subject Title</h2>

        <ul className="pb-4">
            <li className="py-1">List Item</li> 
        </ul>
     </div>
    )
}