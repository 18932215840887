/** vendor */
import React, { useState } from 'react'
import Loading from '../UI/Loading';

export default function ContactForm() { 
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        if (!name || !message || !email) {
            // @todo
            setLoading(false)
        }
    }

    return (
        <>
            {
                (loading) ? <Loading /> : 
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-black text-sm font-bold mb-2" htmlFor="name">
                            Name
                        </label>
                        <input 
                            className="appearance-none border border-black rounded w-full py-2 px-3 text-black leading-tight" 
                            id="name" 
                            type="text" 
                            placeholder="name"
                            onChange={e => setName(e.target.value)} 
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-black text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input 
                            className="appearance-none border rounded w-full py-2 px-3 text-black border-black leading-tight" 
                            id="email" 
                            type="text" 
                            placeholder="Email Address"
                            onChange={e => setEmail(e.target.value)} 
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Message
                        </label>

                        <textarea 
                            id="message" 
                            rows="4" 
                            className="block p-2.5 w-full text-black bg-gray-50 rounded-lg border border-black" 
                            placeholder="Leave a message ..."
                            onChange={e => setMessage(e.target.value)} 
                        ></textarea>
                    </div>

                    <div className="flex items-center justify-between">
                        <button className="bg-black text-white py-2 px-4 rounded block w-full" type="submit">
                            Send
                        </button>
                    </div>
                </form>
            }
        </>
    )
}
