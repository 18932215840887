/** vendor */
import React, { useEffect, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/outline'
import { PlusCircleIcon } from '@heroicons/react/solid'

/** lib */
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '../../hooks/useDebounce'

/** components */
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import SearchSelectForm from '../Forms/SearchSelectForm'

const MealplanCreator = ({ 
    disableButtons = false, 
    rows,
    recipeOptions = [], 
    onUpdateMeaplanRecipe, 
    onDeleteMeaplanRecipe, 
    startDate = null,
    saving = false 
}) => {
    const navigate = useNavigate()

    const [selectedMealplanRecipe, setSelectedMealplanRecipe] = useState({})
    const [mealplanRows, setMealplanRows] = useState([])

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    
    const [focusRecipeSelection, setFocusRecipeSelection] = useState(false)
    const [focusedRowIndex, setFocusedRowIndex] = useState(0)
    const [rowsInit, setRowsInit] = useState(false)
    
    useEffect(() => {
        setMealplanRows(rows || [])
    }, [rows, recipeOptions])

    useDebounce(() => {
        if(rowsInit) { 
            handleSaveMealplan(mealplanRows, true) 
        } else {
            setRowsInit(true)
        }
    }, [mealplanRows], 500)
    
    const addMealplanRecipe = (recipe, rowIndex) => {
        if (recipe) {
            const updateRowRecipes = [...mealplanRows]
            updateRowRecipes[rowIndex]?.recipes.push(recipe)
            setMealplanRows(updateRowRecipes)
        }
        // if(onUpdateMeaplanRecipe) {
        //     onUpdateMeaplanRecipe(mealplanRows, false)
        // }
    }

    const handleCreateCustomItem = (option, rowIndex) => {
        const customRecipe = {
            name: option?.name,
            value: null,
            image: null
        }
        addMealplanRecipe(customRecipe, rowIndex)
    }

    // const handleMealplanRecipeChange = (event, rowIndex) => {
    //     setSelectedMealplanRecipe(recipeOptions[event.target.value])


    //     if(recipeOptions[event.target.value]) {
    //         addMealplanRecipe(recipeOptions[event.target.value], rowIndex)
    //     }
    // }

    const handleRemoveMealplanRecipe = (rowIndex, recipeIndex) => {
        const rowsCopy = [...mealplanRows]
        const rowToUpdate = mealplanRows[rowIndex]
       
        rowToUpdate.recipes.splice(recipeIndex, 1)
        rowsCopy[rowIndex] = rowToUpdate

        setMealplanRows(rowsCopy)
    }

    const deleteMealplan = () => {
        if (onDeleteMeaplanRecipe) {
            onDeleteMeaplanRecipe()
        }    
    }

    const handleDeleteMealplan = () => {
        setShowConfirmDelete(true)
    }

    const handleCancelMealplanEdit = () => {
        navigate('/user-meal-plans')
    }

    const handleSaveMealplan = () => {
        if(onUpdateMeaplanRecipe) {
            onUpdateMeaplanRecipe(mealplanRows, true)
        }
    }

    /**
     * Add Recipe to row handlers
     */
    const handleOnSelectSearchFocus = (rowIndex) => {
        setFocusedRowIndex(rowIndex)
        setFocusRecipeSelection(true)
    }

    const handleOnSelectItem = (option, rowIndex) => {
        setSelectedMealplanRecipe(option)

        if(option) {
            const recipe = {
                name: option?.name,
                value: option?.recipe_id || option?.value,
                image: option?.image
            }
            addMealplanRecipe(recipe, rowIndex)
        }
    }

    const dismissRecipeSelector = (e, rowIndex) => {
        if(rowIndex === focusedRowIndex && focusRecipeSelection) {
            setFocusedRowIndex(null)
            setFocusRecipeSelection(false)
        }
    }
    
    return (
        <div className="container mx-auto max-w-lg">
            {
                mealplanRows && mealplanRows?.length > 0 ? 
                    <ul>
                        {
                            mealplanRows.map((row, rowIndex) => {
                                const options = { 
                                    weekday: 'short', 
                                    year: 'numeric', 
                                    month: 'short', 
                                    day: 'numeric' 
                                }

                                return (
                                    <li key={rowIndex} className="mb-4 border-t border-black pt-4 mt-4">
                                        <div className="flex justify-between w-full text-xs uppercase text-gray-400">
                                            {
                                                row.date && <strong>{new Date(row.date).toLocaleDateString("en-GB", options)}</strong>   
                                            }
                                            <strong>DAY: {row.day + 1}</strong>
                                        </div>
                                        
                                        <ul className="border-b pt-4">
                                            {
                                                row?.recipes && row?.recipes.length > 0 ? (
                                                    row.recipes.map((recipe, recipeIndex) => (
                                                            <li 
                                                                key={recipeIndex}
                                                                className={`flex justify-between my-2 pt-2 border-t`}
                                                            >
                                                                <span className="mr-2">{recipe.name}</span>

                                                                <button 
                                                                    onClick={() => handleRemoveMealplanRecipe(rowIndex, recipeIndex)}                                                    
                                                                    className="block ml-auto opacity-60"
                                                                >
                                                                    <XCircleIcon className="w-4 h-4" />
                                                                </button>
                                                            </li>
                                                        )
                                                    )
                                                ) : <li className="text-xs text-gray-400 text-center py-4">NO RECIPES ...</li>    
                                            }
                                        </ul>
                                        
                                        <div className="flex justify-end items-center mt-4 pb-2">
                                            <button
                                                onClick={() => { handleOnSelectSearchFocus(rowIndex) }}
                                                className="block"
                                            >
                                                <PlusCircleIcon className="w-6 h-6 text-black" />
                                            </button>
                                        </div>


                                        {
                                            (focusRecipeSelection && focusedRowIndex === rowIndex) ?
                                                <div className="fixed bg-black bg-opacity-30 top-0 left-0 w-screen h-screen flex justify-center items-end z-50">
                                                    <div className="w-full fixed bottom-0 left-0">
                                                        {
                                                            (focusRecipeSelection && focusedRowIndex === rowIndex) ?                                            
                                                                <button 
                                                                    className="rounded-full w-8 h-8 mb-4 mr-4 bg-white text-black block ml-auto"
                                                                    onClick={(e) => { dismissRecipeSelector(e, rowIndex) }}
                                                                >&times;</button>
                                                                : ''
                                                        }
                                                        <SearchSelectForm
                                                            showCreateCustom={true}
                                                            onCreateCustomItem={(index, option) => { handleCreateCustomItem(option, rowIndex) }}
                                                            showTabs={true} 
                                                            label="Search Recipes"
                                                            options={recipeOptions} 
                                                            selectedOptions={row.recipes}
                                                            onSelectItem={(index, option) => { handleOnSelectItem(option, rowIndex) }}
                                                            handleOnSelectSearchFocus={(newValue) => { handleOnSelectSearchFocus(rowIndex) }}
                                                            showOptions={focusRecipeSelection && focusedRowIndex === rowIndex}
                                                        />
                                                    </div>
                                                    
                                                </div> : ''     
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                : 'no plan yet ...'
            }

            {
                mealplanRows && mealplanRows?.length > 0 &&
                    <div className="pb-3 px-2 fixed bottom-0 left-0 w-full flex justify-between">
                            <button
                                disabled={disableButtons} 
                                type="button" 
                                className="text-black bg-white rounded font-medium text-xs px-5 py-2.5 mr-2"
                                onClick={handleCancelMealplanEdit}
                            >Cancel</button>

                            <button
                                disabled={disableButtons}  
                                type="button"
                                className={`${(disableButtons) && 'opacity-50'} text-white flex items-center bg-black rounded text-xs px-5 py-2.5`}
                                onClick={handleSaveMealplan}
                            >
                                <span>Save</span>
                                {
                                    saving && (
                                        <div className="animate-pulse ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                            </svg>
                                        </div>
                                    )

                                } 
                            </button>
                    </div> 
            }

            { 
                showConfirmDelete && 
                    <ConfirmDialog 
                        title={`Delete Meaplan`}
                        message="This action cannot be undone, are you sure?" 
                        onConfirm={() => { 
                            deleteMealplan()
                            setShowConfirmDelete(false)
                        }} 
                        onCancel={() => { setShowConfirmDelete(false) }}
                    />
            }
        </div>
    )
}

export default MealplanCreator