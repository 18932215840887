import { useEffect, useState } from 'react'


function on(obj, ...args) {
    if (obj && obj.addEventListener) {
        obj.addEventListener(...(args));
    }
}
  
function off(obj, ...args) {
    if (obj && obj.removeEventListener) {
        obj.removeEventListener(...(args));
    }
}

const defaultState = {
  angle: 0,
  type: '',
}

const useOrientation = (initialState = defaultState) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const screen = window.screen
    let mounted = true

    const onChange = () => {
      if (mounted) {
        const { orientation } = screen

        if (orientation) {
          const { angle, type } = orientation
          setState({ angle, type })
        } else if (window.orientation !== undefined) {
          setState({
            angle: typeof window.orientation === 'number' ? window.orientation : 0,
            type: '',
          })
        } else {
          setState(initialState)
        }
      }
    }

    on(window, 'orientationchange', onChange)
    onChange()

    return () => {
      mounted = false
      off(window, 'orientationchange', onChange)
    }
  }, [])

  return state
}

export default useOrientation