import React from 'react';
import { useTimeout } from '../../../hooks/useTimeout';

export const Toast = ({ children, close}) => {
  useTimeout(close, 1000);

  return (
    <div className="fixed left-0 top-0 p-2 w-screen">
      <div id="toast-success" className="flex items-center mx-auto p-2 mb-4 w-3/5 text-black bg-white rounded shadow" role="alert">
          <div className="text-xs text-center font-normal flex-1">{ children }</div>
      </div>
    </div>
  );
};
