/** vendor */
import React, { useState } from 'react'

const ConfirmDialog = ({ title, message, onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div className="bg-white p-8 rounded-md shadow-md w-96 max-w-full z-50">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <p className="text-gray-700 mb-6">{message}</p>
        <div className="flex justify-between">
          <button
            className="px-4 py-2 text-gray-400 border border-gray-400 hover:bg-gray-100"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="mr-2 px-4 py-2 text-red-700 border border-red-700 hover:bg-red-700 hover:text-white"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDialog
