const userStorage = localStorage.getItem('user')
const user = (userStorage !== 'undefined') ? JSON.parse(userStorage): null

const initialState = user 
    ? { isLoggedIn: true, user: {user, token: null}, loginError: '' } 
    : { isLoggedIn: false, user: null, loginError: '' }

export default function (state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {
        case 'RESET_SUCCESS':
            return {
                ...state,
                isLoggedIn: false,
            }
        case 'RESET_FAIL':
          return {
              ...state,
              isLoggedIn: true,
          }
        case 'REGISTER_SUCCESS':
          return {
              ...state,
              isLoggedIn: false,
          }
        case 'REGISTER_FAIL':
            return {
                ...state,
                isLoggedIn: false,
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                loginError: ''
            }
        case 'LOGIN_FAIL':
            return {
                ...state,
                isLoggedIn: false,
                loginError: action?.message
            }
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                loginError: ''
            }
        /** User profile */
        case 'FETCH_USER_PROFILE_SUCCESS':
            localStorage.setItem('user', JSON.stringify(payload.user_profile))

            const newState = {...state} 
            newState.user.user = payload.user_profile
            return newState
        case 'FETCH_USER_PROFILE_FAIL':
            return {
                ...state
            }
        case 'UPDATE_USER_PROFILE_SUCCESS':
            return {
                ...state,
                ...{ user_data: payload.user_data }
            }
        case 'UPDATE_USER_PROFILE_FAIL':
            return {
                ...state
            }
        case 'DELETE_USER_PROFILE_SUCCESS':
            return {
                ...state,
                user_data: {}
            }
        case 'DELETE_USER_PROFILE_FAIL':
            return {
                ...state
            }
        default:
            return state
    }
}