/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

/** lib */
import Loading from '../UI/Loading'

/** state */
import { resetPassword } from '../../services/auth.service'

export default function ResetForgottenPasswordForm() {
    const dispatch = useDispatch()
    
    const { isLoggedIn } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false)

    const [userId, setUserId] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState()
    const [JWT, setJWT] = useState('')

    const [unmaskPasswordState, setUnmaskPasswordState] = useState('password')
    const [resetSuccess, setResetSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const decodeJWT = (token) => {
        const [header, payload, signature] = token.split('.')
        const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')))
        const currentTime = Math.floor(Date.now() / 1000);
        
        if (decodedPayload.exp && currentTime >= decodedPayload.exp) {
          return null
        }
      
        return decodedPayload
      }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')
    
        if (token) {
          try {
            const decoded = decodeJWT(token)
            
            if(decoded) {
                setJWT(decoded?.reset_token)
                setUserId(decoded?.user_id)
                setEmail(decoded?.user_email)
            } else {
                setErrorMessage('This link has expired.')
            }
          } catch (error) {
            setErrorMessage('This link is invalid.')
          }
        }
    }, [])

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        if (password?.length < 6) {
            setErrorMessage('Passwords must be 6 characters or more.')
            setLoading(false)
            return
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords must match.')
            setLoading(false)
            return
        }

        resetPassword(JWT, { 
            email, 
            password, 
            password_confirmation: confirmPassword 
        }).then((r) => {
            return r.json()
        }).then((r) => {
            if(r?.errors) {
                setErrorMessage('Unhandled Error: ' + Object.values(r?.errors)[0][0] )
            } else {
                setErrorMessage('')
                setResetSuccess(true)
            }
            setLoading(false)
        }).catch((r) => {
            setErrorMessage('An Error occurred resetting your password. Please try again or contact support.')
            setLoading(false)
        })
    }

    if (isLoggedIn) {
        return <Navigate to="/user-dashboard" />
    }

    if (resetSuccess) {
        return (
            <div className="pt-28 px-8">
                <h1 className="text-center text-2xl text-white pb-12">Password Reset Successful</h1>

                <div className="flex items-center justify-center pt-12">
                    <Link to="/login">
                        <button 
                            className={`${loading ? 'opacity-50' : ''} bg-black text-white px-4 rounded h-9 border border-white w-[200px] text-sm font-light block mx-auto`} 
                            type="button"
                        >
                            Return to Login
                        </button>
                    </Link>                    
                </div>
            </div>
        )
    }
    
    return (
        <>
            {
                <form className="pt-28 px-8" onSubmit={handleSubmit}>
                    <style scoped>
                        {`
                            input {
                                all: unset;
                                background: #000 !important;
                                color: #fff;
                                border: 1px solid #fff;
                                font-size: 12px;
                                width: 100%; 
                                box-sizing: border-box;
                                -webkit-box-sizing:border-box;
                                -moz-box-sizing: border-box;
                            }
                            
                            input:-webkit-autofill,
                            input:-webkit-autofill:hover,
                            input:-webkit-autofill:focus,
                            input:-webkit-autofill:active {
                                transition: all 5000s ease-in-out 0s;
                            }
                        `}
                    </style>

                    <h1 className="text-center text-2xl text-white pb-12">Reset Password</h1>

                    <div className="mb-4">
                        <label className="block text-white text-sm font-light mb-2 text-center w-full" htmlFor="username">
                            Email
                        </label>
                        <input
                            disabled 
                            className="rounded w-full pt-2 px-3 leading-tight text-center"                            
                            id="email" 
                            name="email"
                            type="text" 
                            value={email}
                            placeholder="Email Address"
                            onChange={e => setEmail(e.target.value)} 
                        />
                    </div>

                    <div className="mb-2 relative">
                        <label className="block text-white text-sm font-light mb-2 text-center w-full" htmlFor="password">
                            New Password
                        </label>
                        <input 
                            className="rounded w-full pt-2 px-3 leading-tight text-center"                            
                            id="password"
                            name="password" 
                            type={unmaskPasswordState} 
                            autoComplete="new-password"
                            placeholder=""
                            value={password}
                            onChange={e => setPassword(e.target.value)} 
                        />
                        <div className="absolute right-2 bottom-[5px]">
                            {
                                unmaskPasswordState === 'password'
                                    ? 
                                        <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                            <EyeIcon className="text-white w-4 h-4"/>
                                        </button> 
                                    : <button className="text-white" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                        <EyeOffIcon className="text-white w-4 h-4" />
                                    </button> 
                            }  
                        </div>
                    </div>

                    <div className="pt-2 relative">
                        <label className="block text-white text-sm font-light mb-2 text-center w-full" htmlFor="password_confirm">
                            Confirm New Password
                        </label>
                        <input 
                            className="rounded w-full py-2 px-3 mb-3 leading-tight text-center"
                            id="password_confirm" 
                            name="password_confirm"
                            autoComplete="new-password"
                            type={unmaskPasswordState} 
                            placeholder=""
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)} 
                        />
                        <div className="absolute right-2 bottom-[17px]">
                            {
                                unmaskPasswordState === 'password'
                                    ? 
                                        <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                            <EyeIcon className="text-white w-4 h-4"/>
                                        </button> 
                                    : <button className="text-white" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                        <EyeOffIcon className="text-white w-4 h-4" />
                                    </button> 
                            }  
                        </div>
                    </div>

                    <div>
                        <div className="h-5 text-red-500 text-center">
                            {errorMessage || ' '}
                        </div>

                        <div className="flex items-center justify-between pt-12">
                            <button 
                                className={`${loading ? 'opacity-50' : ''} bg-black text-white px-4 rounded h-9 border border-white w-[200px] text-sm font-light block mx-auto`} 
                                type="submit"
                            >
                                Reset Password
                            </button>
                        </div>
                    </div>    
                </form>
            }
        </>
    )
}

