
/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchIcon } from '@heroicons/react/solid'

/** components */
import SearchSelectForm from '../SearchSelectForm'
import IngredientListItem from './IngredientListItem'

export default function CreateIngredientsForm({onUpdate, recipe}) {
    const ingredientOptions = useSelector(state => state.form_options?.ingredient_options)
    
    const [selectedIngredients, setSelectedIngredients] = useState([])
    const [focusIngredientSelection, setFocusIngredientSelection] = useState(false)

    /**
     * Utils
     */
    const addRecipeIngredient = (ingredient) => {
        if (ingredient) {
            const selectedIngredientsCopy = [...selectedIngredients]
            let matched = false

            selectedIngredientsCopy.map((i) => {
                if (ingredient.value === i.value) {
                    matched = true
                    i['quantity']++
                }
                return i
            })

            if (!matched) {
                ingredient['quantity'] = 1
                selectedIngredientsCopy.push(ingredient)
            }

            setSelectedIngredients(selectedIngredientsCopy)
            onUpdate({ingredients: selectedIngredientsCopy})
        }
    }

    /**
     * Hooks
     */
    useEffect(() => {
        if (recipe?.ingredients) {
            setSelectedIngredients(recipe?.ingredients)
        }
    }, [recipe])

    /**
     * Handlers
     */
    const handleOnRemoveIngredient = (ingredient) => {
        const selectedIngredientsCopy = [...selectedIngredients]
        const indegredientIndex = selectedIngredientsCopy.indexOf(ingredient)
       
        selectedIngredientsCopy.splice(indegredientIndex, 1)
        setSelectedIngredients(selectedIngredientsCopy)
        onUpdate({ingredients: selectedIngredientsCopy})
    }

    const handleOnSelectItem = (ingredientOptionIndex, rowIndex) => {
        if(ingredientOptions[ingredientOptionIndex]) {
            addRecipeIngredient(ingredientOptions[ingredientOptionIndex])
        }
    }

    const handleDismissRecipeSelector = (e) => {
        setFocusIngredientSelection(false)
    }

    return (
        <div className="min-w-full h-[60vh] relative pt-4">
            <div className="min-w-full"> 
                <ul role="list" className="divide-y divide-black min-w-full h-96 overflow-y-scroll">
                    {                        
                        selectedIngredients ? 
                            selectedIngredients.map((ingredient, index) => { return (
                                <IngredientListItem
                                    key={index}
                                    ingredient={ingredient} 
                                    onRemove={ (ingredient) => { handleOnRemoveIngredient(ingredient) } } 
                                />
                            )}).reverse() : <li>No Ingredients yet.</li>
                    }
                </ul>

                <div className="absolute bottom-0 left-0 w-full">
                    <label 
                        htmlFor="ingredient" 
                        className="block mb-2 text-sm font-medium text-black"
                    >Find Ingredient</label>

                    <div className="relative cursor-pointer" onClick={() => { setFocusIngredientSelection(true) }}>
                        <input 
                            type="text" 
                            id="ingredient"
                            disabled 
                            className="bg-gray-50 border rounded border-black text-black text-sm block w-full pr-10 p-2.5" 
                            placeholder="Ingredient name ..."
                        />
                        <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                            <SearchIcon className="h-6 w-6" />
                        </div>
                    </div>
                </div>

                {
                    focusIngredientSelection ?
                        <div className="fixed bg-black bg-opacity-30 top-0 left-0 w-screen h-screen flex justify-center items-end pb-4 z-50">
                            <div className="w-full px-4">
                                {
                                    focusIngredientSelection ?                                            
                                        <button 
                                            className="rounded-full w-8 h-8 mb-4 bg-white text-black block ml-auto"
                                            onClick={(e) => { handleDismissRecipeSelector(e) }}
                                        >&times;</button>
                                        : ''
                                }
                                <SearchSelectForm 
                                    label="Search Ingredients"
                                    options={ingredientOptions} 
                                    selectedOptions={selectedIngredients}
                                    onSelectItem={(newValue) => { handleOnSelectItem(newValue) }}
                                    handleOnSelectSearchFocus={(newValue) => { }}
                                    showOptions={true}
                                />
                            </div>
                            
                        </div> : ''     
                }
            </div>
        </div>
    )
}

