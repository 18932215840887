/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SearchIcon, FilterIcon } from '@heroicons/react/solid'
import { SearchIcon as SearchIconOutline, FilterIcon as FilterIconOutline } from '@heroicons/react/outline'

/** lib */
import Loading from '../UI/Loading'
import Pagination from '../UI/Pagination'

/** state */
import { getRecipes, searchRecipes, filterRecipes } from '../../actions/recipe.actions'
import { getSavedRecipes } from '../../actions/user.actions'

/** components */
import RecipeListItem from './RecipeListItem'
import SearchForm from '../../components/Forms/SearchForm'
import FilterDialog from '../Dialogs/FilterDialog'
import AllergenDialog from '../Dialogs/AllergenDialog'

export default function RecipeList() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const recipes = useSelector(state => state?.recipes?.recipes?.data)
    const pagination = useSelector(state => state?.recipes?.recipes)
    const [currentSearchTerm, setCurrentSearchTerm] = useState('')

    const [appliedFilters, setAppliedFilters] = useState(undefined)
    const [showFilters, setShowFilters] = useState(false)
    const toggleFilters = e => { setShowFilters(!showFilters) }

    const [showSearch, setShowSearch] = useState(false)
    
    const toggleSearch = e => { 
        setShowSearch(!showSearch)
        
        setTimeout(() => { 
            if (!showSearch)
            {
                document.getElementById('recipeSearchInput').focus() 
            } 
        }, 100)
    }

    const [allergenDialogContent, setAllergenDialogContent] = useState([])
    const [showAllergens, setShowAllergens] = useState(false)
    const toggleAllergens = e => { setShowAllergens(!showAllergens) }

    useEffect(() => {
        setLoading(true)

        dispatch(getRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

        const filters = localStorage.getItem('filters')
    
        if(filters) {
            setAppliedFilters(JSON.parse(filters))
        }
    }, [])

    const handleOnOpenAllergenDialog = (allergens) => {
        setAllergenDialogContent(allergens)
        setShowAllergens(true)
    }
    
    const handleOnFilter = (filters) => {
        setShowFilters(false)

        dispatch(filterRecipes(1, currentSearchTerm, appliedFilters)).then(() => {
            setCurrentSearchTerm(currentSearchTerm)
            setAppliedFilters(filters)

            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const handleOnPaginate = (pageToRequest) => {
        setLoading(true)

        if(appliedFilters) {
            dispatch(filterRecipes(pageToRequest, currentSearchTerm, appliedFilters)).then(() => {
                setCurrentSearchTerm(currentSearchTerm)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        } else if (!currentSearchTerm) {
            dispatch(getRecipes(pageToRequest)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

        } else if (currentSearchTerm) {
            dispatch(searchRecipes(pageToRequest, currentSearchTerm)).then(() => {
                setCurrentSearchTerm(currentSearchTerm)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    const handleOnSearchRecipes = (searchString) => {
        if (searchString.length > 2 && !loading) {
            setLoading(true)

            dispatch(searchRecipes(1, searchString)).then(() => {
                setCurrentSearchTerm(searchString)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }

        if (searchString.length < 1 && !loading)
        {
            dispatch(getRecipes(1)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    return (
        <div className="w-full lg:w-2/3">
            {
                loading ? <div className="h-[60vh] relative pt-40"><Loading /></div> :

                <section className="antialiased text-gray-600 px-4">
                    <div className="flex flex-col justify-center pt-4">
                        <div className="p-3">
                            <div>
                                { 
                                    recipes ?
                                        recipes.map((r) => <RecipeListItem handleOnOpenAllergenDialog={handleOnOpenAllergenDialog} recipe={r} key={r.recipe_id} />)
                                        : <div className="text-center">No Recipes Found</div> 
                                }
                            </div>
                            
                        </div>
                    </div>
                </section>
            }

            <div className="fixed flex justify-between align-middle items-center bottom-0 left-0 p-2 px-4 w-full">
                { 
                    showSearch 
                        ? <SearchIcon className="h-8 pl-2 w-8 inline-flex text-white" onClick={toggleSearch} /> 
                        : <SearchIconOutline className="h-8 pl-2 w-8 inline-flex text-white" onClick={toggleSearch} />
                }            

                { 
                    showSearch 
                        ? <div className="px-4 w-full"><SearchForm onSearch={handleOnSearchRecipes}  /></div>
                        : <></> 
                }

                { 
                    recipes && !showSearch && !showFilters && pagination?.last_page > 1 ? 
                        <Pagination
                            onPaginate={handleOnPaginate}
                            pagination={pagination} 
                        /> : <></> 
                }

                <div className="relative">
                    { 
                        showFilters 
                            ? <FilterIcon className="h-6 pr-2 w-6 inline-flex text-white" onClick={toggleFilters} /> 
                            : <FilterIconOutline className="h-6 pr-2 w-6 inline-flex text-white" onClick={toggleFilters} />
                    }

                    <FilterDialog 
                        showDialog={showFilters} 
                        onUpdate={handleOnFilter} 
                        onCancel={toggleFilters}
                    />
                </div>

                <AllergenDialog showDialog={showAllergens} onCancel={toggleAllergens} allergens={allergenDialogContent}  />
            </div>
        </div>
    )

}