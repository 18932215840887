import { API_URL, requestHeader } from '../constants/ApiConfiguration'

const has401Error = (status) => {
    if (status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('saved_recipes')

        window.location.href = '/login'
        window.location.reload()
    } else {
        return false
    }
}

export function fetchSharedWithMe() {
    return fetch(API_URL + '/share', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

/** User General Info */
export function fetchUserProfile() {
    return fetch(API_URL + '/account', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function updateUserProfile(payload) {
    return fetch(API_URL + '/account/update', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function updateUserPassword(payload) {
    return fetch(API_URL + '/account/change-password', {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function deleteUserProfile() {
    return fetch(API_URL + '/account', {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}


/** User Store */
export function fetchUserStore(foodstore_id) {
    return fetch(API_URL + '/stores/' + foodstore_id, {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function fetchUserStores() {
    return fetch(API_URL + '/stores', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function createUserStore(payload) {
    return fetch(API_URL + '/stores', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function deleteUserStore(foodstore_id) {
    return fetch(API_URL + '/stores/' + foodstore_id, {
        method: 'DELETE', 
        headers: requestHeader()
    }).then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function updateUserStore(foodstore_id, payload) {
    return fetch(API_URL + '/stores/' + foodstore_id, {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function setDefaultUserStore(foodstore_id) {
    return fetch(API_URL + '/stores/' + foodstore_id + '/default', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}


/** Mealplans */
export function fetchUserMealplans() {
    return fetch(API_URL + '/user-mealplans/', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function fetchUserMealplan(id) {
    return fetch(API_URL + '/user-mealplans/' + id, {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function createMealplan(payload) {
    return fetch(API_URL + '/user-mealplans/', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
    .then((r) => { 
        return r.data 
    })
}

export function updateMealplan(mealplan_id, payload) {
    return fetch(API_URL + '/user-mealplans/' + mealplan_id, {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function deleteMealplan(mealplan_id) {
    return fetch(API_URL + '/user-mealplans/' + mealplan_id, {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

/** User Created Recipes */
export function fetchUserCreatedRecipes() {
    return fetch(API_URL + '/user-recipe-creator', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function createRecipe(payload) {
    return fetch(API_URL + '/user-recipe-creator', {
        method: 'POST', 
        headers: requestHeader(),
        body: payload
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function updateRecipe(recipe_id, payload) {
    return fetch(API_URL + '/user-recipe-creator/' + recipe_id, {
        method: 'PUT', 
        headers: requestHeader(),
        body: payload
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function deleteRecipe(recipe_id) {
    return fetch(API_URL + '/user-recipe-creator/' + recipe_id, {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

/** User Saved Recipes */
export function fetchUserSavedRecipes() {
    return fetch(API_URL + '/user-saved-recipes', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then((r) => {
        localStorage.setItem('saved_recipes', JSON.stringify(r.data))
        return r
    }) 
}

export function saveRecipe(payload) {
    return fetch(API_URL + '/user-saved-recipes', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

export function removeSavedRecipe(recipe_id) {
    return fetch(API_URL + '/user-saved-recipes/' + recipe_id, {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    }) 
}

/** User Imported Recipes */
export function fetchUserImportedRecipes() {
    return fetch(API_URL + '/user-imported-recipes', {
        method: 'GET', 
        headers: requestHeader()
    })    
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then((r) => {
        localStorage.setItem('imported_recipes', JSON.stringify(r))
        return r
    }) 
}

export function fetchUserImportedRecipe(recipe_id) {
    return fetch(API_URL + '/user-imported-recipes/' + recipe_id, {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then((r) => {
        return r
    }) 
}

export function importRecipe(payload) {
    return fetch(`${API_URL}/user-imported-recipes`, {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then((r) => {
        const importedRecipeJson = null
        const importedRecipesCache = localStorage.getItem('imported_recipes')

        if(importedRecipesCache) {
            try {
                importedRecipeJson = JSON.parse(importedRecipesCache)
                importedRecipeJson.push(r)
                localStorage.setItem('imported_recipes', JSON.stringify(importedRecipeJson))
            } catch (error) {
                console.warn(error)
            }

            return r
        }

        localStorage.setItem('imported_recipes', JSON.stringify([...r]))
        return r
    }) 
}

export function removeImportedRecipe(recipe_id) {
    return fetch(API_URL + '/user-imported-recipes/' + recipe_id, {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
}

/** User Shoppinglists */
export function fetchShoppinglists() {
    return fetch(API_URL + '/user-shoppinglists', {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
}

export function fetchShoppinglist(shoppinglist_id) {
    return fetch(API_URL + '/user-shoppinglists/' + shoppinglist_id, {
        method: 'GET', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then(r => {
        return r
    }) 
}

export function createShoppinglist(payload) {
    return fetch(API_URL + '/user-shoppinglists', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
    .then(r => {
        return r
    }) 
}

export function updateShoppinglist(shoppinglist_id, payload) {
    return fetch(API_URL + '/user-shoppinglists/' + shoppinglist_id, {
        method: 'PUT', 
        headers: requestHeader(),
        body: JSON.stringify(payload)
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
}

export function deleteShoppinglist(shoppinglist_id) {
    return fetch(API_URL + '/user-shoppinglists/' + shoppinglist_id, {
        method: 'DELETE', 
        headers: requestHeader()
    })
    .then((response) => { 
        if(!has401Error(response?.status)) {
            return response.json()
        }
        return null 
    })
}
