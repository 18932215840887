let savedRecipes = null
let importedRecipes = null
let mealplans = null

if (localStorage.getItem('saved_recipes')) {
    try {
        savedRecipes = JSON.parse(localStorage.getItem('saved_recipes')) 
    } catch (error) {
        console.warn(localStorage.getItem('saved_recipes'))
    }
}

if (localStorage.getItem('imported_recipes')) {
    try {
        importedRecipes = JSON.parse(localStorage.getItem('imported_recipes')) 
    } catch (error) {
        console.warn(localStorage.getItem('imported_recipes'))
    }
}

if (localStorage.getItem('user_mealplans')) {
    try {
        mealplans = JSON.parse(localStorage.getItem('user_mealplans')) 
    } catch (error) {
        console.warn(localStorage.getItem('user_mealplans'))
    }
}

const initialState = {
    saved_recipes: (savedRecipes) ? savedRecipes : [],
    imported_recipes: (importedRecipes) ? importedRecipes : [],
    mealplans: (mealplans) ? mealplans : [],
    user_stores: [],
    user_data: {},
    user_recipes: [],
    shared_with_me: []
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch(type) {
        case 'FETCH_SHARED_WITH_ME_SUCCESS':
            return {
                ...state,
                ...{ shared_with_me: payload.shared_with_me }
            }
        /** User mealplans */
        case 'FETCH_MEALPLANS_SUCCESS':
            return {
                ...state,
                mealplans: payload.mealplans.data || []
            }
        case 'FETCH_MEALPLANS_FAIL':
                return {
                ...state
                }
        case 'FETCH_MEALPLAN_SUCCESS':        
            return {
                ...state,
                current_mealplan: payload
            }
        case 'FETCH_MEALPLAN_FAIL':
                return {
                ...state
                }
        case 'CREATE_MEALPLAN_SUCCESS':
            return {
                ...state,
                mealplans: payload.mealplans
            }
        case 'CREATE_MEALPLAN_FAIL':
            return {
                ...state
            }
        case 'UPDATE_MEALPLAN_SUCCESS':
            return {
                ...state,
                mealplans: payload.mealplans
            }
        case 'UPDATE_MEALPLAN_FAIL':
            return {
                ...state
            }
        case 'DELETE_MEALPLAN_SUCCESS':
            return {
                ...state,
                mealplans: []
            }
        case 'DELETE_MEALPLAN_FAIL':
            return {
                ...state
            }

        /** User shoppinglists */
        case 'FETCH_SHOPPINGLISTS_SUCCESS':
            return {
                ...state,
                shoppinglists: payload.shoppinglists
            }
        case 'FETCH_SHOPPINGLISTS_FAIL':
                return {
                ...state
                }
        case 'CREATE_SHOPPINGLISTS_SUCCESS':
            return {
                ...state,
                shoppinglists: payload.shoppinglists
            }
        case 'CREATE_SHOPPINGLISTS_FAIL':
            return {
                ...state
            }
        case 'UPDATE_SHOPPINGLISTS_SUCCESS':
            return {
                ...state,
                shoppinglists: payload.shoppinglists
            }
        case 'UPDATE_SHOPPINGLISTS_FAIL':
            return {
                ...state
            }
        case 'DELETE_SHOPPINGLISTS_SUCCESS':
            return {
                ...state,
                shoppinglists: payload.shoppinglists
            }
        case 'DELETE_SHOPPINGLISTS_FAIL':
            return {
                ...state
            }
        /** User Created Recipes */
        case 'FETCH_USER_RECIPES_SUCCESS':
            return {
                ...state,
                ...{ user_recipes: payload.user_recipes }
            }
        case 'FETCH_USER_RECIPES_FAIL':
            return {
                ...state
            }
        case 'CREATE_RECIPE_SUCCESS':
            return {
                ...state,
                ...{ user_recipes: payload.user_recipes }
            }
        case 'CREATE_RECIPE_FAIL':
            return {
                ...state
            }
        case 'UPDATE_RECIPE_SUCCESS':
            return {
                ...state,
                ...{ user_recipes: payload.user_recipes }
            }
        case 'UPDATE_RECIPE_FAIL':
            return {
                ...state
            }
        case 'DELETE_RECIPE_SUCCESS':
            return {
                ...state,
                ...{ user_recipes: payload.user_recipes }
            }
        case 'DELETE_RECIPE_FAIL':
            return {
                ...state
            }

        /** User Saved Recipes */
        case 'FETCH_SAVED_RECIPES_SUCCESS':
            return {
                ...state,
                ...{ saved_recipes: payload.saved_recipes.data }
            }
        case 'FETCH_SAVED_RECIPES_FAIL':
                return {
                ...state
                }
        case 'CREATE_SAVED_RECIPE_SUCCESS':
            return {
                ...state
            }
        case 'CREATE_SAVED_RECIPE_FAIL':
            return {
                ...state
            }
        case 'DELETE_SAVED_RECIPE_SUCCESS':
            return {
                ...state
            }
        case 'DELETE_SAVED_RECIPE_FAIL':
            return {
                ...state
            }

        /** User Imported Recipes */
        case 'FETCH_IMPORTED_RECIPES_SUCCESS':
            return {
                ...state,
                ...{ imported_recipes: payload.imported_recipes }
            }
        case 'FETCH_IMPORTED_RECIPES_FAIL':
            return {
                ...state
            }
        case 'CREATE_IMPORTED_RECIPE_SUCCESS':
            return {
                ...state
            }
        case 'CREATE_IMPORTED_RECIPE_FAIL':
            return {
                ...state
            }
        case 'DELETE_IMPORTED_RECIPE_SUCCESS':
            return {
                ...state
            }
        case 'DELETE_IMPORTED_RECIPE_FAIL':
            return {
                ...state
            }
        /** User FoodStore */
        case 'FETCH_USER_STORES_SUCCESS':
            return {
                ...state,
                ...{ user_stores: payload.user_stores.data }
            }
        case 'FETCH_USER_STORES_FAIL':
            return {
                ...state
            }
        case 'FETCH_USER_STORE_SUCCESS':
            console.warn('todo FETCH_USER_STORE_SUCCESS', payload)

            return {
                ...state,
                // ...{ user_stores: payload.user_store.data[0] }
            }
        case 'FETCH_USER_STORE_FAIL':
            return {
                ...state
            }
        case 'CREATE_USER_STORE_SUCCESS':
            return {
                ...state,
                ...{ user_stores: payload.data }
            }
        case 'CREATE_USER_STORE_FAIL':
            return {
                ...state
            }
        case 'UPDATE_USER_STORE_SUCCESS':
            return {
                ...state,
                ...{ user_stores: payload.user_store.data }
            }
        case 'UPDATE_USER_STORE_FAIL':
            return {
                ...state
            }
        default:
            return state
    }
}