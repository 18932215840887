/** vendor */
import React from 'react'

/** lib */
import { Card } from '../../components/UI/Card'

/** components */
import ResetForgottenPasswordForm from '../../components/Forms/ResetForgottenPasswordForm'

export default function ResetForgottenPassword() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black">
      <ResetForgottenPasswordForm />
    </div>
  )
}