/** vendor */
import React, { useEffect, useState } from 'react'

/** lib */
import TimerInput from '../UI/TimerInput'

const CreateStepDialog = ({ 
  showDialog = false, 
  step, 
  onUpdate = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [currentStep, setCurrentStep] = useState(step || { stepId: null, title: '', description: '', timer: '' }) 

  const handleChange = (e) => {
    const currentStepCopy = JSON.parse(JSON.stringify(currentStep))
    currentStepCopy[e.target.id] = e.target.value
    setCurrentStep(currentStepCopy)
  }

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleOnTimerChange = (newValue) => {
    const currentStepCopy = JSON.parse(JSON.stringify(currentStep))
    currentStepCopy['timer'] = newValue
    setCurrentStep(currentStepCopy)
  }

  const handleConfirm = () => {
    onUpdate(currentStep)
    setIsOpen(false)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
      <div className="bg-white p-8 fixed bottom-16 rounded-md shadow-md w-[90vw] z-50">
        <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">{currentStep?.stepId ? 'Edit Step' : 'Create Step'}</h2>

        <div className="flex min-w-full justify-center"> 
            <div className="w-full">
                <div className="mb-4 w-full">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">Name</label>
                    <div className="relative">
                        <input 
                            type="text" 
                            id="title"
                            value={currentStep?.title}
                            onChange={handleChange} 
                            className="border border-black text-black text-sm rounded block w-full pr-10 p-2.5" 
                            placeholder="Step Name"
                        />
                    </div>
                </div>

                <div className="mb-4 w-full">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Description</label>
                    <textarea 
                        id="description" 
                        rows="4"
                        onChange={handleChange}
                        value={currentStep?.description} 
                        className="block p-2.5 w-full text-sm text-black rounded border border-black" 
                        placeholder="Step Description"></textarea>
                </div>
                <div className="w-full">
                  <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Timer</label>
                  <TimerInput onUpdate={handleOnTimerChange} />
                </div>
            </div>

        </div>
      </div>

      <div className="flex justify-between mt-6 w-full fixed bottom-3 px-6">
          <button
            className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
            onClick={handleConfirm}
          >
            Confirm
          </button>
      </div>

    </div>
  )
}

export default CreateStepDialog
