import React from 'react';

const Pagination = ({ pagination, onPaginate }) => {

    const handlePaginate = (direction, current_page) => {
        if (direction == 'next' && current_page === pagination?.last_page) {
            return
        }

        if (direction == 'prev' && current_page === 1) {
            return
        }

        const pageToRequest = direction == 'next' ? current_page+1 : current_page-1

        onPaginate(pageToRequest)
    }

    return (
        <div className="flex flex-col items-center">
            <span className="text-xs text-white leading-none block pt-1">
                Showing <span className="font-semibold text-white">{pagination?.from}</span> to <span className="font-semiboldtext-white">{pagination?.to}</span> of <span className="font-semibold text-white">{pagination?.total}</span> Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
                <button 
                    onClick={() => { handlePaginate('prev', pagination?.current_page) }}
                    disabled={pagination?.current_page === 1}
                    className={`
                        ${pagination?.current_page === 1 ? 'opacity-50' : ''}
                        flex items-center justify-center px-3 h-8 text-sm font-medium 
                        text-white bg-black rounded-l
                    `}>
                Prev
                </button>
                <button 
                    onClick={() => { handlePaginate('next', pagination?.current_page) }}
                    className={`
                        ${pagination?.current_page === pagination?.last_page ? 'opacity-50' : ''}
                        flex items-center justify-center px-3 h-8 text-sm font-medium 
                        text-white bg-black border-0 border-l border-black rounded-r
                    `}>
                Next
                </button>
            </div>
        </div>
    )
}

export default Pagination