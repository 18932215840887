/** vendor */
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HomeIcon, BeakerIcon } from '@heroicons/react/solid'
import { 
  LightBulbIcon
} from '@heroicons/react/outline'
export default function Home() {
  const { user: currentUser } = useSelector((state) => state.auth)

  return (
    <header className="flex items-center justify-between px-5 py-5 text-white text-center fixed top-0 left-0 w-screen z-10" style={{background: 'rgba(0,0,0,0.8)'}}>
      <div>
        {currentUser ? ''
          : <div className="flex">
            <Link to="/login" className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        }
      </div>

      <ul className="flex">
        <li className="pr-2">
          <Link to={`/home-beta`}>
            <HomeIcon className="h-[16px] w-[16px] block mx-auto" />
            <div className="leading-none" style={{ fontSize: 8 }}>beta</div>
          </Link>
        </li>

        <li className="pl-2">
          <Link to={`/home`} className="flex">
            <HomeIcon className="h-6 w-6" />
            <span className="sr-only">Home</span>
          </Link>
        </li>
      </ul>
    </header>
  );
}

