export const formatRecipe_SEO_JSON = (recipe) => {

    return {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        "name": recipe?.name,
        "image": [recipe?.image],
        "author": {
          "@type": "Person",
          "name": "Aisle 9"
        },
        "datePublished": recipe?.updated_at,
        "description": recipe?.description,
        "recipeCuisine": recipe?.category?.name,
        "prepTime": " ",
        "cookTime": " ",
        "totalTime": " ",
        "keywords": recipe?.tags?.join(','),
        "recipeYield": "serves" + recipe?.serves,
        "recipeCategory": recipe?.category?.name,
        "nutrition": {
          "@type": "NutritionInformation",
          "calories": " "
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": " ",
          "ratingCount": " "
        },
        "recipeIngredient": recipe?.recipe_ingredients?.map(i => i?.quantity +' '+ i?.unit + ' '+ i?.treatment +' '+ i?.ingredient?.name) || [""],
        "recipeInstructions": recipe?.recipe_steps?.map((s) => { 
            return {
                "@type": "HowToStep",
                "name": s?.name,
                "text": s?.description,
                "url": "https://aislenine.co.nz/recipes/" + recipe?.id,
                "image": s?.image
            } 
        }) || [],
        "video": {
          "@type": "VideoObject",
          "name": " ",
          "description": " ",
          "thumbnailUrl": [
            "",
           ],
          "contentUrl": " ",
          "embedUrl": " ",
          "uploadDate": " ",
          "duration": " ",
          "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": { "@type": "WatchAction" },
            "userInteractionCount": 1
          },
          "expires": " "
         }
      }
}