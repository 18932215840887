import React, { useState } from 'react'

export default function Logo() {
    
    return (
        <div className="h-[120px] items-center w-4/5 mx-auto">
            <div className="rounded-full border mx-auto antialiased bg-white text-center w-[80px] h-[80px] flex items-center justify-center">
                <span className="text-black font-serif font-bold text-2xl pb-2 antialiased">a9</span>
            </div>
        </div>
    )
}