/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** lib */
import { getRecipeOptions } from '../../actions/form.actions'
import { updateMealplan, deleteMealplan } from '../../services/user.service'
import { getUserMealplan } from '../../actions/user.actions'
import { useToast } from '../../hooks/useToast'

/** components */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import MealplanCreator from '../../components/MealPlan/MealPlanCreator'

export default function MealPlanCreate() {
  // vendor utils
  const dispatch = useDispatch()
  const location = useLocation()

  // a9 utils
  const toast = useToast()

  // show / hide dialogs
  const [mealplan, setMealplan] = useState({})
  const [mealplanName, setMealplanName] = useState('')
  const [daysCount, setDaysCount] = useState(0)
  const [recipeOptions, setRecipeOptions] = useState([])
  const [mealplanRows, setMealplanRows] = useState([])

  // ui states
  const [isSaved, setIsSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)

  // mealplan data
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  })

  const editPlan = useSelector(state => state?.current_mealplan)

  /**
   * Utils
   */
  const setupEdit = () => {
    setDateRange({
      startDate: editPlan?.mealplan?.startDate,
      endDate: editPlan?.mealplan?.endDate
    })

    setMealplanName(editPlan?.mealplan?.name)
    setDaysCount(editPlan?.mealplan?.days)
    setMealplanRows(editPlan?.mealplan?.recipes)
    setMealplan(editPlan?.mealplan)
    setIsSaved(true)
  }

  const formatDate = (dateStr) => {
    const options = { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
    }
    
    if (dateStr) {
      let d = new Date(dateStr)
      d = d.toLocaleDateString("en-GB", options)
  
      return d
    }

    return null
  }

  const createMealplanPayload = (rows) => {
    const payload = {
      name: mealplanName,
      recipes: rows,
      days: rows?.length  
    }

    if(dateRange) {
      payload['start_date'] = new Date(dateRange?.startDate)
      payload['end_date'] = new Date(dateRange?.endDate)
    }

    return payload
  }

  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(true)

      dispatch(getRecipeOptions())
      .then((r) => {
        // @todo pull this from redux state
        setRecipeOptions(r?.data)

        if(window.location.href.includes('edit')) {
          dispatch(getUserMealplan(location.pathname.split('/')[3])).then((r) => {
              setLoading(false)
          }).catch(() => {
              setLoading(false)
          })
        } else {
          setIsSaved(false)
          setLoading(false)
        }

      }).catch(() => {
          setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (Object.keys(editPlan).length > 0) {
      setupEdit()
    }
  }, [editPlan])

  /**
   * Handlers
   */
  const handleOnUpdateMealplan = (updatedMealplanRows, shouldSave = false) => {
    if(shouldSave) {
      const payload = createMealplanPayload(updatedMealplanRows)

      setSaving(true)

      updateMealplan(mealplan?.user_mealplan_id, payload)
      .then((r) => {
        setSaving(false)
      }).catch(() => {
        setSaving(false)
      })
    }
  }

  const handleOnDeleteMealplan = (mealplan_id) => {
    dispatch(deleteMealplan(mealplan_id))
    .then((r) => {
      setSaving(false)
    }).catch(() => {
      setSaving(false)
    })
  }

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
      <Card 
        title={isSaved ? mealplanName : 'Create a Meal Plan'}
        status={
          saving ? 
            <div className="animate-pulse">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
            </div> : <></>
        }
      >
          <div className="min-w-full">
            <div className="flex justify-between text-black">
              {
                dateRange?.startDate && dateRange?.endDate ?
                  <small>
                    {formatDate(dateRange?.startDate)} - {formatDate(dateRange?.endDate)}
                  </small> : ''
              }
              <small>TOTAL DAYS: {daysCount}</small>
            </div> 
            
            <div>
              <MealplanCreator
                disableButtons={saving || loading}
                startDate={dateRange?.startDate} 
                rows={mealplanRows}
                recipeOptions={recipeOptions}
                onUpdateMeaplanRecipe={(updatedMealplan, shouldSave) => {
                  handleOnUpdateMealplan(updatedMealplan, shouldSave)
                }}
                onDeleteMeaplanRecipe={() => { handleOnDeleteMealplan() }}
              />
            </div>
          </div>
      </Card>
    </div>
  )
}