/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'

/** lib */
import { Card } from '../../components/UI/Card'
import Stepper from '../../components/UI/Stepper'
import Loading from '../../components/UI/Loading'

/** state */
import { getFormOptions } from '../../actions/form.actions'

/** components */
import CreateBasicDetailsForm from '../../components/Forms/RecipeCreator/CreateBasicDetailsForm'
import CreateIngredientsForm from '../../components/Forms/RecipeCreator/CreateIngredientsForm'
import ConfigureIngredientsForm from '../../components/Forms/RecipeCreator/ConfigureIngredientsForm'
import CreateStepsForm from '../../components/Forms/RecipeCreator/CreateStepsForm'
import SaveRecipeForm from '../../components/Forms/RecipeCreator/SaveRecipeForm'

export default function RecipeCreator() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

	const [currentStep, setCurrentStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)

  const [recipe, setRecipe] = useState({
    category_id: null,
    name: '',
    ingredients: [],
    steps: [],
    serves: null,
    recipe_image: null
  })
  
  /**
   * Utils
   */
  const stepArray = [
    {
      description: 'Basic Details',
      to: '/recipe-creator' 
    },
    {
      description: 'Add Ingredients',
      to: '/recipe-creator/ingredients' 
    },
    {
      description: 'Configure Ingredients',
      to: '/recipe-creator/configure' 
    },
    {
      description: 'Add Steps',
      to: '/recipe-creator/steps' 
    },
    {
      description: 'Review and Save',
      to: '/recipe-creator/complete' 
    }
	]

  const saveFormProgress = () => {
    // @todo
  } 

  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(true)

    dispatch(getFormOptions()).then(() => {
        setLoading(false)
    }).catch(() => {
        setLoading(false)
    })

    setCurrentStep(stepArray.findIndex(i => i.to === location.pathname) + 1)
  }, [])

  /**
   * Handlers
   */
	const handleClick = (clickType) => {
		let newStep = currentStep;

		(clickType == "next") ? newStep++ : newStep--

		// Check if steps are within the boundary
		if (newStep > 0 && newStep <= stepArray.length) {
			setCurrentStep(newStep)
      navigate(stepArray[newStep - 1].to)
		}

    if (clickType == "next") {
      saveFormProgress()
    }
	}

  const onUpdateFormState = (newState) => {
    setRecipe({...recipe, ...newState})
  }

  if (loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full relative">
      <Card title="Create Recipe" enableBack={false}>
          <Stepper steps={stepArray} currentStepNumber={currentStep} />
          <div className="pt4 h-[65vh] overflow-y-scroll flex justify-center items-center">
              <Routes> 
                <Route path="/" element={<CreateBasicDetailsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/ingredients" element={<CreateIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/configure" element={<ConfigureIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/steps" element={<CreateStepsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
                <Route path="/complete" element={<SaveRecipeForm onUpdate={onUpdateFormState} recipe={recipe} />} />
              </Routes>
          </div>
      </Card>

      <div className="container flex justify-between fixed bottom-5 left-0 px-2 w-full">
          <button 
            onClick={() => handleClick()} 
            className="py-2 px-4 rounded h-12 w-20 border border-black bg-white text-black hover:bg-black hover:text-white"
          >Back</button>
          
          <button 
            onClick={() => handleClick("next")} 
            className="py-2 rounded px-4 h-12 w-20 border border-black bg-white text-black hover:bg-black hover:text-white"
          >Next</button>
      </div>

      {
        saving ? <div className="h-full w-full fixed top-0 left-0 opacity-60 bg-white flex items-center justify-center pt-60"><Loading title="Saving" /></div> : ''
      }
    </div>
  )
}