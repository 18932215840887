/** vendor */
import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'

/** Lib */
import { updateSharedWith } from '../../services/share.service'
import { useToast } from '../../hooks/useToast'

const ShareDialog = ({
    title = 'Share With',
    showDialog = false,
    shareWith = [],
    entityId,
    entityType,
    onUpdate = () => { },
    onCancel = () => { } 
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [email, setEmail] = useState('')
    const [shareWithList, setShareWithList] = useState([])
    const [unshareWithList, setUnshareWithList] = useState([])
    const toast = useToast()

    /**
     * Utils
     */
    const updateSharedEntityRequest = (shareList, unshareList) => {      
        setSaving(true)
        
        updateSharedWith({
            shared_with: shareList, 
            unshared_with: unshareList,
            entity_id: entityId, 
            type: entityType
        }).then((r) => {
            toast.open(`Sharing Updated`)
            setSaving(false)
            // console.warn('updateSharedWith', r)
        }).catch((e) => {
            toast.open(`Error: Failed to Share`)
            setSaving(false)
            // console.error('updateSharedWith', e)
        })
    }

    /**
     * Hooks
     */
    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    useEffect(() => {
        if (shareWith && shareWith.length > 0) {
            setShareWithList(shareWith)
        }
        else if(shareWithList?.length !== 0)
        {
            setShareWithList([])
        }
    }, [shareWith])    

    /**
     * Handlers
     */
    const handleChange = (e) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setEmail(e.target.value)
        setIsValidEmail(regex.test(e.target.value))
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    const handleRemoveSharedUser = (user, index) => {
        const shareWithListCopy = [...shareWithList]
        const unshareWithListCopy = [...unshareWithList]
        
        if (unshareWithListCopy.indexOf(user) < 0)  {
            unshareWithListCopy.push(user)
            setUnshareWithList(unshareWithListCopy)
        }

        shareWithListCopy.splice(shareWithListCopy.indexOf(user), 1)

        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithListCopy)
        onUpdate(shareWithListCopy)
    }

    const handleGiveUserEditPermissionByIndex = (index) => {
        const shareWithListCopy = [...shareWithList]
        shareWithListCopy[index].canEdit = !shareWithListCopy[index].canEdit
        
        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithList)
        onUpdate(shareWithListCopy)
    }

    const handleConfirm = () => {
        if (!isValidEmail) return

        const shareWithListCopy = [...shareWithList]
        const unshareWithListCopy = [...unshareWithList]

        const newUser = {canEdit: false, email}

        shareWithListCopy.push(newUser)

        if (unshareWithListCopy.indexOf(newUser))  {
            unshareWithListCopy.splice(unshareWithListCopy.indexOf(newUser), 1)
            setUnshareWithList(unshareWithListCopy)
        }

        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithListCopy)
        onUpdate(shareWithListCopy)

        setEmail('')
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'
                } items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="bg-white p-2 fixed bottom-6 rounded-md shadow-md w-[90vw] z-50">
                <button
                    className="rounded-full w-8 h-8 absolute top-[-50px] right-0 bg-white text-black block ml-auto z-50 mr-2"
                    onClick={(e) => { handleCancel(e) }}
                >&times;</button>

                <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">{title}</h2>

                {
                    shareWithList && shareWithList.length > 0 ?
                        <ul className="pb-3 overflow-y-auto text-sm">
                            {
                                shareWithList.map((user, index) => (
                                    <li
                                        key={`shared-with-email-${index}`}
                                        className={`${index > 5 ? 'hidden' : ''} border-b border-gray-200 py-1`}
                                    >
                                        <div className="flex items-center justify-between pl-2 rounded">
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() => { handleRemoveSharedUser(user, index) }}
                                                    className="w-6 h-6 ml-auto mr-1 p-1"
                                                >
                                                    <XIcon className="" />
                                                </button>

                                                <label
                                                    className="w-full py-2 text-sm font-medium text-black rounded"
                                                >
                                                    {user.email}
                                                </label>
                                            </div>
                                            <div className="items-center pr-2 mt-[-8px]">
                                                    <label 
                                                        htmlFor="deleteList" 
                                                        className="text-[8px] font-medium text-black block"
                                                    >Edit</label>
                                                    <input 
                                                        onChange={() => { handleGiveUserEditPermissionByIndex(index) }}
                                                        disabled={saving}
                                                        checked={user?.canEdit} 
                                                        id="adjustIngredidents" 
                                                        type="checkbox" 
                                                        value="adjustIngredidents" 
                                                        className={`w-4 h-4 mt-[-10px] text-black bg-white border-black rounded ${saving && 'opacity-60'}`} 
                                                    />
                                                    
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul> : ''
                }

                <div className="flex min-w-full justify-center">
                    <div className="w-full">
                        <div className="w-full">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">
                                Email / UserID
                            </label>
                            <div className="relative flex">
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => {handleChange(e) }}
                                    className={`${email.length > 5 && !isValidEmail ? 'border-red-500' : 'border-black'} border text-black text-sm rounded block w-full pr-10 p-2.5`}
                                    placeholder="Email Address or UserID"
                                />

                                <button
                                    className={`${saving || !isValidEmail ? 'opacity-60' : ''} px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1 ${saving && 'opacity-60'}`}
                                    onClick={() => { handleConfirm() }}
                                    disabled={saving || !isValidEmail}
                                >
                                    {
                                        saving ? 
                                        <div className="animate-pulse">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                            </svg>
                                        </div> : 'Add'
                                    }
                                </button>
                            </div>
                            <label className="block text-xs font-light text-red-500 mt-1">
                                { 
                                    email.length > 5 && !isValidEmail
                                        ? 'Invalid email address' 
                                        : <>&nbsp;</>
                                }
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareDialog
