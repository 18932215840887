/** vendor */
import React, { useEffect, useState } from 'react'

const CreateStoreDialog = ({ 
  showDialog = false, 
  store, 
  onSubmit = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  /**
   * Hooks
   */
  useEffect(() => {
    if (store) {
        setName(store?.name)
        setDescription(store?.description)
    }
    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleConfirm = () => {
    onSubmit({name, description})
    setIsOpen(false)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
      <div className="bg-white p-8 fixed bottom-16 rounded-md shadow-md w-[95vw] z-50">
        <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">{store ? 'Edit Store' : 'Create Store'}</h2>

        <div className="flex min-w-full justify-center"> 
            <div className="w-full">
                <div className="mb-4 w-full">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-black">Name</label>
                    <div className="relative">
                        <input 
                            type="text" 
                            id="title"
                            value={name}
                            onChange={handleNameChange} 
                            className="border border-black text-black text-sm rounded block w-full pr-10 p-2.5" 
                            placeholder="Store Name"
                        />
                    </div>
                </div>

                <div className="mb-4 w-full">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-black">Description</label>
                    <textarea 
                        id="description" 
                        rows="4"
                        onChange={handleDescriptionChange}
                        value={description} 
                        className="block p-2.5 w-full text-sm text-black rounded border border-black" 
                        placeholder="Store Description"></textarea>
                </div>
            </div>

        </div>
      </div>

      <div className="flex justify-between mt-6 w-full fixed bottom-3 px-2">
          <button
            className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
            onClick={handleConfirm}
          >
            Confirm
          </button>
      </div>

    </div>
  )
}

export default CreateStoreDialog
