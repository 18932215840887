/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { dateDiff } from '../../utils'
import { updateMealplan } from '../../services/user.service'

/** state */
import {
  deleteMealplan,
  getUserMealplans,
  getSharedWithMe
} from '../../actions/user.actions'

const ScheduleMealplanDialog = ({
  mealplan,
  showDialog = false,
  onSubmit = () => { },
  onCancel = () => { }
}) => {
  // vendor utils
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(true)
  const [saving, setSaving] = useState(false)

  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
  })

  /**
   * Hooks
   */
  useEffect(() => {
    if (mealplan) {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() + mealplan?.days)

      setFormData({
        start_date: new Date().toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
      })
    }

    setIsOpen(showDialog)
  }, [showDialog, mealplan])

  /**
   * Handlers
   */
  const handleDateChange = (e) => {
    const formCopy = { ...formData }

    formCopy.start_date = e.target.value

    const end_date = new Date(e.target.value)

    end_date.setDate(
      end_date.getDate() + mealplan?.days
    )

    formCopy.end_date = end_date.toISOString().split('T')[0]

    setFormData(formCopy)
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = () => {
    if (formData?.start_date) {

      const payload = { ...mealplan, ...formData }

      payload.recipes = payload?.recipes.map((day, index) => {
        const d = new Date(formData?.start_date)
        d.setDate(d.getDate() + index)

        const dayEntry = { ...day }
        dayEntry.date = d.toISOString()

        return dayEntry
      })

      setSaving(true)

      updateMealplan(mealplan?.user_mealplan_id, payload)
        .then((r) => {
          setSaving(false)
          onSubmit(formData)
        }).catch(() => {
          setSaving(false)
        })
    }
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'
        } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
      <div className="bg-white p-8 fixed bottom-16 rounded-md shadow-md w-[90vw] z-50">
        <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Schedule Mealplan</h2>

        <div className="flex min-w-full justify-center">
          <div className="w-full">
            <div className="">
              <label
                htmlFor="days"
                className="block mb-2 text-sm font-medium text-black"
              >Start Date</label>
              <input
                className="border border-black text-black text-sm rounded block w-full p-2.5"
                onChange={(e) => { handleDateChange(e) }}
                type="date"
                id="start_date"
                name="start_date"
                value={formData?.start_date}
              />
            </div>

            <div className="mt-2 opacity-20">
              <label
                htmlFor="days"
                className="block mb-2 text-sm font-medium text-black"
              >End Date</label>
              <input
                className="border border-black text-black text-sm rounded block w-full p-2.5"
                disabled
                value={formData.end_date}
                type="date"
                id="end_date"
                name="end_date"
              />
            </div>
          </div>

        </div>
      </div>

      <div className="flex justify-between mt-6 w-full fixed bottom-3 px-6">
        <button
          className="px-4 py-1 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
          onClick={handleCancel}
        >
          Cancel
        </button>

        <button
          className="px-4 py-1 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>

    </div>
  )
}

export default ScheduleMealplanDialog
