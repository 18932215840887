import React, { useEffect, useState } from 'react'
import { Card } from '../../components/UI/Card'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EyeIcon, UserGroupIcon } from '@heroicons/react/solid'
import { getShoppinglists, getSharedWithMe } from '../../actions/user.actions'

export default function ShoppingPanel() {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState()
    const [listItems, setListItems] = useState([])

    const shoppingLists = useSelector(state => state?.user_data?.shoppinglists)
    const sharedWithMeLists = useSelector(state => state?.user_data?.shared_with_me?.filter(m => m.type === 'UserShoppinglist'))
    const currentUsersId = useSelector(state => state.auth?.user?.user?.id)

    /**
     * Hooks
     */
    useEffect(() => {
        let isMounted = true

        setLoading(true)
        
        if (isMounted) { 
            dispatch(getShoppinglists())
                .then((r) => {
                    dispatch(getSharedWithMe())
                        .then((r) => {
                            setLoading(false) 
                        }).catch(() => {    
                            setLoading(false)
                        })

            }).catch(() => {
                setLoading(false)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <div className="w-full px-2">
            <div 
                className="bg-white w-full shadow-md border border-gray-400 rounded"
            >
                <div className="flex p-2 w-full justify-between items-center bg-gray-50  border-b border-gray-200">
                    <h5 className="text-md text-center font-light">
                        Shopping Lists
                    </h5>

                    <hr className="pt-2 " />
                </div>
                <div style={{ height: 'calc(100vh / 3)' }} className="overflow-y-auto"> 
                    {
                        shoppingLists && [...shoppingLists, ...sharedWithMeLists]?.length > 0 ?
                            [...shoppingLists, ...sharedWithMeLists].sort((a, b) => {
                                const dateA = new Date(a.updated_at)
                                const dateB = new Date(b.updated_at)                        
                                return dateB - dateA
                            }).map((list, i) => {
                                return (
                                    <div key={i}>

                                        <div key={i} className="flex justify-between p-2 items-center border-b border-gray-200">
                                            <div>
                                                <small className="opacity-40 block" style={{ fontSize: 10 }}>Last Updated: {new Date(list?.updated_at).toLocaleString()}</small>
                                                <div className="flex items-center">
                                                    {
                                                        currentUsersId !== parseInt(list?.user_id) &&
                                                            <UserGroupIcon className="text-xs w-4 h-4 mr-2 mt-1" />
                                                    }
                                                    <label className="block text-black font-light text-md mr-1 mt-1">{list?.name}</label>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <Link to={`/user-shopping-lists/${list.user_shoppinglist_id}?view=readonly`}>
                                                    <button
                                                        className="text-xs w-6 h-6 px-1 text-gray-400 rounded-full"
                                                    >
                                                        <EyeIcon />
                                                    </button>
                                                </Link>
                                                <small style={{ fontSize: 10 }} className="text-xs opacity-40 block">{list?.ingredients?.length} Items</small>
                                            </div>
                                        </div>
                                    </div>
                                )
                                
                            }) : <></>
                    }
                </div>
            </div>
        </div>
    )
}