/** vendor */
import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import { formatRecipe_SEO_JSON } from '../../utils/recipeSchema'

/** components */
import RecipeStep from './RecipeStep'
import RecipeDetailItem from './RecipeDetailItem'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

export default function RecipeDetail({ recipe }) {
    const [showIngredients, setShowIngredients] = useState(false)
    
    const formatQuantity = (value) => {
        const stringValue = value.toString()

        if (stringValue.includes('.')) {
          const [integerPart, decimalPart] = stringValue.split('.')
      
          if (/^0+$/.test(decimalPart)) {
            return parseInt(integerPart, 10)
          }
        }
      
        return value    
    }

    return (
        <div className="w-full flex justify-center">
            <script type="application/ld+json">{JSON.stringify(formatRecipe_SEO_JSON(recipe))}</script>
            <div className="flex flex-col-reverse w-full">
                <div className="w-full">
                    <div className="flex">
                        <RecipeDetailItem recipe={recipe?.current_recipe} />
                    </div>
                    <div>
                        { 
                            recipe.current_recipe.recipe_steps
                                .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                .map((s) => 
                                    <RecipeStep 
                                        step={s} 
                                        key={s.recipe_step_id} 
                                        ingredientList={recipe.current_recipe.recipe_ingredients}
                                    />
                            )
                        }
                    </div>
                </div>

                <div className={`p-4 fixed bottom-0 left-0 w-screen ${showIngredients ? 'bg-black flex flex-col justify-end bg-opacity-50 h-screen' : ''}`}>
                    <div className={`border rounded border-black bg-white`}>
                        { showIngredients ?
                            <ul className="h-[80vh] overflow-y-auto">
                                {
                                    recipe.current_recipe.recipe_ingredients.map((i) =>
                                    {
                                        const ingredientImage = (i.image) ? `${IMG_URL}/${i.image}` : PLACEHOLDER_IMG

                                        return (
                                            <li className="p-2 border-t min-w-full" key={i.recipe_ingredient_id}>
                                                <div className="flex items-center">                                                     
                                                    <div className="flex-1 min-w-0">
                                                        <div className={`text-sm truncate text-black flex w-full justify-between items-center`}>
                                                            <div>{formatQuantity(i.quantity)} {i.measurement} {i.treatment} {i.ingredient.name}</div>
                                                            { 
                                                                i.ingredient.allergen  
                                                                    ? <div className="text-orange-400 opacity-40 text-xs h-6 flex items-center text-center">
                                                                        <ExclamationCircleIcon className="h-6 w-6" />
                                                                    </div> : <></> 
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul> : <></> 
                        }

                        <div className="w-full p-2 flex justify-between" onClick={ () => { setShowIngredients(!showIngredients) }}>
                            <label className="block text-center text-black">Ingredients</label>
                            <button>
                                { showIngredients ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" /> }
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>  
    )
}