/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** state */
import { getImportedRecipe } from '../../actions/user.actions'
import { Card } from '../../components/UI/Card'
import Loading from '../UI/Loading'

export default function ImportedRecipeDetail() {
    const location = useLocation()
    const dispatch = useDispatch()

    const import_recipe_id = location.pathname.split('/')[2]

    const importedRecipe = useSelector(state => state.user_data.imported_recipes
        .find(r => r.import_recipe_id === parseInt(import_recipe_id))
    )

    const [recipeJson, setRecipeJson] = useState(null)
    const [loading, setLoading] = useState(false);
    
    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)
        try {
            setRecipeJson(JSON.parse(importedRecipe.data))
        } catch (error) {
            console.warn(error)
        }
        setLoading(false)
    }, [importedRecipe])

    if(loading || !importedRecipe) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <>
            {
                importedRecipe && recipeJson ?
                    <div className="flex flex-col w-full pt-16">        
                    
                        <Card title={recipeJson.name} className="w-full">
                            <div className="w-full flex justify-center">
                                <div className="flex flex-col-reverse w-full lg:flex-row lg:w-10/12">
                                    <div className="w-full lg:w-8/12">
                                        <div>
                                            <p>{ recipeJson.description }</p>
                                            <small className="border-t pt-2 mt-4 block">Source: {recipeJson.author.name} - { recipeJson.url }</small>
                                        </div>
                                        <div className="mt-12 border-t pt-12">
                                            {   
                                                recipeJson.recipeInstructions.map((step, index) => {
                                                    return (
                                                        <div className="border-b mb-12 pb-12">
                                                            <div className="flex items-center">
                                                            <span className="block border-gray-600 text-gray-600 rounded-full border py-2 px-4">{step.position || index+1}</span>
                                                            <label className="lowercase pl-2 text-gray-600">{step.name}</label>
                                                            </div>
                                                    
                                                            <div className="flex py-12">
                                                                <p className="pl-4 whitespace-pre-wrap">
                                                                    {step.text}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="lg:w-4/12 lg:pl-4 pb-4">
                                        <div className="border rounded p-1 border-gray-200">
                                            <label className="pb-4 pt-2 block text-center text-gray-600">Ingredients</label>
                                            <ul>
                                                {
                                                    recipeJson.recipeIngredient.map((i, index) =>
                                                    {
                                                        const ingredientImage = PLACEHOLDER_IMG

                                                        return (
                                                            <li className="py-2 px-4 border-t min-w-full" key={index}>
                                                                <div className="flex items-center space-x-4">
                                                                    <div className="flex-shrink-0">
                                                                        <img 
                                                                            className="w-8 h-8 rounded-full border overflow-hidden" 
                                                                            src={ingredientImage}
                                                                            alt="ingredient image" 
                                                                        />
                                                                    </div>                                                       
                                                                    <div className="flex-1 min-w-0">
                                                                        <p className={`text-sm truncate text-gray-600`}>
                                                                            {i}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>

                                        <div className="border rounded p-1 border-gray-200 mt-4">
                                            <label className="pb-4 pt-2 block text-center text-gray-600">Nutritional Information</label>
                                            <ul>
                                                {
                                                    recipeJson.nutrition ? 
                                                        Object.entries(recipeJson.nutrition).map(([key, value], index) => {
                                                            return (
                                                                <li key={index} className={`${index === 0 ? 'hidden' : ''}`}>
                                                                    <strong>{key}: </strong> {value}
                                                                </li>
                                                            )
                                                        }) : <li> - </li>  
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div> : ''
            }
        </>        
    )
}