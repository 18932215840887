/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

/** state */
import { 
    getSavedRecipes, 
    getUserMealplans, 
    getShoppinglists, 
    getSharedWithMe 
} from '../actions/user.actions'

export default function useUserData(isloggedIn = false) {
    const [userDataLoadingState, setLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isloggedIn) return

        const fetchData = async () => {
          try {
            await Promise.all([
              dispatch(getSavedRecipes()),
              dispatch(getUserMealplans()),
              dispatch(getShoppinglists()),
              dispatch(getSharedWithMe())
            ]);
    
            setLoading(false)
          } catch (error) {
            console.error('Error fetching user data:', error)
            setLoading(false)
          }
        };
    
        fetchData()
      }, [dispatch])

    return { userDataLoadingState }
}