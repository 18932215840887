/** vendor */
import React, { useState, useEffect } from 'react'

/** lib */
import { fetchHome } from '../../services/home.service'

/** components */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import HomeNewsTab from '../../components/Home/HomeNewsTab'
import HomeRecipesTab from '../../components/Home/HomeRecipesTab'
import HomeFeaturesTab from '../../components/Home/HomeFeaturesTab'

export default function HomeBeta() {
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState('news')

  const [news, setNews] = useState([])
  const [categories, setCategories] = useState([])
  const [latestRecipes, setRecipes] = useState([])
  const [featuredContent, setFeaturedContent] = useState([])

  useEffect(() => {
    fetchHome().then(r => {
      setNews(r?.news || [])
      setCategories(r?.categories || [])
      setRecipes(r?.recipes || [])
      setFeaturedContent(r?.featured || [])

      setLoading(false)
    });
  }, [])

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
        <Card title="aisle nine" autoHeight="true">
          
          <div className="w-full flex flex-col items-center" style={{ height: 'calc(100vh - 140px)' }}>
            <ul className="w-full flex justify-center flex-wrap text-sm font-medium text-center border-b pb-2">
                <li className="w-1/3 text-center">
                    <a 
                      href="#news" 
                      aria-current="page"
                      onClick={handleToggleTab}  
                      className={`${tab === 'news' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                        News
                    </a>
                </li>
                <li className="w-1/3 text-center">
                    <a 
                      href="#latestRecipes"
                      onClick={handleToggleTab} 
                      className={`${tab === 'latestRecipes' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                        Latest Recipes
                    </a>
                </li>
                <li className="w-1/3 text-center">
                    <a 
                      href="#featuredContent"
                      onClick={handleToggleTab} 
                      className={`${tab === 'featuredContent' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                      Featured
                    </a>
                </li>
            </ul>

            {
              loading 
              ? <div className="h-[60vh] relative pt-40"><Loading /></div> :
              <>
                { tab === 'news' && <HomeNewsTab news={news} /> }
                { tab === 'latestRecipes' && <HomeRecipesTab recipes={latestRecipes} categories={categories} /> }
                { tab === 'featuredContent' && <HomeFeaturesTab featuredContent={featuredContent} /> }
              </>
            }                


          </div>
        </Card>
    </div>
  )
}