/** vendor */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/solid'

export default function ShoppingListListItem({list, index, onShowOptions}) {
    if (!list) {
        return <></>
    }
    
    return (    
        <div className="flex justify-between items-center py-2 border-b border-gray-200">
            <div className="text-black">
                <small className="opacity-40 block leading-none" style={{ fontSize: 10 }}>Last Updated: {new Date(list?.updated_at).toLocaleDateString().padStart(10, '0')}</small>
                <div>
                    <label className={`block mt-1 ${list?.owner_name ? 'leading-none' : ''}`}>{list?.name}</label>
                </div>
                { 
                    list?.owner_name && 
                        <div className="text-[10px] opacity-40">owner: {list?.owner_name }</div> 
                }
            </div>
            <div className="text-center">
                <div className="flex justify-end items-center">
                    <Link to={`/user-shopping-lists/${list.user_shoppinglist_id}?view=readonly`}>
                        <button
                            className="text-xs w-6 h-5 pl-2 pt-[6px] ml-1 text-black rounded-full"
                        >
                            <EyeIcon />
                        </button>
                    </Link>
                    <button
                        className="text-xs h-5 w-6 ml-1 text-black rounded-full"
                        onClick={(e) => { onShowOptions(list) }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </button>
                </div>
                <small className="opacity-40 leading-none block" style={{ fontSize: 10 }}>{list?.ingredients?.length} items</small>
            </div>
        </div>        
    )
}