import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchSelectForm from '../Forms/SearchSelectForm'
import { getFormOptions } from '../../actions/form.actions'

const FilterDialog = ({
  context = 'filter', // filter | save
  showDialog = false, 
  onUpdate = () => {}, 
  onCancel = () => {} 
}) => {
  const dispatch = useDispatch()

  const ingredientOptions = useSelector(state => state.form_options?.ingredient_options || [])
  const categoryOptions = useSelector(state => state.form_options?.category_options || [])

  const [isOpen, setIsOpen] = useState(true)
  const [includeExclude, setIncludeExclude] = useState('include')
  const [selectedFilters, setSelectedFilters] = useState([])

  const [currentTab, setCurrentTab] = useState('preferences')
  const appliedFiltersContainerRef = useRef(null)
  
  /**
   * Hooks
   */
  useEffect(() => {
    dispatch(getFormOptions())

    const filters = localStorage.getItem('filters')
    
    if(filters) {
      setSelectedFilters(JSON.parse(filters))
    }
  }, [])

  useEffect(() => { 
    setIsOpen(showDialog) 
  }, [showDialog])

  const isSelectedPreference = (val) => {
    const index = selectedFilters?.findIndex(f => f?.value === val)
    return (index > -1)
  }
  /**
   * Handlers
   */
  const handleSelectPreference = (e) => {
    const selectFiltersCopy = [...selectedFilters]

    if (e?.target?.checked) {
      selectFiltersCopy?.push({
        value: e?.target?.value,
        name: e?.target.value,
        type: 'preference'
      })
    } else {
      const index = selectFiltersCopy?.findIndex(f => f?.value === e?.target?.value)
      selectFiltersCopy?.splice(index, 1)
    }

    setSelectedFilters(selectFiltersCopy)

    if (appliedFiltersContainerRef.current) {
      setTimeout(() => {
        appliedFiltersContainerRef.current.scrollTop = appliedFiltersContainerRef.current.scrollHeight;
      }, 10)
    }
  }

  const handleRemoveFilter = (index) => {
    const selectFiltersCopy = [...selectedFilters]
    selectFiltersCopy.splice(index, 1)
    setSelectedFilters(selectFiltersCopy)

    if (appliedFiltersContainerRef.current) {
      setTimeout(() => {
        appliedFiltersContainerRef.current.scrollTop = appliedFiltersContainerRef.current.scrollHeight;
      }, 10)
    }
  }
  
  const handleSelectIngredient = (index, option) => {
    if (!selectedFilters.includes(option)) {
      const selectFiltersCopy = [...selectedFilters]
      
      option['type'] = 'ingredient'
      option['includeExclude'] = includeExclude

      selectFiltersCopy.push(option)
      setSelectedFilters(selectFiltersCopy)

      if (appliedFiltersContainerRef.current) {
        setTimeout(() => {
          appliedFiltersContainerRef.current.scrollTop = appliedFiltersContainerRef.current.scrollHeight;
        }, 10)
      }
    }
  }

  const handleSelectCategory = (index, option) => {
    if (!selectedFilters.includes(option)) {
      const selectFiltersCopy = [...selectedFilters]

      option['type'] = 'category'
      option['includeExclude'] = includeExclude

      selectFiltersCopy.push(option)
      setSelectedFilters(selectFiltersCopy)

      if (appliedFiltersContainerRef.current) {
        setTimeout(() => {
          appliedFiltersContainerRef.current.scrollTop = appliedFiltersContainerRef.current.scrollHeight;
        }, 10)
      }
    }
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  const handleConfirm = () => {
    localStorage.setItem('filters', JSON.stringify(selectedFilters))
    onUpdate(selectedFilters)
    setIsOpen(false)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>

      <div className="fixed bottom-0 w-screen z-50">
        <div className="flex flex-wrap justify-end items-end z-50 max-h-[100px] overflow-y-scroll" ref={appliedFiltersContainerRef}>
            {
              selectedFilters ? 
                selectedFilters.map((i, index) => {
                  return (
                    <div 
                      key={index} 
                      className={`m-1 p-1 h-6 text-xs rounded text-white ${i?.includeExclude === 'exclude' ? 'bg-red-600' : 'bg-black'}`}>
                        <span>{i.name}</span>
                        <span className="ml-2" onClick={() => { handleRemoveFilter(index) }}>&times;</span>  
                    </div>
                  )
                }) : ''
            }
        </div>

        <div className="px-2">
          <div className="bg-white p-4 rounded-md shadow-md">
            <div className="border-b border-black w-full flex justify-between items-center pb-2" >
              <div className="text-xl font-semibold">{ context === 'filter' ? 'Apply Filters' : 'Set Preferences' }</div>
              <div className="relative">
                <div className="w-full flex justify-end">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input 
                      type="checkbox" 
                      value="includeExclude"
                      onChange={() => {
                        const copy = includeExclude === 'include' ? 'exclude' : 'include'
                        setIncludeExclude(copy)
                      }}
                      checked={(includeExclude === 'include')} 
                      className="sr-only peer" 
                      />
                    <div 
                      
                      className={`
                          w-9 h-5 bg-red-600
                          rounded-full peer
                          peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                          after:absolute after:top-[2px] after:left-[2px] 
                          after:bg-white
                            after:rounded-full after:h-4 after:w-4 after:transition-all 
                        peer-checked:bg-black

                      `}></div>
                    <span className="ml-3 text-xs font-medium text-gray-900 dark:text-gray-300">{includeExclude}</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex min-w-full justify-center my-2"> 
                <div className="w-full">

                    <ul className="flex text-sm font-medium text-center border-b border-black overflow-hidden">
                        <li className="w-full">
                            <a href="#" 
                              onClick={() => { setCurrentTab('ingredients') }}
                              className={`${currentTab === 'ingredients' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Ingredients</a>
                        </li>
                        <li className="w-full">
                            <a href="#" 
                              onClick={() => { setCurrentTab('categories') }}
                              className={`${currentTab === 'categories' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Categories</a>
                        </li>
                        <li className="w-full">
                            <a href="#"
                              onClick={() => { setCurrentTab('preferences') }}
                              className={`${currentTab === 'preferences' ? 'text-white bg-black active' : ''} inline-block w-full p-2 rounded-t-lg`}>Preferences</a>
                        </li>
                    </ul>
            
                </div>
            </div>

            { 
              currentTab === 'ingredients' ? 
                <div>
                  <SearchSelectForm 
                      label="Filter Ingredients"
                      limit={3}
                      options={ingredientOptions} 
                      selectedOptions={selectedFilters}
                      onSelectItem={(newValue, option) => { handleSelectIngredient(newValue, option) }}
                      handleOnSelectSearchFocus={(newValue) => { }}
                      showOptions={currentTab === 'ingredients'}
                  />
                </div> : <></> 
            }

            { 
              currentTab === 'categories' ?
                <div>
                  <SearchSelectForm 
                      label="Filter Categories"
                      limit={3}
                      options={categoryOptions} 
                      selectedOptions={selectedFilters}
                      onSelectItem={(newValue, option) => { handleSelectCategory(newValue, option) }}
                      handleOnSelectSearchFocus={(newValue) => { }}
                      showOptions={currentTab === 'categories'}
                  />
                </div> : <></> 
            }
          
            { 
              currentTab === 'preferences' && 
                <div className="h-[320px]">
                  <div className="mb-6 pt-6 px-2 flex flex-wrap">
                    <div className="flex  py-2 w-1/2 items-center">
                        <input 
                            onChange={(e) => { handleSelectPreference(e) }}
                            checked={isSelectedPreference('Vegetarian')} 
                            id="Vegetarian" 
                            type="checkbox" 
                            value="Vegetarian" 
                            className="w-4 h-4 text-black bg-white border-black rounded" 
                        />
                        <label 
                            htmlFor="Vegetarian" 
                            className="ml-2 text-sm font-medium text-black"
                        >Vegetarian</label>
                    </div>

                    <div className="flex py-2 w-1/2 items-center">
                        <input 
                            onChange={(e) => { handleSelectPreference(e) }}
                            checked={isSelectedPreference('Vegan')} 
                            id="Vegan" 
                            type="checkbox" 
                            value="Vegan" 
                            className="w-4 h-4 text-black bg-white border-black rounded" 
                        />
                        <label 
                            htmlFor="Vegan" 
                            className="ml-2 text-sm font-medium text-black"
                        >Vegan</label>
                    </div>

                    <div className="flex py-2 w-1/2 items-center">
                        <input 
                            onChange={(e) => { handleSelectPreference(e) }}
                            checked={isSelectedPreference('Gluten Free')}  
                            id="GlutenFree" 
                            type="checkbox" 
                            value="Gluten Free" 
                            className="w-4 h-4 text-black bg-white border-black rounded" 
                        />
                        <label 
                            htmlFor="GlutenFree" 
                            className="ml-2 text-sm font-medium text-black"
                        >Gluten Free</label>
                    </div>

                    <div className="flex py-2 w-1/2 items-center">
                        <input 
                            onChange={(e) => { handleSelectPreference(e) }}
                            checked={isSelectedPreference('Sugar Free')}
                            id="SugarFree" 
                            type="checkbox" 
                            value="Sugar Free" 
                            className="w-4 h-4 text-black bg-white border-black rounded" 
                        />
                        <label 
                            htmlFor="SugarFree" 
                            className="ml-2 text-sm font-medium text-black"
                        >Sugar Free</label>
                    </div>

                    <div className="flex py-2 w-1/2 items-center">
                        <input 
                            onChange={(e) => { handleSelectPreference(e) }}
                            checked={isSelectedPreference('Dairy Free')}
                            id="DairyFree" 
                            type="checkbox" 
                            value="Dairy Free" 
                            className="w-4 h-4 text-black bg-white border-black rounded" 
                        />
                        <label 
                            htmlFor="DairyFree" 
                            className="ml-2 text-sm font-medium text-black"
                        >Dairy Free</label>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>

        <div className="flex justify-between w-screen px-2 pt-2 mb-3">
            <div>
                <button
                    className="px-4 py-2 text-black border bg-white border-black hover:bg-black hover:text-white rounded"
                    onClick={handleCancel}
                >
                    Cancel
                </button>

                {/* <button
                    className="px-4 py-2 ml-2 text-black border bg-white border-black hover:bg-black hover:text-white rounded"
                    onClick={handleRemember}
                >
                    Remember
                </button> */}
            </div>

            <button
                className="px-4 py-2 text-white bg-black border border-black hover:bg-black hover:text-white rounded"
                onClick={handleConfirm}
            >
                Apply
            </button>
      </div>
      </div>


    </div>
  )
}

export default FilterDialog
