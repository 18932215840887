/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/solid'

/** state */
import { getRecipe } from '../../actions/recipe.actions'

export default function MealplanPanelListItem({ plan, i }) {
    const dispatch = useDispatch()

    const getRecipeDetail = async (recipeId) => {
        // const recipeDetails = await dispatch(getRecipe(recipeId))
        // console.warn('getRecipeDetail', recipeDetails)
        // return recipeDetails
    }

    return (
        <>
            {
                plan?.recipes?.length > 0 ? 
                    <div>
                        {/* <div className="bg-gray-200 flex justify-between items-center px-1">
                            <label 
                                className="font-light py-1"
                                style={{fontSize: 12}}
                            >{plan?.name}</label>

                            <Link to={`/mealplans/${plan.user_mealplan_id}`}>
                                <button
                                className="text-xs w-6 h-6 px-1 ml-1 pt-1 text-black rounded-full"
                                >
                                <EyeIcon />
                                </button>   
                            </Link>

                        </div> */}

                        
                        {
                            plan?.recipes.map((day, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            day?.recipes.map((recipe, i) => {
                                                // @todo get the recipe details
                                                const recipeDetails = recipe
                                                
                                                // plan?.recipes.find((r) => {
                                                //     return r.recipe_id === recipe?.value
                                                // })
            
                                                return (
                                                <div className="flex justify-between px-1 py-2 items-center border-t border-gray-400 pb-1" key={i}>
                                                    <div>
                                                        <label className="font-light text-md">{recipeDetails?.name}</label>
                                                        <small className="block text-xs">
                                                            {/* {recipeDetails?.recipe_ingredients.length} ingredient(s) */}
                                                        </small>
                                                    </div>
                                                    {
                                                        recipe?.value && (
                                                            <Link to={`/recipes/${recipeDetails.value}`}>
                                                                <button
                                                                className="text-xs w-6 h-6 px-1 ml-1 pt-1 text-gray-400 rounded-full"
                                                                >
                                                                <EyeIcon />
                                                                </button>   
                                                            </Link>
                                                        )
                                                    }
                                                    
                                                </div>
                                                )
                                            })
                                        }                                                
                                    </div>
                                )
                            })
                        }
                    </div> : <></>
            }
        </>
    )
}