/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { useToast } from '../../hooks/useToast'
import { computeQuantity } from '../Utility/ComputeQuantityUnits'

/** state */
import { deleteMealplan, updateMealplan } from '../../actions/user.actions'

const CompleteMealplanDialog = ({
    showDialog = false,
    completedMeals,
    onConfirm = () => { },
    onCancel = () => { } 
}) => {
    // vendor utils
    const dispatch = useDispatch()

    // a9 utils
    const toast = useToast()

    // ui states
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deletePlan, setDeletePlan] = useState(true)
    const [storeComplete, setStoreComplete] = useState(true)
    const [reusePlan, setReusePlan] = useState(false)
    const [adjustIngredients, setAdjustIngredients] = useState(false)    
    const [newStartDate, setNewStartDate] = useState('')
    
    // user data
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
    const sharedWithMeFoodStores = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserFoodstore'))
    const foodstores = useSelector(state => state.user_data?.user_stores)

    // form data
    const [checkedIngredients, setCheckedIngredients] = useState([])
    const [ingredientsInStores, setIngredientsInStores] = useState([])
    const [ingredientsNotInStores, setIngredientsNotInStores] = useState([])

    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    useEffect(() => {
        if(!loading && completedMeals?.length > 0 && foodstores) {
          const meaplanIngredients = []
          const ingredientsInStoresIds = []
          const ingredientsNotinStoresIds = []
    
          completedMeals.forEach((recipe, index) => {
            recipe?.recipe_ingredients.forEach((ingredient, i) => {
              meaplanIngredients.push(ingredient)
            })
          })
    
          meaplanIngredients.forEach(ingredient => {
            let inStock = false
            let itemToAdd = null
            let storeId = null
            let storeName = null
            let storeQuantity = 0
    
            for(const store of foodstores) {
              for(const item of store?.items) {
                if(item?.value === ingredient?.ingredient_id) {
                    storeId = store.id
                    storeName = store.name
                    inStock = true
                    itemToAdd = item 
                    storeQuantity = item?.quantity
                    break
                }
              } 
    
              if(inStock)
              {
                break
              }
            }
            
            if(inStock && itemToAdd) {
              ingredientsInStoresIds.push({
                storeId,
                storeName,
                value: ingredient?.ingredient_id, 
                quantity: storeQuantity || 0, 
                measurement: ingredient?.measurement
              })
            } else {
              ingredientsNotinStoresIds.push({
                value: ingredient?.ingredient_id, 
                quantity: ingredient?.quantity, 
                measurement: ingredient?.measurement
              })
            }
          })
    
          setCheckedIngredients(ingredientsInStoresIds)
          setIngredientsNotInStores(ingredientsNotinStoresIds)
          setIngredientsInStores(ingredientsInStoresIds)
        }
    }, [completedMeals, loading, foodstores])

    const handleDeleteChange = (e) => {
        setDeletePlan(!deletePlan)
    }

    const handleStoreChange = (e) => {
        setStoreComplete(!storeComplete)
    }

    const handleReusePlanChange = (e) => {
        setReusePlan(!reusePlan)
    }

    const handleAdjustmentChange = (e) => {
        setAdjustIngredients(!adjustIngredients)
    }

    const handleNewStartDateSelect = (e) => {
        setNewStartDate(e.target.value)
    }

    const handleOnToggleCheckedIngredient = (e, ingredient) => {
        if (e.target.checked) {
            // @todo fix 'units()' being the measurement it should be 'unit' this is caused somewhere else in the app
            const newIngredients = [...checkedIngredients, {
                value: ingredient?.ingredient_id,
                quantity: ingredient?.quantity || 1,
                measurement: ingredient?.measurement || 1
            }]
      
            setCheckedIngredients(newIngredients)
        } else {
            const newIngredients = checkedIngredients.filter((item) => item?.value !== ingredient?.ingredient_id)
            setCheckedIngredients(newIngredients)    
        }
    }
    
    const handleConfirm = () => {
        if(storeComplete) {
            // @todo remove from store
        }

        if(reusePlan) {
            dispatch(updateMealplan()).then(() => {
              }).catch(() => {
              })
        }
    
        if(deletePlan) {
            dispatch(deleteMealplan()).then(() => {
              }).catch(() => {
              })
        }
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'} items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="bg-white p-8 fixed bottom-20 rounded-md shadow-md w-[90vw] z-50">
                <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Complete Mealplan</h2>

                <div className="flex min-w-full justify-center">
                    <div className="w-full">
                        {
                            adjustIngredients ? 
                                <div className="h-96 overflow-y-auto">
                                    { 
                                        completedMeals.length !== 0 &&
                                            <ul className="space-y-4" style={{ height: 'calc(70vh - 172px)', overflow: 'auto' }}>
                                                {completedMeals.map((recipe, index) => (
                                                    <li key={index}>
                                                        <strong className="block pb-2">{recipe?.name}</strong>

                                                        {
                                                            recipe?.recipe_ingredients?.map((ingredient, i) => {
                                                                const ingredientDetail = ingredient.ingredient                                
                                                                const inStore = checkedIngredients?.filter(i => i?.value === ingredientDetail.ingredient_id)
                                                                const instoreCount = ingredientsInStores.find(i => i?.value === ingredientDetail.ingredient_id)

                                                                return (
                                                                    <div key={i}>
                                                                        <div className="border-t border-black py-1 pl-2 flex justify-between items-center">
                                                                            <div className={`${!instoreCount && 'text-red-600'}`}>
                                                                                {ingredient?.quantity} {ingredient?.measurement} {ingredientDetail?.name}
                                                                            </div>
                                                                            {
                                                                                inStore?.length > 0 &&
                                                                                    <div className="flex py-2 items-center pr-4">
                                                                                        <input 
                                                                                            onChange={(e) => {handleOnToggleCheckedIngredient(e, ingredient, recipe )}}
                                                                                            checked={inStore} 
                                                                                            id={`sendTolist_${i}`} 
                                                                                            type="checkbox" 
                                                                                            value={ingredientDetail.ingredient_id}
                                                                                            className="w-4 h-4 text-black bg-white border-black rounded" 
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            inStore?.length > 0 &&
                                                                                <div className="mt-[-6px]">
                                                                                    {
                                                                                        inStore?.map((i) => {
                                                                                            if(i?.quantity === 0) { return <></> }

                                                                                            return (
                                                                                                <div className="text-xs opacity-60 pl-2 pb-1">
                                                                                                    {i?.quantity} {i?.measurement} found in {i?.storeName}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>                                                                        
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                    }   
                                </div>
                            : ''
                        }

                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleAdjustmentChange}
                                checked={adjustIngredients} 
                                id="adjustIngredidents" 
                                type="checkbox" 
                                value="adjustIngredidents" 
                                className="w-4 h-4 text-black bg-white border-black rounded" 
                            />
                            <label 
                                htmlFor="deleteList" 
                                className="ml-2 text-sm font-medium text-black"
                            >Review and Adjust Ingredients</label>
                        </div>


                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleDeleteChange}
                                checked={deletePlan} 
                                id="deleteList" 
                                type="checkbox" 
                                value="deleteList" 
                                className="w-4 h-4 text-black bg-white border-black rounded" 
                            />
                            <label 
                                htmlFor="deleteList" 
                                className="ml-2 text-sm font-medium text-black"
                            >Delete and discard remaining meals</label>
                        </div>

                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleStoreChange}
                                checked={storeComplete} 
                                id="storeComplete" 
                                type="checkbox" 
                                value="storeComplete" 
                                className="w-4 h-4 text-black bg-white border-black rounded" 
                            />
                            <label 
                                htmlFor="storeComplete" 
                                className="ml-2 text-sm font-medium text-black"
                            >Store complete meals</label>
                        </div>

                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleReusePlanChange}
                                checked={reusePlan} 
                                id="reusePlan" 
                                type="checkbox" 
                                value="reusePlan" 
                                className="w-4 h-4 text-black bg-white border-black rounded" 
                            />
                            <label 
                                htmlFor="reusePlan" 
                                className="ml-2 text-sm font-medium text-black"
                            >Use mealplan again</label>
                        </div>
                        
                        {
                            reusePlan ? 
                                <div className="pt-1 mt-2 border-t border-black">
                                    <label className="block py-2 text-xs w-full">New start date (Optional)</label>
                                    <input 
                                        type="date" 
                                        value={newStartDate} 
                                        onChange={handleNewStartDateSelect}
                                        className="w-full border-black rounded-lg" 
                                    />
                                </div>   
                            : ''
                        }
                        
                        
                    </div>
                </div>
            </div>

            <div className="flex justify-between mt-6 w-full fixed bottom-3 px-6">
                <button
                    className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
                    onClick={handleCancel}
                >
                    Cancel
                </button>

                <button
                    className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                    onClick={handleConfirm}
                >
                    Confirm
                </button>
            </div>
        </div>
    )
}

export default CompleteMealplanDialog
