const initialState = { current_shoppinglist: {}}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'FETCH_SHOPPINGLIST_SUCCESS':
          return  payload.shoppinglist
          
        case 'FETCH_SHOPPINGLIST_FAIL':
          return {
            ...state,
          };
        default:
          return state
      }
}