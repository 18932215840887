/** vendor */
import React, { useState } from 'react'
import Loading from '../UI/Loading'

/** components */
import RecipeListItem from '../Recipes/RecipeListItem'
import AllergenDialog from '../Dialogs/AllergenDialog'

export default function HomeRecipesTab({ recipes }) {
    
    const [allergenDialogContent, setAllergenDialogContent] = useState([])
    const [showAllergens, setShowAllergens] = useState(false)
    const toggleAllergens = e => { setShowAllergens(!showAllergens) }

    const handleOnOpenAllergenDialog = (allergens) => {
        setAllergenDialogContent(allergens)
        setShowAllergens(true)
    }

    return (
        <div className="h-[120px]  items-center w-full mx-auto">
            <section className="antialiased text-gray-600 px-4">
                <div className="flex flex-col justify-center pt-4">
                    <div className="p-3">
                        <div>
                            { 
                                recipes ?
                                    recipes.map((r) => 
                                        <RecipeListItem handleOnOpenAllergenDialog={handleOnOpenAllergenDialog} recipe={r} key={r.recipe_id} />)
                                        : 'No Recipes Yet' 
                            }
                        </div>
                        
                    </div>
                </div>
            </section>

            <AllergenDialog showDialog={showAllergens} onCancel={toggleAllergens} allergens={allergenDialogContent}  />
        </div>
    )
}