import auth from './auth'
import recipes from './recipes'
import current_recipe from './recipe'
import current_shoppinglist from './shoppinglist'
import form_options from './formoptions'
import user_data from './user'
import current_mealplan from './mealplan'

import { combineReducers } from 'redux'

const reducers = combineReducers({ 
    auth, 
    recipes, 
    current_recipe,
    current_shoppinglist,
    current_mealplan,
    form_options,
    user_data 
})

export default reducers