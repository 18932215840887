import * as UserService from '../services/user.service'

const resolver = (p, success, failure, dispatch) => {
  return p.then((data) => {
    const storeKey = success.split('_').slice(1, -1).join('_').toLowerCase()

    dispatch({
      type: success,
      payload: { 
        [storeKey]: data 
      }
    })

    return data
  },
  (error) => {
    dispatch({
      type: failure,
      message: error
    })
  
    return Promise.reject(error)
  })
}

export const getSharedWithMe = () => (dispatch) => {
  return resolver(
    UserService.fetchSharedWithMe(),
    'FETCH_SHARED_WITH_ME_SUCCESS',
    'FETCH_SHARED_WITH_ME_FAIL',
    dispatch
  )
}

/** User Created Recipes */
export const createUserRecipe = (payload) => (dispatch) => {
  return resolver(
    UserService.createRecipe(payload),
    'CREATE_RECIPE_SUCCESS',
    'CREATE_RECIPE_FAIL',
    dispatch
  )
}

export const updateUserRecipe = (recipe_id, payload) => (dispatch) => {
  return resolver(
    UserService.updateRecipe(recipe_id, payload),
    'UPDATE_RECIPE_SUCCESS',
    'UPDATE_RECIPE_FAIL',
    dispatch
  )
}

export const deleteUserRecipe = (recipe_id) => (dispatch) => {
  return resolver(
    UserService.deleteRecipe(recipe_id),
    'DELETE_RECIPE_SUCCESS',
    'DELETE_RECIPE_FAIL',
    dispatch
  )
}

/** User Imported Recipes */
export const getImportedRecipes = () => (dispatch) => {
  return resolver(
    UserService.fetchUserImportedRecipes(),
    'FETCH_IMPORTED_RECIPES_SUCCESS',
    'FETCH_IMPORTED_RECIPES_FAIL',
    dispatch
  ) 
}

export const getImportedRecipe = (id) => (dispatch) => {
  return resolver(
    UserService.fetchUserImportedRecipe(id),
    'FETCH_IMPORTED_RECIPE_SUCCESS',
    'FETCH_IMPORTED_RECIPE_FAIL',
    dispatch
  ) 
}

export const importRecipe = (payload) => (dispatch) => {
  return resolver(
    UserService.importRecipe(payload),
    'IMPORT_RECIPE_SUCCESS',
    'IMPORT_RECIPE_FAIL',
    dispatch
  )
}

export const removeImportedRecipe = (recipe_id) => (dispatch) => {
  return resolver(
    UserService.removeImportedRecipe(recipe_id),
    'REMOVE_IMPORTED_RECIPE_SUCCESS',
    'REMOVE_IMPORTED_RECIPE_FAIL',
    dispatch
  )
}

/** User Saved Recipes */
export const getSavedRecipes = () => (dispatch) => {
  return resolver(
    UserService.fetchUserSavedRecipes(),
    'FETCH_SAVED_RECIPES_SUCCESS',
    'FETCH_SAVED_RECIPES_FAIL',
    dispatch
  ) 
}

export const createSavedRecipe = (payload) => (dispatch) => {
  return resolver(
    UserService.saveRecipe(payload),
    'CREATE_SAVED_RECIPE_SUCCESS',
    'CREATE_SAVED_RECIPE_FAIL',
    dispatch
  )
}

export const deleteSavedRecipe = (recipe_id) => (dispatch) => {
  return resolver(
    UserService.removeSavedRecipe(recipe_id),
    'DELETE_SAVED_RECIPE_SUCCESS',
    'DELETE_SAVED_RECIPE_FAIL',
    dispatch
  )
}

/** User Mealplans */
export const getUserMealplans = () => (dispatch) => {
  return resolver(
    UserService.fetchUserMealplans(),
    'FETCH_MEALPLANS_SUCCESS',
    'FETCH_MEALPLANS_FAIL',
    dispatch
  )
}

export const getUserMealplan = (id) => (dispatch) => {
  return resolver(
    UserService.fetchUserMealplan(id),
    'FETCH_MEALPLAN_SUCCESS',
    'FETCH_MEALPLAN_FAIL',
    dispatch
  )
}

export const createMealplan = (payload) => (dispatch) => {
  return resolver(
    UserService.createMealplan(payload),
    'CREATE_MEALPLAN_SUCCESS',
    'CREATE_MEALPLAN_FAIL',
    dispatch
  )
}

export const updateMealplan = (mealplan_id, payload) => (dispatch) => {
  return resolver(
    UserService.updateMealplan(mealplan_id, payload),
    'UPDATE_MEALPLAN_SUCCESS',
    'UPDATE_MEALPLAN_FAIL',
    dispatch
  )
}

export const deleteMealplan = (mealplan_id) => (dispatch) => {
  return resolver(
    UserService.deleteMealplan(mealplan_id),
    'DELETE_MEALPLAN_SUCCESS',
    'DELETE_MEALPLAN_FAIL',
    dispatch
  )
}

/** User Shoppinglists */
export const getShoppinglists = () => (dispatch) => {
  return resolver(
    UserService.fetchShoppinglists(),
    'FETCH_SHOPPINGLISTS_SUCCESS',
    'FETCH_SHOPPINGLISTS_FAIL',
    dispatch
  )
}

export const getShoppinglist = (shoppinglist_id) => (dispatch) => {
  return resolver(
    UserService.fetchShoppinglist(shoppinglist_id),
    'FETCH_SHOPPINGLIST_SUCCESS',
    'FETCH_SHOPPINGLIST_FAIL',
    dispatch
  )
}

export const createShoppinglist = (payload) => (dispatch) => {
  return resolver(
    UserService.createShoppinglist(payload),
    'CREATE_SHOPPINGLIST_SUCCESS',
    'CREATE_SHOPPINGLIST_FAIL',
    dispatch
  )
}

export const updateShoppinglist = (shoppinglist_id, payload) => (dispatch) => {
  return resolver(
    UserService.updateShoppinglist(shoppinglist_id, payload),
    'UPDATE_SHOPPINGLIST_SUCCESS',
    'UPDATE_SHOPPINGLIST_FAIL',
    dispatch
  )
}

export const deleteShoppinglist = (shoppinglist_id) => (dispatch) => {
  return resolver(
    UserService.deleteShoppinglist(shoppinglist_id),
    'DELETE_SHOPPINGLIST_SUCCESS',
    'DELETE_SHOPPINGLIST_FAIL',
    dispatch
  )
}


/** User Profile */
export const getUserProfile = () => (dispatch) => {
  return resolver(
    UserService.fetchUserProfile(),
    'FETCH_USER_PROFILE_SUCCESS',
    'FETCH_USER_PROFILE_FAIL',
    dispatch
  )
}

export const updateUserProfile = (payload) => (dispatch) => {
  return resolver(
    UserService.updateUserProfile(payload),
    'UPDATE_USER_PROFILE_SUCCESS',
    'UPDATE_USER_PROFILE_FAIL',
    dispatch
  )
}

export const deleteUserProfile = () => (dispatch) => {
  return resolver(
    UserService.deleteUserProfile(),
    'DELETE_USER_PROFILE_SUCCESS',
    'DELETE_USER_PROFILE_FAIL',
    dispatch
  )
}

/** User Foodstore */
export const getUserStore = (foodstore_id) => (dispatch) => {
  return resolver(
    UserService.fetchUserStore(foodstore_id),
    'FETCH_USER_STORE_SUCCESS',
    'FETCH_USER_STORE_FAIL',
    dispatch
  )
}
export const getUserStores = () => (dispatch) => {
  return resolver(
    UserService.fetchUserStores(),
    'FETCH_USER_STORES_SUCCESS',
    'FETCH_USER_STORES_FAIL',
    dispatch
  )
}

export const createUserStore = (payload) => (dispatch) => {
  return resolver(
    UserService.createUserStore(payload),
    'CREATE_USER_STORE_SUCCESS',
    'CREATE_USER_STORE_FAIL',
    dispatch
  )
}

export const deleteUserStore = (foodstore_id) => (dispatch) => {
  return resolver(
    UserService.deleteUserStore(foodstore_id),
    'DELETE_USER_STORE_SUCCESS',
    'DELETE_USER_STORE_FAIL',
    dispatch
  )
}

export const updateUserStore = (foodstore_id, payload) => (dispatch) => {
  return resolver(
    UserService.updateUserStore(foodstore_id, payload),
    'UPDATE_USER_STORE_SUCCESS',
    'UPDATE_USER_STORE_FAIL',
    dispatch
  )
}

export const setDefaultUserStore = (foodstore_id) => (dispatch) => {
  return resolver(
    UserService.setDefaultUserStore(foodstore_id),
    'UPDATE_USER_STORE_SUCCESS',
    'UPDATE_USER_STORE_FAIL',
    dispatch
  )
}
