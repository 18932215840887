/** vendor */
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** components */
import SaveRecipeForm from '../Forms/SaveRecipeForm'

export default function RecipeListItem({
    recipe,
    handleOnOpenAllergenDialog = (allergens) => {}
}) {
    const navigate = useNavigate()

    const handleClick = (recipe_id) => {
        navigate(`/recipes/${recipe_id}`)
    }

    const allergens = recipe.recipe_ingredients.filter((i) => {
        return (i.ingredient.allergen)
    })

    const vegan = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.vegan)
    }) === undefined

    const vegetarian = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.vegetarian)
    }) === undefined    

    const sugarFree = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.diabetes_ok)
    }) === undefined

    const glutenFree = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.gluten_free)
    }) === undefined

    const recipeImage = (recipe.image) ? `${IMG_URL}/${recipe.image}` : PLACEHOLDER_IMG

    return (
        <div className="w-full p-1 px-2 mb-4 hover:bg-black hover:text-white">
            <small className="text-left text-gray-400 pb-2 block">A9 Recipes: {recipe.category?.name}</small>
            <div className="flex-1 border-b border-black cursor-pointer" 
                onClick={() => { handleClick(recipe?.recipe_id) }}
            >    
                <div>                                                
                    <div className="font-medium">{ recipe.name }</div>
                    <div className="text-left flex-wrap text-sm flex-shrink-1 py-1">{recipe.description}</div>
                    <small className="text-left text-xs pb-2 block">Serves: {recipe.serves}</small>
                </div>               
            </div>

            <div className="flex w-full justify-between">
                <div className="flex">
                    { vegetarian && !vegan ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">V</span> : <></> }
                    { vegan ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">VG</span> : <></> }
                    { glutenFree ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">GF</span> : <></> }
                    { sugarFree ? <span className="flex items-center justify-center leading-none border border-black mr-1 text-black text-[10px] w-5 h-5 mt-1 text-center rounded-full">SF</span> : <></> }
                    { 
                        allergens.length > 0  
                            ? <div className="text-orange-400 opacity-40 text-xs h-6 pt-1 flex items-center text-center">
                                <ExclamationCircleIcon className="h-6 w-6" onClick={() => {handleOnOpenAllergenDialog(allergens)} }/>
                            </div> : <></> 
                    }                     
                </div>
                
                <SaveRecipeForm recipe_id={recipe.recipe_id} />
            </div>
        </div>
    )
}