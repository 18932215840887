/** vendor */
import React, { useEffect, useState } from 'react'

const AllergenDialog = ({ 
    title = 'Allergen Warning',
    showDialog = false, 
    allergens,
    onCancel = () => { } 
}) => {
  const [isOpen, setIsOpen] = useState(true)

    /**
     * Hooks
     */
    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    return (
        <div
            className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
                isOpen ? 'flex' : 'hidden'
            } items-center justify-center`}
        >
        <div className="fixed inset-0 bg-black opacity-30"></div>
            <div className="bg-white p-4 rounded-md shadow-md w-96 max-w-full z-50">
                <h2 className="text-xl text-center border-b pb-1 border-black font-semibold">{title}</h2>
                <small className="text-center text-xs block w-full pb-4">this recipe contains known allergens</small>
                <ul className="text-gray-700 mb-6">
                    {
                        allergens && allergens.map((a, i) => {
                            return (
                                <li key={i} className=" border-b py-1 border-grey-200">{a?.ingredient?.name}</li>
                            )
                        })
                    }
                </ul>

                <div className="flex justify-end">
                    <button
                        className="mr-2 px-4 py-2 text-black border border-black rounded"
                        onClick={handleCancel}>Ok</button>
                </div>
            </div>
        </div>
    )
}

export default AllergenDialog
