/** vendor */
import React, { useState } from 'react'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import Terms from '../../components/Legal/Terms'
import Privacy from '../../components/Legal/Privacy'
import Allergy from '../../components/Legal/Allergy'


export default function Legal() {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('terms')

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
        <Card title="Legal Notices" autoHeight="true">
          
          <div className="w-full flex flex-col items-center" style={{ height: 'calc(100vh - 140px)' }}>
            <ul className="mb-4 w-full flex justify-center flex-wrap text-sm font-medium text-center border-b pb-2">
                <li className="mr-2">
                    <a 
                      href="#terms" 
                      aria-current="page"
                      onClick={handleToggleTab}  
                      className={`${tab === 'terms' ? 'active bg-black text-white': ''} inline-block py-1 px-2 rounded`}
                    >
                        Terms
                    </a>
                </li>
                <li className="mr-2">
                    <a 
                      href="#privacy"
                      onClick={handleToggleTab} 
                      className={`${tab === 'privacy' ? 'active bg-black text-white': ''} inline-block py-1 px-2 rounded`}
                    >
                      Privacy
                    </a>
                </li>
                <li className="mr-2">
                    <a 
                      href="#allergen"
                      onClick={handleToggleTab} 
                      className={`${tab === 'allergen' ? 'active bg-black text-white': ''} inline-block py-1 px-2 rounded`}
                    >
                      Allergens
                    </a>
                </li>
            </ul>

            { tab === 'terms' && <Terms /> }
            { tab === 'privacy' && <Privacy /> }
            { tab === 'allergen' && <Allergy /> }
          </div>
        </Card>
    </div>
  )
}