/** vendor */
import React, {useState} from 'react'
import { useDispatch } from 'react-redux'

/** state */
import { importRecipe } from '../../actions/user.actions'

export default function ImportForm({ 
    onImportSuccess = () => {},
    onImportFail = () => {} 
}) {
    const dispatch = useDispatch()
    const [url, setUrl] = useState('')
    const [importing, setImporting] = useState(false)

    const isValidHttpUrl = (string) => {
        let url
        try {
          url = new URL(string)
        } catch (_) {
          return false
        }
        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const handleChange = async e => {
        e.preventDefault()
        setUrl(e.target.value)
    }

    const handleClick = async e => {
        e.preventDefault()

        console.warn('import!')

        if(isValidHttpUrl(url)) {
            setImporting(true)

            dispatch(importRecipe({ url })).then(() => {
                setImporting(false)
                onImportSuccess()
            }).catch((e) => {
                console.error(e)
                setImporting(false)
                onImportFail()
            })
        }
    }

    return (
        <div className="flex justify-center items-center mb-4">
            <div className="mb-2 pt-2 w-96">
                <div className="flex">
                    <input 
                        type="text" 
                        id="recipe" 
                        className=" border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="enter a url"
                        onChange={handleChange}
                    />
                    <div className="pl-2">
                        <button
                            onClick={(e) => { handleClick(e) }}
                            className="bg-white border border-black hover:bg-black hover:text-white font-bold py-2 px-4 rounded" type="submit">
                            Import
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

