/** vendor */
import React, { useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

export default function MatchIngredientForm({
    label = 'Search ...',
    options = [], 
    selectedOptions = [],
    showTabs = false,
    showOptions = false,
    showCreateCustom = false,
    onCreateCustomItem = (option, index) => {}, 
    limit = 0,
    onSelectItem = () => { console.warn('MatchIngredientForm: callback missing') },
    handleOnSelectSearchFocus  = () => {}
}) {
    const [allOptions, setAllOptions] = useState(options)
    const [filteredSortedOptions, setFilteredSortedOptions] = useState(options)
    const savedRecipes = useSelector(state => state?.user_data?.saved_recipes)
    const [currentTab, setCurrentTab] = useState(showTabs ? 'savedRecipes' : 'allOptions')
    const [currentSearch, setCurrentSearch] = useState('')

    /**
     * Hooks
     */
    useEffect(() => {
        if(options && options !== filteredSortedOptions) {
            setAllOptions(options)
            setFilteredSortedOptions(options)
        }
    }, [options, selectedOptions])

    /**
     * Handlers
     */
    const handleSearchAsync = e => {
        // @todo search by api if recipes
    }

    const handleSearch = e => {
        if(e.target.value.length < 1) {
            let optionsLimit = [...options]

            setFilteredSortedOptions(allOptions)
            return
        }

        if(e.target.value.length > 2) {
            let filteredOptions = [...allOptions].filter(i => {
                const lowerName = i.name.toLowerCase().trim()
                const lowerValue = e.target.value.toLowerCase().trim()
                return lowerName === lowerValue || lowerName.includes(lowerValue)
            })
            
            setFilteredSortedOptions(filteredOptions)
        }
    }

    const handleSelection = (option, index) => {
        onSelectItem(index, option)
    }

    const handleCreateCustomItem = () => {
        onCreateCustomItem(null, {name: currentSearch, quantity: 1, image: null, value: 'CUSTOM_' + uuidv4()})        
    }

    return (
        <div className="w-full">
            <div className={`w-full ${showOptions ? 'p-2' : ''}`}>
                {
                    showOptions &&
                        <div className="bg-white rounded-lg w-full border border-black pb-2">
                            <div className="p-2">
                                <label htmlFor="input-group-search" className="sr-only">{label}</label>

                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg 
                                            className="w-5 h-5 text-black" 
                                            aria-hidden="true" 
                                            fill="currentColor" 
                                            viewBox="0 0 20 20" 
                                            xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <input 
                                        type="text" 
                                        className="block w-full p-2 pl-10 text-sm text-black border border-border rounded" 
                                        placeholder={label} 
                                        onChange={(e) => {
                                            setCurrentSearch(e.target.value) 
                                            handleSearch(e) 
                                        }}
                                    />
                                    { 
                                        showCreateCustom && 
                                            <button
                                                disabled={filteredSortedOptions?.length > 0}
                                                onClick={() => { handleCreateCustomItem() } }
                                                className={`w-6 h-6 absolute inset-y-0 top-[6px] right-1 ${ filteredSortedOptions?.length > 0 && 'opacity-20' }`}
                                            >
                                                <PlusCircleIcon className="text-green-400" />
                                            </button>
                                    }
                                </div>
                            </div>          

                            <ul className="px-3 pb-3 overflow-y-auto text-sm h-[120px]">
                                {
                                    filteredSortedOptions && filteredSortedOptions.length > 0 ?
                                        filteredSortedOptions.map((option, index) => (
                                            <li 
                                                key={`select-search-option-${index}`} 
                                                className={`border-b border-gray-200 py-1`}
                                            >
                                                <div className="flex items-center pl-2 rounded">
                                                    <label 
                                                        className="w-full py-2 text-sm font-medium text-black rounded"
                                                    >
                                                        {option.name}
                                                    </label>
                                                    <button
                                                        onClick={() => { handleSelection(option, index)} }
                                                        className="w-6 h-6 ml-auto mr-1"
                                                    >
                                                        <PlusCircleIcon className="" />
                                                    </button>
                                                </div>
                                            </li> 
                                        )) : 
                                        <li>                        
                                            <div className="flex items-center pl-2 rounded">
                                                <label 
                                                    className="w-full py-2 text-sm font-medium text-black rounded"
                                                >
                                                    No Options Found ...
                                                </label>
                                            </div>
                                        </li>                           
                                }    
                            </ul> 
                        </div>
                }
            </div>
        </div>
    )
}
