/** vendor */
import React, { useState } from 'react'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'

/** components */
import SavedRecipeList from '../../components/Recipes/SavedRecipeList'
import ImportedRecipeList from '../../components/Recipes/ImportedRecipeList'
import CustomRecipeList from '../../components/Recipes/CustomRecipeList'

export default function UserRecipes() {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('saved')

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
        <Card title="My Recipes" autoHeight="true">
          
          <div className="w-full flex flex-col items-center" style={{ height: 'calc(100vh - 140px)' }}>
            <ul className="w-full flex justify-center flex-wrap text-sm font-medium text-center border-b pb-2">
                <li className="w-1/3 text-center">
                    <a 
                      href="#saved" 
                      aria-current="page"
                      onClick={handleToggleTab}  
                      className={`${tab === 'saved' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                        Saved
                    </a>
                </li>
                <li className="w-1/3 text-center">
                    <a 
                      href="#custom"
                      onClick={handleToggleTab} 
                      className={`${tab === 'custom' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                      Custom
                    </a>
                </li>
                <li className="w-1/3 text-center">
                    <a 
                      href="#imported"
                      onClick={handleToggleTab} 
                      className={`${tab === 'imported' ? 'active bg-black text-white': ''} block py-1 px-2 rounded`}
                    >
                      Imported
                    </a>
                </li>
            </ul>

            { tab === 'saved' ? <SavedRecipeList /> : '' }
            { tab === 'custom' ? <CustomRecipeList /> : '' }
            { tab === 'imported' ? <ImportedRecipeList /> : '' }
          </div>
        </Card>
    </div>
  )
}