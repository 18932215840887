import React, { useState } from 'react'

import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import ContactForm from '../../components/Forms/ContactForm'

export default function GeneralEnquiry() {
  const [loading, setLoading] = useState(false)

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return(
    <div className="pt-16 w-full">
        <Card title="Contact us" enableBack={false}>
          <ContactForm />
        </Card>
    </div>
  )
}