/** vendor */
import React, { useState, useEffect } from 'react'

/** components */
import CreateStepDialog from '../../Dialogs/CreateStepDialog'

export default function CreateStepsForm({onUpdate, recipe}) {
    const [showDialog, setShowDialog] = useState(false)
    const [editStepId, setEditStepId] = useState(false);
    const [steps, setSteps] = useState([]);

    /**
     * Hooks
     */
    useEffect(() => {
        if (recipe?.steps) {
            setSteps(recipe?.steps)
        }
    }, [recipe])

    /**
     * Handlers
     */
    const handleOnUpdate = step => {
        onUpdate({steps})
        setShowDialog(false)
    }

    const handleOnCancel =  e => {
        setShowDialog(false)
    }

    return (
        <div className="min-w-full h-[60vh] relative">
            <button
                onClick={() => { setShowDialog(true) }}
                className="border border-black text-center block w-full absolute bottom-1 left-0 py-2 rounded hover:bg-black hover:text-white"
            >Create Step</button>

            <CreateStepDialog 
                showDialog={showDialog} 
                onCancel={handleOnCancel} 
                onUpdate={handleOnUpdate} 
                stepId={editStepId}
            />
        </div>
    )
}

