const initialState = {}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'FETCH_MEALPLAN_SUCCESS':
          return payload.mealplan.data
        case 'FETCH_MEALPLAN_FAIL':
          return {
            ...state,
          };
        default:
          return state
      }
}