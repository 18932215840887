/** vendor */
import React from 'react'

/** components */
import OptionDialog from "../Dialogs/OptionDialog"

export default function MealPlanOptions({
    mealplanId, 
    title, 
    showDialog,
    isSharedWithUser,
    canEdit,
    onAction = () => {}, 
    onCancel = () => {}
}) {
    const options = [
        
    ]

    if (!isSharedWithUser) {
        options.push({ id: 'delete', label: 'delete'})
    }

    if (isSharedWithUser) {
        options.push({ id: 'remove', label: 'remove'})
    }

    if (canEdit) {
        options.push({ id: 'edit', label: 'edit'})
        options.push({ id: 'schedule', label: 'schedule'})
    } 

    if (!isSharedWithUser) {
        options.push({ id: 'share', label: 'share'})
    }

    options.push({ id: 'check', label: 'check ingredients' })

    /**
     * Handlers
     */
    const handleOnOptionSelect = (selection) => {
        onAction(selection, mealplanId)
    }

    if (!showDialog) {
        return (<></>)
    }

    return (
        <>
            <OptionDialog
                options={options}
                title={title}
                onSelect={handleOnOptionSelect} 
                showDialog={showDialog}
                onCancel={() => {
                    onCancel()
                }}
            />
        </>


    )
}