/** vendor */
import React, { useState, useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'

/** state */
import { getRecipe } from '../../actions/recipe.actions'

/** components */
import RecipeDetail from '../../components/Recipes/RecipeDetail'
import RecipeCookMode from '../../components/Recipes/RecipeCookMode'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import OptionDialog from '../../components/Dialogs/OptionDialog'


export default function Recipe() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [cookMode, setCookMode] = useState(false)
    const [showShare, setShowShareDialog] = useState(false)
    const [showOptions, setShowOptions] = useState(false)

    const recipe = useSelector(state => state.current_recipe)

    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)

        const id = window.location.pathname.split('/')[2]

        dispatch(getRecipe(id)).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    /**
     * Handlers
     */
    const handleOnSave = () => {

    }

    const handleShowOptions = () => {
        setShowOptions(true)
    }

    const handleOnOptionSelect = (action, id) => {
        if (action === 'share') {

        }

        if (action === 'cook') {
            setCookMode(!cookMode)
            setShowOptions(false)
        }
    }


    if(loading) {
      return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }
  
    return (
        <div className="pt-16 min-w-full">
            <Card 
                title={recipe?.current_recipe?.name}
                status={
                    <button
                        className="text-xs h-6 w-6 text-black rounded-full"
                        onClick={ (e) => { handleShowOptions() } }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </button>
                }
            >
                <div className="min-w-full flex overflow-y-scroll justify-center card-no-footer">
                    { recipe && !cookMode ? <RecipeDetail recipe={recipe || null} /> : <></> }
                    { recipe && cookMode ? <RecipeCookMode recipe={recipe || null} /> : <></> }
                </div>
            </Card>

            <OptionDialog
                processing={false}
                options={[
                    { id: 'cook', label: cookMode ? 'scroll' : 'tutorial'},
                    { id: 'share', label: 'clipboard'},
                    { id: 'print', label: 'print'},
                    { id: 'download', label: 'download'}
                ]}
                title={`Recipe Options`}
                onSelect={handleOnOptionSelect} 
                showDialog={showOptions}
                onCancel={ () => { setShowOptions(false) } } 
            />
        </div>
    )
}