/** vendor */
import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'

const OptionDialog = ({ 
  showDialog, 
  title, 
  options, 
  processing, 
  onSelect = () => {} , 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)

  /**
   * Hooks
   */
  useEffect(() => { setIsOpen(showDialog) }, [showDialog])

  /**
   * Handlers
   */
  const handleOptionSelect = (option) => {
    onSelect(option)
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
                                            
      <div className="fixed bottom-3 w-[95vw] z-50">
        <button 
            className="rounded-full z-50 w-8 h-8 mb-4 bg-white text-black block ml-auto"
            onClick={handleCancel }
        >
          <XIcon className="w-4 h-4 block mx-auto" />
        </button>

        <div className="bg-white p-8 rounded">
          <h2 className="text-xl font-semibold text-center mb-4 border-b border-black pb-2">
            {title}
          </h2>
  
          <ul className="space-y-4">
            {options.map((option) => (
              <li key={option.id}>
                <button 
                  className={
                    `border 
                    ${option.label === 'delete' || option.label === 'remove'
                      ? 'border-red-600 text-red-600' : 'border-black'
                    } 
                    rounded block w-full uppercase py-2`
                  }               
                  onClick={() => handleOptionSelect(option?.id)}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>

        </div>
      </div>    
    </div>
  )
}

export default OptionDialog