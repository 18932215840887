import React, { useState, useEffect, useRef } from 'react'
import { ClockIcon as ClockiconSolid } from '@heroicons/react/solid'
import { ClockIcon } from '@heroicons/react/outline'

const Timer = ({ timerObj, restartTimer, pauseTimer }) => {
    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const intervalRef = useRef(null)


    const caluclateSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number)
        return hours * 3600 + minutes * 60 + seconds
    }

    const formatTime = (timeString) => {
        const minutes = Math.floor(timeString / 60);
        const seconds = timeString % 60;
    
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
    
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        setIsRunning(false)
    
        if (restartTimer) {
            setTime(caluclateSeconds(timerObj?.start_time))
        }

        if(timerObj) {
            setTime(caluclateSeconds(timerObj?.time_remaining))
        }
    }, [timerObj, restartTimer])

    const handleOnStartTimer = () => {
        setIsRunning(true)
        
        intervalRef.current = setInterval(() => {
            setTime((prevTime) => prevTime - 1)
        }, 1000)
    }

    const handleOnPauseTimer = () => {
        setIsRunning(false)
        clearInterval(intervalRef.current)
    }

    const handleOnRestartTimer = () => {
        setTime(0)
        clearInterval(intervalRef.current)
        
        if (isRunning) {
            handleOnStartTimer()
        }
    }

    useEffect(() => {
        return () => clearInterval(intervalRef.current)
    }, [])

    return (
        <div>
            {
                timerObj?.start_time ? 
                    <div className="mx-auto w-6" onClick={isRunning ? handleOnPauseTimer : handleOnStartTimer}>
                        { 
                            isRunning 
                                ? <ClockIcon className="w-6 h-6" /> 
                                : <ClockiconSolid className="w-6 h-6 opacity-30" /> 
                        }
                        <small>{time ? formatTime(time) : '00:00:00'}</small>
                    </div> : <div> - </div>
            }
            {/* <button onClick={handleOnRestartTimer}>Restart</button> */}
        </div>
    )
}

export default Timer
