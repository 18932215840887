import React from 'react'


export default function Terms() {
    return (
     <div>
        <small className="font-xs opacity-60">Last Updated: Sun 7 April 2024</small>
        <h1 className="font-bold text-xl pt-2 pb-4">1. Introduction</h1>

        <p className="pb-4">
            Please read these Terms of Use (these "Terms") carefully as they govern your use of (which includes access to) 
            Tait Digitals online services for Kitchen Management and other content, including all of our websites and software 
            applications that incorporate or link to these Terms (collectively, the "Aisle Nine Service") and any digital content 
            that is made available through the Aisle Nine Service (the "Service").
        </p>

        <p className="pb-4">
            Use of the Aisle Nine Service may be subject to additional terms and conditions presented by Tait Digital, which are hereby incorporated by this reference into these Terms.
            By signing up for, or otherwise using, the Aisle Nine Service, you agree to these Terms. If you do not agree to these Terms, then you must not use the Aisle Nine Service or access any Content.
        </p>

        <h2 className="py-4 font-semibold text-lg">Service Provider</h2>

        <p className="pb-4">These Terms are between you and Tait Digital Limited / Aisle Nine</p>

        <h2 className="py-4 font-semibold text-lg">Age and eligibility requirements</h2>
        
        <p className="pb-4">In order to use the Aisle Nine Service and access any Content, you need to </p>
        
        <ul className="pb-4">
            <li className="py-1">(1) be 13 years of age (or the equivalent minimum age in your home country) or older,</li> 
            <li className="py-1">(2) have parent or guardian consent if you are a minor in your home country,</li> 
            <li className="py-1">(3) have the power to enter a binding contract with us and not be barred from doing so under any applicable laws, and</li> 
            <li className="py-1">(4) reside in a country where the Service is available.</li> 
        </ul>

        <p className="pb-4">
            You also agree that any registration information that you submit to Aisle Nine is true, accurate, and complete, and you agree to keep it that way at all times If you are a minor in your home country, 
            your parent or guardian will need to enter into these Terms on your behalf. 
        </p>

        <p className="pb-4">If you do not meet the minimum age requirements then Aisle Nine will be unable to register you as a user.</p>

        <h1 className="font-bold text-xl pt-2 pb-4">2. The Aisle Nine Service Provided by Us</h1>

        <h2 className="py-4 font-semibold text-lg">Service Options</h2>

        <p className="pb-4">
            We provide numerous Service options. Certain Service options are provided free-of-charge, while other options require payment before they can be accessed (the "Paid Subscriptions"). 
            We may also offer special promotional plans, memberships, or services, including offerings of third-party products and services. 
        </p>

        <p className="pb-4">We are not responsible for the products and services provided by such third parties.</p>


        <h2 className="py-4 font-semibold text-lg">Service Limitations and Modifications</h2>

        <p className="pb-4">
            We use reasonable care and skill to keep the Service operational and to provide you with a quality kitchen management experience. 
            However, our service offerings and their availability may change from time to time and subject to applicable laws, without liability to you; for example:
        </p>

        <p className="pb-4">The Service may experience temporary interruptions due to technical difficulties, maintenance or testing, or updates, including those required to reflect changes in relevant laws and regulatory requirements.</p>
        <p className="pb-4">We aim to evolve and improve our Services constantly, and we may modify, suspend, or stop (permanently or temporarily) providing all or part of the Service (including particular functions, features, subscription plans and promotional offerings).</p>
        <p className="pb-4">Tait Digital has no obligation to provide any specific content through the Service, and Aisle Nine Staff or the applicable owners may remove their Content without notice.</p>
        <p className="pb-4">Tait Digital has no liability to you, nor any obligation to provide a refund to you, in connection with internet or other service outages or failures that are caused by the actions of government authorities, other third parties or events beyond our control.</p>


        <h1 className="font-bold text-xl pt-2 pb-4">3. Your Use of the Aisle Nine Service</h1>

        <h2 className="py-4 font-semibold text-lg">Creating an account</h2>

        <p className="pb-4">
            You may need to create an account to use all or part of the Service. Your username and password are for your personal use only and should be kept confidential. 
            You understand that you are responsible for all use (including any unauthorized use) of your username and password. 
        </p>

        <p className="pb-4">
            Notify our Customer Service team immediately if your username or password is lost or stolen, or if you believe there has been unauthorized access to your account.
            Aisle Nine may reclaim, or require you to change, your username for any reason.
        </p>

        <h2 className="py-4 font-semibold text-lg">Your rights to use the Service</h2>

        <p className="pb-4">
            Subject to your compliance with these Terms (including any other applicable terms and conditions), we grant to you limited, non-exclusive, revocable permission to make personal, non-commercial use of the Service and the Content (collectively, "Access").
        </p>

        <p className="pb-4">
            This Access shall remain in effect unless and until terminated by you or Tait Digital. You agree that you will not redistribute or transfer the Service or the Content.
            All Tait Digital software applications and the Content are licensed, not sold or transferred to you, and Tait Digital and its licensors retain ownership of all copies of the Aisle Nine software applications and Content even after installation on your Devices.
            Proprietary Rights
        </p>

        <p className="pb-4">
            The Service and the Content are the property of Tait Digital or Aisle Nine's licensors. All trademarks, service marks, trade names, logos, domain names, and any other features of the brand are the sole property of Tait Digital or its licensors. 
        </p>

        <p className="pb-4">      
            These Terms do not grant you any rights to use any Brand Features whether for commercial or non-commercial use.
        </p>

        <h2 className="py-4 font-semibold text-lg">Brand Accounts</h2>
        
        <p className="pb-4">
            If you establish an account on behalf of a company, organisation, entity, or brand (a "Brand," and such account a "Brand Account"), the terms "you" and "your," as used throughout these Terms (including other terms and conditions incorporated by reference herein), apply to both you and the Brand.
        </p>

        <p className="pb-4">
            If you create a Brand Account, you represent and warrant that you are authorised to grant all permissions and licences provided in these Terms (including any other applicable terms and conditions) and to bind the Brand to these Terms.
        </p>

        <p className="pb-4">        
            A Brand may follow users and create and share meal plans, recipes and Brand Ingredients, provided that the Brand does not take any action that implies an endorsement or commercial relationship between the Brand and the followed user, or any other person, unless the Brand has independently obtained the rights to imply such an endorsement. 
        </p>

        <p className="pb-4">
            In addition, Brands must be transparent to our users about disclosing any endorsements or consideration provided to chefs, curators, content creators, or any other party and must comply with all applicable laws, regulations, and codes of practice when engaging in the foregoing practices.
        </p>

        <h1 className="font-bold text-xl pt-2 pb-4">4. Content and Intellectual Property Rights</h1>

        <h2 className="py-4 font-semibold text-lg">User content</h2>

        <p className="pb-4">
            The content you post on the service
            <br/><br/>
            Aisle Nine users may post, upload, or otherwise contribute content to the Service ("User Content"). For the avoidance of doubt, "User Content" includes all information, materials and other content that is added, created, uploaded, submitted, distributed, or posted to the Service (including to the Support Community) by users.
            <br/><br/>
            You are solely responsible for all User Content that you post<br/>
            You agree that, with respect to any User Content you post on Aisle Nine,
            <br/><br/>
            (1) you own or have the right to post such User Content; 
            <br/><br/>
            (2) such User Content, or its use by Aisle Nine pursuant to the licence granted below, does not:
            <br/><br/>
            (i) violate these Terms, applicable law, or the intellectual property or other rights of any third party; or
            <br/><br/>
            (ii) such User Content does not imply any affiliation with or endorsement of you or your User Content by Aisle Nine, Tait Digital, brand, or other individual or entity without the prior express written consent from Tait Digital or such individual or entity.
            <br/><br/>
            In posting or sharing User Content or other information on the Service, please keep in mind that content and other information will be publicly accessible, and may be used and re-shared by others on the Service and across the web, so please use caution in posting or sharing on the Service, and be mindful of your account settings. 
            <br/>
            Tait Digital is not responsible for what you or others post or share on the Service.
        </p>

        <h2 className="py-4 font-semibold text-lg">Monitoring user content</h2>

        <p className="pb-4">Tait Digital may, but has no obligation to, monitor or review User Content.</p> 
        <p className="pb-4">Tait Digital reserves the right to remove or disable access to any User Content for any or no reason.</p> 
        <p className="pb-4">Tait Digital may take these actions without prior notification to you.</p>

        <h2 className="py-4 font-semibold text-lg">Licences that you grant us</h2>

        <p className="pb-4">
            <strong>User Content</strong><br/><br/>
            You retain ownership of your User Content when you post it to the Service. However, in order for us to make your User Content available on the Service, we do need a limited licence from you to that User Content. 
            <br/><br/>
            Accordingly, you hereby grant to Tait Digital a non-exclusive, transferable, sub-licensable, royalty-free, fully paid, irrevocable, worldwide licence to reproduce, make available, perform and display, translate, modify, create derivative works from, distribute, and otherwise use any such User Content through any medium, whether alone or in combination with other Content or materials, in any manner and by any means, method or technology, whether now known or hereafter created, in connection with the Service. 
            <br/><br/>
            Where applicable and to the extent permitted under applicable law, you also agree to waive, and not to enforce, any "moral rights" or equivalent rights, such as your right to be identified as the author of any User Content, including Feedback, and your right to object to derogatory treatment of such User Content.
            <br/><br/>
            <strong>Feedback</strong><br/><br/>
            If you provide ideas, suggestions, or other feedback in connection with your use of the Service or any Content ("Feedback"), such Feedback is not confidential and may be used by Tait Digital without restriction and without payment to you. Feedback is considered a type of User Content under these Terms.
            <br/><br/>
            <strong>Your Device.</strong><br/><br/>

            You also grant to us the right 
            <br/><br/>
            (1) to allow the Service to use the processor, bandwidth, and storage hardware on your Device in order to facilitate the operation of the Service, 
            <br/><br/>
            (2) to provide advertising and other information to you, and to allow our business partners to do the same, as permitted in accordance with the Privacy Policy.
            <br/><br/>

            <strong>Content experience</strong><br/><br/>

            In any part of the Service, the Content that you access, including its selection and placement, may be influenced by commercial considerations, including Tait Digital’s agreements with third parties.
            <br/><br/>
            Some Content licensed by, provided to, created by, or otherwise made available by Aisle Nine may incorporate advertising, and Tait Digital is not responsible for any such advertising.
            Infringement claims
            <br/><br/>
            Tait Digital respects the rights of intellectual property owners. If you believe that any Content infringes your copyright rights, please see the Aisle Nine Copyright Policy.
            <br/><br/>

            <strong>Warranty disclaimers</strong><br/><br/>

            Tait Digital will provide the Aisle Nine Service using reasonable care and skill and in accordance with any specification of the Service provided by Tait Digital, however, subject to that, the Service is provided "as is" and "as available," without any warranties of any kind, whether express, implied, or statutory. Further, Tait Digital and all owners of the content disclaim any express, implied, and statutory warranties regarding the content, including warranties of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement. 
            <br/><br/>
            Neither Tait Digital nor any owner of content warrants that the Service or content is free of malware or other harmful components. In addition, Tait Digital makes no representation regarding, nor does it warrant or assume any responsibility for, any third-party applications (or the content thereof), user content, devices or any product or service advertised, promoted or offered by a third party on or through the Service or any hyperlinked website, and Tait Digital is not responsible for any transactions between you and any third-party providers of the foregoing. 
            No advice or information whether oral or in writing obtained by you from Tait Digital shall create any warranty on behalf of Tait Digital. 
            <br/><br/>
            While using the Service, you may have access to explicit content filtering features, but use of these features may still result in some explicit content being served and you should not rely on such features to filter all explicit content. This section applies to the fullest extent permitted by applicable law.  
        </p>



        <h1 className="font-bold text-xl pt-2 pb-4">5. About these Terms</h1>

        <p className="pb-4">
            Under applicable law, you may have certain rights that can't be limited by a contract. These terms are in no way intended to restrict those rights.
        </p>

        <p className="pb-4">
            <strong>Changes</strong><br/><br/>

            We may make changes to these Terms (including any additional Tait Digital terms and conditions incorporated by reference herein) from time to time by notifying you of such changes by any reasonable means (before they take effect), including by posting a revised Agreement on the applicable Service (provided that, for material changes, we will seek to supplement such notice by email, an in-service pop-up message, or other means). Any such changes will not apply to any dispute between you and us arising prior to the date on which we posted the revised Terms, or other Tait Digital terms and conditions, incorporating such changes, or otherwise notified you of such changes. 
            <br/><br/>
            Your use of the Service following any changes to these Terms will constitute your acceptance of such changes. 
            <br/><br/>
            If you do not wish to continue using the Service under the updated Terms, you may terminate your account by contacting us. 
            <br/><br/>
            The effective date set forth at the top of this document indicates when these Terms were last changed.
        </p>
     </div>   
    )
}