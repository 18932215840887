const sidebarItems = [
    {
        label: 'Search Recipes',
        route: '/recipes',
        icon: 'LightBulbIcon'
    },
    {
        label: 'My Recipes',
        route: '/user-recipes',
        icon: 'FolderIcon'
    },
    {
        label: 'Dashboard',
        route: '/user-dashboard',
        icon: 'HomeModernIcon'
    },
    
    {
        label: 'Meal Plans',
        route: '/user-meal-plans',
        icon: 'CalendarDaysIcon'
    },
    {
        label: 'Shopping Lists',
        route: '/user-shopping-lists',
        icon: 'ClipboardListIcon'
    },
    {
        label: 'Recipe Creator',
        route: '/recipe-creator',
        icon: 'RetangleGroupIcon'
    },
    {
        label: 'Stores',
        route: '/user-stores',
        icon: 'InboxStackIcon'
    },
    {
        label: 'Notifications',
        route: '/user-notifications',
        icon: 'BellIcon'
    },
    {
        label: 'Account',
        route: '/user-preferences',
        icon: 'AdjustmentsIcon'
    },
]

export default sidebarItems