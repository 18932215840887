/** vendor */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';

/** lib */
import { Card } from '../../components/UI/Card'

/** components */
import LoginForm from '../../components/Forms/LoginForm'
import useOrientation from '../../hooks/useOrientations';
import Logo from '../../components/UI/Logo';

export default function Login() {
    const { type } = useOrientation()
    const [betaEmail, setBetaEmail] = useState()

    const handleSubmitBeta = async e => {
        // @todo
    }

    return(
        <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black">
            { type &&
                <div className="flex flex-col justify-around h-screen w-screen">
                    <div className={`flex flex-1 items-center justify-center w-full mx-auto ${type.includes('landscape') ? 'flex-row' : 'flex-col'}`}>
                        <Logo />
                        <div className="w-full">
                            <LoginForm />
                        </div>
                    </div>
                    {
                        !type?.includes('landscape') &&
                            <form onSubmit={handleSubmitBeta} className="border-t border-black p-12 w-full">
                                <style scoped>
                                    {`
                                        input {
                                            all: unset;
                                            background: #000 !important;
                                            color: #fff;
                                            border: 1px solid #fff;
                                            font-size: 12px;
                                            width: 100%; 
                                            box-sizing: border-box;
                                            -webkit-box-sizing:border-box;
                                            -moz-box-sizing: border-box;
                                        }
                                        
                                        input:-webkit-autofill,
                                        input:-webkit-autofill:hover,
                                        input:-webkit-autofill:focus,
                                        input:-webkit-autofill:active {
                                            transition: all 5000s ease-in-out 0s;
                                        }
                                    `}
                                </style>
                            </form>
                    }
            </div>
            }
        </div>
    )
}