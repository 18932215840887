export const treatment_options = [
    'whole',
    'crushed',
    'powdered',
    'muddled',
    'diced',
    'peeled',
    'sliced',
    'cooked',
    'melted',
    'boiled',
    'grated'
]

export const measurement_options = [
    {label: 'units', value: 'unit'},
    {label: 'kilo', value: 'kg'},
    {label: 'grams', value: 'g'},
    {label: 'mg', value: 'mg'},
    {label: 'litres', value: 'l'},
    {label: 'ml', value: 'ml'},
    {label: 'cups', value: 'cup'},
    {label: 'rashers', value: 'rasher'},
    {label: 'tsp', value: 'tsp'},
    {label: 'tbsp', value: 'tbsp'}
]