import React, { useState, useMemo } from 'react'
import { createPortal } from 'react-dom'

import { ConfirmContext } from './ConfirmContext'
import Confirm from './Confirm'

// Create a random ID
function generateUEID() {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  first = ('000' + first.toString(36)).slice(-3)
  second = ('000' + second.toString(36)).slice(-3)

  return first + second
}

export const ConfirmProvider = (props) => {
  const [confirms, setConfirms] = useState([])
  const open = (title, description, confirm, close) =>
    setConfirms((currentConfirms) => [
      ...currentConfirms,
      { id: generateUEID(), title, description, confirm, close },
    ])
  const close = (id) =>
    setConfirms((currentConfirms) =>
      currentConfirms.filter((c) => c.id !== id)
    )
  const contextValue = useMemo(() => ({ open }), [])

  return (
    <ConfirmContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div className="confirms-wrapper">
          {confirms.map((confirm) => (
            <Confirm 
              key={confirm.id} 
              close={() => close(confirm.id)}
              confirm={() => {
                confirm.confirm()
                close(confirm.id)
              }}
              title={confirm?.title}
              description={confirm?.description}
            />
          ))}
        </div>,
        document.body
      )}
    </ConfirmContext.Provider>
  )
}
