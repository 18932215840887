export default function Confirm({ 
    title = '',
    description = '',
    confirm = () => {}, 
    close
}) {
    
    return (
        <div className={`fixed z-50 top-0 left-0 overflow-hidden h-screen w-screen flex items-center justify-center`}>
            <div className="fixed inset-0 top-0 left-0 bg-black opacity-60 h-screen w-screen"></div>

            <div className="fixed top-40 w-[90vw] z-50">
                <div className="bg-white p-8 rounded-md shadow-md">
                    <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">
                        {title}
                    </h2>

                    <div className="flex min-w-full justify-center">
                        <div className="w-full text-center">
                            {description}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
                        onClick={close}
                    >
                        Cancel
                    </button>

                    <button
                        className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                        onClick={confirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>

            
        </div>
    )
}