/** vendor */
import React, {useState} from 'react'
import { measurement_options } from '../../constants/index'

export default function SelectUnitForm({
    userUnit = null,
    defaultUnit = null,
    canEdit = true, 
    onSelectUnit = () => {},
}) {
    const [selectedUnit, setSelectedUnit] = useState(userUnit ?? defaultUnit)

    const handleSelectUnit = async e => {
        e.preventDefault()
        setSelectedUnit(e.target.value)
        onSelectUnit(e.target.value)
    }

    return (
        <div className="w-full overflow-hidden">
            <select 
                id="measurement"
                disabled={!canEdit}
                onChange={(e) => { handleSelectUnit(e)} }
                value={selectedUnit || 'unit'}  
                style={{ fontSize: 12 }}
                className="border bg-black border-black w-full text-center text-white leading-none rounded-b block px-1 py-1"
            >
                {
                    measurement_options?.map((option, i) => {
                        return <option key={i} value={option.value}>{option.label}</option>
                    })
                }
            </select>
        </div>
    )
}

