export const excludeReceiptLineWords = [
    'eftpos',
    'visa',
    'total',
    'purchase',
    'efpos',
    'mastercard',
    'terminal',
    'tax',
    'invoice',
    '.co',
    'phone',
    'reference',
    'authori',
    'gst'
]