
export const dateDiff = (first, second) => {   
    const date1 = new Date(`20${first.replace(/-/g, '/')}`);
    const date2 = new Date(`20${second.replace(/-/g, '/')}`);
    const timeDifference = date2 - date1;
    
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
}


export const formatShortDate = (dateStr) => {
    const options = { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
    }

    let d = new Date(dateStr)
        d = d.toLocaleDateString("en-GB", options)
    
    return d
  }

export const formatQuantity = (value) => {
    const stringValue = value.toString()

    if (stringValue.includes('.')) {
      const [integerPart, decimalPart] = stringValue.split('.')
  
      if (/^0+$/.test(decimalPart)) {
        return parseInt(integerPart, 10)
      }
    }
  
    return value    
}

export const formatTimer = (value) => { 
    return value.split(':').map(i => i.length < 2 ? '0' + i : i).join(':')
} 