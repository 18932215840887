import React, { useState } from 'react'
import sidebarItems from '../../constants/SidebarItems'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Logout from '../Logout/Logout'

import { 
    MenuIcon, 
    XCircleIcon, 
    CakeIcon, 
    ClipboardListIcon, 
    BeakerIcon, 
    AdjustmentsIcon,
    ViewGridIcon, 
    BellIcon,
    FolderOpenIcon,
    FolderIcon,
} from '@heroicons/react/solid'

import { 
    HomeIcon,     
    CalendarIcon,
    LightBulbIcon
} from '@heroicons/react/outline'

export default function Sidebar({ onToggleWakeLock }) {
    
    const user = useSelector(state => state?.auth?.user?.user)
    const [showSidebar, setShowSidebar] = useState(false)
    const [wakeLock, setWakeLock] = useState(false)

    let items = []

    if (sidebarItems) { 
        items = [...sidebarItems] 
        items = sidebarItems.map((r) => {
            const Icon = (props) => {
                const { name } = props
              
                if (name === 'ViewGridIcon') return  <ViewGridIcon { ...props } />
                if (name === 'CakeIcon') return  <CakeIcon { ...props } />
                if (name === 'CalendarIcon') return  <CalendarIcon { ...props } />
                if (name === 'CalendarDaysIcon') return  <CalendarIcon { ...props } />                
                if (name === 'ClipboardListIcon') return  <ClipboardListIcon { ...props } />
                if (name === 'BeakerIcon') return  <BeakerIcon { ...props } />
                if (name === 'AdjustmentsIcon') return  <AdjustmentsIcon { ...props } />
                if (name === 'FolderOpenIcon') return  <FolderOpenIcon { ...props } />
                if (name === 'BellIcon') return  <BellIcon { ...props } />
                if (name === 'HomeModernIcon') return  <HomeIcon { ...props } />
                if (name === 'FolderIcon') return  <FolderIcon { ...props } />
                if (name === 'RetangleGroupIcon') return  <XCircleIcon   { ...props } />  
                if (name === 'LightBulbIcon') return  <LightBulbIcon { ...props } />
                if (name === 'InboxStackIcon') return  <XCircleIcon { ...props } />
                return <></>
            }

            return (
                <li key={r.route} className="py-4">
                    <Link to={r.route} onClick={() => setShowSidebar(!showSidebar)} className="flex items-center">
                        <Icon name={r.icon} className="h-8 w-8 pr-2 font-light opacity-80" />
                        <span>{r.label}</span>
                    </Link>
                </li>
            )
        })
    }

    const handleOnWakeLock = () => {
        setWakeLock(!wakeLock)
        onToggleWakeLock(!wakeLock)
    }

    return (
        <div className="h-screen">
            {!showSidebar ? (
                <button
                    className="flex text-4xl text-white items-center cursor-pointer fixed left-2 top-4 z-40"
                    onClick={() => setShowSidebar(!showSidebar)}
                    >
                    <MenuIcon className="h-8 w-8" />
                </button>
            ) : ''}


            <div className={`top-0 left-0 w-[80vw] bg-black ease-in-out duration-300 p-6 pt-2 text-white fixed h-full overflow-y-auto z-50 ${
                showSidebar ? "translate-x-0 " : "translate-x-[-80vw]"
            }`}>
                <div className="flex justify-between w-full pb-2">
                    <div className="relative mt-3">
                        <div className="w-full flex justify-end">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input 
                                type="checkbox" 
                                value="wakeLock"
                                onChange={() => {
                                    handleOnWakeLock()
                                }}
                                checked={wakeLock} 
                                className="sr-only peer" 
                                />
                                <div 
                                
                                className={`
                                    w-9 h-5 bg-red-800
                                    rounded-full peer
                                    peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                    after:absolute after:top-[2px] after:left-[2px] 
                                    after:bg-white
                                        after:rounded-full after:h-4 after:w-4 after:transition-all 
                                    peer-checked:bg-green-800

                                `}></div>
                                <span className="ml-3 text-xs font-medium text-white">Wake Lock</span>
                            </label>
                        </div>
                    </div>

                    <button
                        className="flex text-4xl text-white items-center cursor-pointer z-50"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        <XCircleIcon className="h-10 w-10" />
                    </button>
                </div>

                <ul>
                    {
                        user ? 
                            <div className="flex my-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <div className="pl-2 w-3/5">
                                    <p className="text-white text-base truncate pt-3 font-semibold">{ user?.name }</p>
                                </div>     
                            </div>
                        : <></>
                    }

                    {items}

                    <li className="border-b-gray-500 py-4">
                        <Logout />
                    </li>
                </ul>

                <div className="flex items-center mt-8 z-50 ">
                    <Link to="/about" onClick={() => setShowSidebar(!showSidebar)}>
                        <span className="text-xs opacity-60 text-white cursor-pointer underline">About</span>
                    </Link>
                    <span className="text-xs opacity-60 text-white px-2 mt-1"> | </span>
                    <Link to="/legal" onClick={() => setShowSidebar(!showSidebar)}>
                        <span className="text-xs opacity-60 text-white cursor-pointer underline">Legal</span>
                    </Link>
                    <span className="text-xs opacity-60 text-white px-2 mt-1"> | </span>

                    <Link to="/contact" onClick={() => setShowSidebar(!showSidebar)}>
                        <span className="text-xs opacity-60 text-white cursor-pointer underline">Contact</span>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}