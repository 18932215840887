import { API_URL, requestHeader } from '../constants/ApiConfiguration'

export function fetchRecipes(pageToRequest = 1) {
    return fetch(API_URL + '/recipes?page=' + pageToRequest, {
        method: 'GET', 
        headers: requestHeader()
    }).then(response => response.json()) 
}

export function searchRecipes(pageToRequest = 1, searchString = '') {
    return fetch(API_URL + '/recipe-search?page=' + pageToRequest + '&search=' + searchString, {
        method: 'GET', 
        headers: requestHeader()
    }).then(response => response.json()) 
}

export function filterRecipes(pageToRequest = 1, searchString = '', filters) {
    return fetch(API_URL + '/recipe-filter', {
        method: 'POST', 
        headers: requestHeader(),
        body: JSON.stringify({pageToRequest, searchString, filters})
    }).then(response => response.json()) 
}

export function fetchRecipe(id) {
    return fetch(API_URL + '/recipes/' + id, {
        method: 'GET', 
        headers: requestHeader()
    }).then(response => response.json()) 
}