import * as RecipeService from '../services/recipe.service'

export const getRecipes = (pageToRequest = 1) => (dispatch) => {
    return RecipeService.fetchRecipes(pageToRequest).then((data) => {
        dispatch({
          type: 'FETCH_RECIPES_SUCCESS',
          payload: { recipes: data },
        });
        return Promise.resolve()
      },
      (error) => {
        dispatch({
          type: 'FETCH_RECIPES_FAIL',
          message: error
        });
      
        return Promise.reject()
      })
}

export const searchRecipes = (pageToRequest, searchRecipes) => (dispatch) => {
  return RecipeService.searchRecipes(pageToRequest, searchRecipes).then((data) => {
      dispatch({
        type: 'FETCH_RECIPES_SUCCESS',
        payload: { recipes: data },
      });
      return Promise.resolve()
    },
    (error) => {
      dispatch({
        type: 'FETCH_RECIPES_FAIL',
        message: error
      });
    
      return Promise.reject()
    })
}

export const filterRecipes = (pageToRequest, searchRecipes, filters) => (dispatch) => {
  return RecipeService.filterRecipes(pageToRequest, searchRecipes, filters).then((data) => {
      dispatch({
        type: 'FETCH_RECIPES_SUCCESS',
        payload: { recipes: data },
      });
      return Promise.resolve()
    },
    (error) => {
      dispatch({
        type: 'FETCH_RECIPES_FAIL',
        message: error
      });
    
      return Promise.reject()
    })
}

export const getRecipe = (id) => (dispatch) => {
    return RecipeService.fetchRecipe(id).then((data) => {
        dispatch({
          type: 'FETCH_RECIPE_SUCCESS',
          payload: { current_recipe: data },
        });
        return Promise.resolve()
      },
      (error) => {
        dispatch({
          type: 'FETCH_RECIPE_FAIL',
          message: error
        })
      
        return Promise.reject()
      })
}