/** vendor */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import { PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

export default function ImportedRecipeListItem({
    importedRecipe,
    onHandleRemove
}) {
    const navigate = useNavigate()
    const [recipeJson, setRecipeJson] = useState(null)

    const handleClick = (recipe_id) => {
        navigate(`/imported-recipes/${recipe_id}`)
    }

    /**
     * Hooks
     */
    useEffect(() => {
        try {
            setRecipeJson(JSON.parse(importedRecipe.data))
        } catch (error) {
            console.warn(error)
        }
    }, [importedRecipe])

    const recipeImage = PLACEHOLDER_IMG

    const handleRemoveImportedRecipe = (importedRecipe) => {
        onHandleRemove(importedRecipe)
    }

    return (
        <>
        {
            importedRecipe && recipeJson ? 
                <div className="w-full py-1 mb-4 hover:bg-black hover:text-white">
                    <div className="flex w-full justify-between items-center pb-2">
                        <small className="text-left text-gray-400 block">Imported Recipes: {!recipeJson?.import_failed ? recipeJson?.recipeCuisine : ''}</small>
                        <button
                            className="text-xs w-5 ml-1 text-black"
                            onClick={() => { handleRemoveImportedRecipe(importedRecipe) }}
                        >
                            <XCircleIcon className="w-5 h-5 text-gray-300" />
                        </button>
                    </div>
                    
                    <div className="flex-1 border-gray-200 flex cursor-pointer" 
                        onClick={() => { handleClick(importedRecipe?.import_recipe_id) }}
                    >    
                        {
                            importedRecipe?.import_failed ? 
                                <div>
                                    <div className="font-medium underline">
                                        <a 
                                            className="break-all"
                                            href={importedRecipe.url} 
                                            target="_blank"
                                        >{ importedRecipe.url }</a>
                                    </div>
                                </div> : 
                                <div>                                                
                                    <div className="font-medium">{ recipeJson.name }</div>
                                    <div className="text-left flex-wrap text-sm flex-shrink-1 py-1">{recipeJson.description}</div>
                                    <small className="text-left text-xs pb-2 block">{recipeJson.recipeYield}</small>
                                </div>
                        }
                    </div>
                </div> : ''
        }
        </>
    )
}