/** vendor */
import React, { useEffect, useState } from 'react'

import { dateDiff } from '../../utils'

const CreateMealplanDialog = ({ 
  showDialog = false, 
  onSubmit = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    start_date: '',
    end_date: '',
    number_of_days: 0
  })

  const validateName = () => {
    const validNamePattern = /^[a-zA-Z0-9\s]+$/;
    return formData.name && validNamePattern.test(formData.name);
  }

  const validateForm = () => {
    if(!validateName()) return false
    
    if(formData.start_date) {


    }

    return true
  }

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleNameChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    setFormData(formCopy)
  }

  const handleDateChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    if(formCopy.start_date && formCopy.end_date) {
      formCopy.number_of_days = dateDiff(formCopy.start_date, formCopy.end_date) + 1
    }

    setFormData(formCopy)
  }

  const handleDaysChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    setFormData(formCopy)
  }

  const handleCancel = () => {
    setFormData({
      name: '',
      start_date: '',
      end_date: '',
      number_of_days: 0
    })

    onCancel()
  }

  const handleCreate = () => {
    onSubmit(formData)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
      <div className="bg-white p-8 fixed bottom-16 rounded-md shadow-md w-[90vw] z-50">
        <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Create a Mealplan</h2>

        <div className="flex min-w-full justify-center"> 
            <div className="w-full">
                <div className="mb-2 w-full">
                    <label 
                    htmlFor="name" 
                    className="block mb-2 text-sm font-medium text-black">Name</label>
                    <div className="relative">
                        <input 
                            type="text" 
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleNameChange} 
                            className={
                              `${formData.name.length > 0 && !validateName() ? 'border-red-500' : ''} border rounded w-full text-xs py-2 px-3 text-black`
                            }                            
                            placeholder="Plan Name"
                        />
                    </div>
                    <label className="block text-xs font-light text-red-500 mt-1">
                        { 
                            formData.name.length > 0 && !validateName()
                                ? 'No special characters allowed' 
                                : <>&nbsp;</>
                        }
                    </label>
                </div>

                <div className="">
                    <label 
                        htmlFor="days" 
                        className="block mb-2 text-sm font-medium text-black"
                    >Start Date</label>
                    <input
                        className={
                          `border rounded w-full text-xs py-2 px-3 text-black`
                        }                         
                        onChange={(e) => { handleDateChange(e)}}
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        value={formData.start_date} 
                        max={formData.end_date} 
                    />
                </div>

                <div className="mt-2">
                    <label 
                        htmlFor="days" 
                        className="block mb-2 text-sm font-medium text-black"
                    >End Date</label>
                    <input
                        className={
                          `${formData?.number_of_days < 1 && (formData.start_date.length > 0 && !formData.end_date) ? 'border-red-500' : ''} border rounded w-full text-xs py-2 px-3 text-black`
                        }        
                        onChange={(e) => { handleDateChange(e)}}
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        value={formData.end_date} 
                        min={formData.start_date} 
                    />
                    <label className="block text-xs font-light text-red-500 mt-1">
                        { 
                            formData?.number_of_days < 1 && (formData.start_date.length > 0 && !formData.end_date)
                                ? 'An end date is required' 
                                : <>&nbsp;</>
                        }
                    </label>
                </div>

                <div className="my-2 py-2">
                    <hr />
                    <div className="bg-white text-gray-400 text-xs text-center mt-[-8px] w-[20px] mx-auto"> OR </div>
                </div>

                <div className="">
                    <label htmlFor="days" className="block mb-2 text-sm font-medium text-black text-center">Number of days</label>
                    <input 
                      type="number"
                      id="number_of_days"
                      name="number_of_days" 
                      className="br-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-12 text-center mx-auto" 
                      placeholder=""
                      value={formData?.number_of_days}
                      disabled={(formData.end_date || formData.start_date)}
                      onChange={handleDaysChange} 
                    />
                  </div>
            </div>

        </div>
      </div>

      <div className="flex justify-between mt-6 w-full fixed bottom-3 px-6">
          <button
            className="px-4 py-1 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            disabled={!validateForm()}
            className={`${!validateForm() ? 'opacity-60' : ''} px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1`}
            onClick={handleCreate}
          >Create</button>
      </div>

    </div>
  )
}

export default CreateMealplanDialog
