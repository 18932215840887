/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { XIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'

/** lib */
import Loading from '../../components/UI/Loading'
import { useToast } from '../../hooks/useToast'
import { computeQuantity } from '../Utility/ComputeQuantityUnits'

/** state */
import { getUserStores, updateUserStore, getUserMealplan, } from '../../actions/user.actions'
import { updateShoppinglist } from '../../services/user.service'

const MealplanIngredientSummaryDialog = ({ 
  mealplanId, 
  showDialog, 
  title,
  onClose = () => {} 
}) => {
  // vendor utils
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // a9 utils
  const toast = useToast()

  // ui states
  const [isOpen, setIsOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)

  // user data
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)

  const mealplan = useSelector(state => state.current_mealplan)
  const foodstores = useSelector(state => state.user_data?.user_stores)
  const shoppingLists = useSelector(state => state.user_data?.shoppinglists)
  const sharedWithMeShoppinglists = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserShoppinglist'))
  
  // form data
  const [shoppingListOptions, setShoppingListOptions] = useState([])
  const [selectedShoppingListId, setSelectedShoppingListId] = useState('')
  const [checkedIngredients, setCheckedIngredients] = useState([])
  const [ingredientsInStores, setIngredientsInStores] = useState([])
  const [ingredientsNotInStores, setIngredientsNotInStores] = useState([])
  const ingredientOptions = useSelector(state => state.form_options?.ingredient_options || [])

  const mergeItems = (items) => {
    const mergedItems = []
    const valueMap = {}
  
    items.forEach(item => {
      const { image, name, quantity, value, measurement } = item
  
      if (valueMap[value]) {
        const computedValue = computeQuantity(
          quantity, 
          measurement, 
          valueMap[value].quantity, 
          valueMap[value].measurement
        )

        valueMap[value].quantity = computedValue?.quantity
        valueMap[value].measurement = computedValue?.measurement
      } else {
        valueMap[value] = { 
            image, 
            name, 
            quantity: quantity, 
            value: value, 
            measurement: measurement 
          }
      }
    })
  
    for (const key in valueMap) {
      mergedItems.push(valueMap[key])
    }

    // @todo if the final quantity is less than the default_quantity

    // for(const item of mergedItems) {
        // check if the final quantity and unit need updating

        // check if the final quantity and unit less than the default quantity and unit

    //   const defaultQuantity = ingredientOptions?.find(i => item?.value === i?.value)?.default_quantity
    // }

    return mergedItems
  }

  const updateSelectedShoppingList = () => {
    setSaving(true)

    const shoppingListToUpdate = [
      ...shoppingLists, 
      ...sharedWithMeShoppinglists
    ].find(list => list?.user_shoppinglist_id === parseInt(selectedShoppingListId))

    const ingredientsToAdd = [...checkedIngredients]
      .map(i => {
        const ingredientOption = ingredientOptions
          .find(ci => ci?.value === i.value)
        
        return {
          image: ingredientOption?.image, 
          name: ingredientOption?.name,
          quantity: i?.quantity,
          value: i?.value,
          measurement: i?.measurement
        }
      })

    const mergedItems = mergeItems([...shoppingListToUpdate?.ingredients, ...ingredientsToAdd])

    // console.warn('mergedItems', mergedItems)
    // return

    if(shoppingListToUpdate?.user_shoppinglist_id) {
      updateShoppinglist(shoppingListToUpdate?.user_shoppinglist_id, {
        name: shoppingListToUpdate?.name,
        ingredients: mergedItems
      })
      .then((r) => {
        setSaving(false)
        navigate(`/user-shopping-lists/${shoppingListToUpdate?.user_shoppinglist_id}`)
      }).catch(() => {
        setSaving(false)
      })
    }
  }

  useEffect(() => { 
    setIsOpen(showDialog) 
    
    if (showDialog) {
      setLoading(true)

      dispatch(getUserStores()).then((r) => { 

        dispatch(getUserMealplan(mealplanId)).then((r) => {
          setLoading(false)
        }).catch((e) => {
            setLoading(false)
        })

      }).catch((e) => {
        setLoading(false)
      })

    }
  }, [showDialog])

  useEffect(() => {
    if(!loading && mealplan?.recipes?.length > 0 && foodstores) {
      const meaplanIngredients = []
      const ingredientsInStoresIds = []
      const ingredientsNotinStoresIds = []

      mealplan?.recipes.forEach((recipe, index) => {
        recipe?.recipe_ingredients.forEach((ingredient, i) => {
          meaplanIngredients.push(ingredient)
        })
      })

      meaplanIngredients.forEach(ingredient => {
        let inStock = false
        let itemToAdd = null

        for(const store of foodstores) {
          for(const item of store?.items) {
            if(item?.value === ingredient?.ingredient_id) { 
              inStock = true
              itemToAdd = item 
              break
            }
          } 

          if(inStock)
          {
            break
          }
        }
        
        if(inStock && itemToAdd) {
          ingredientsInStoresIds.push({
            value: ingredient?.ingredient_id, 
            quantity: ingredient?.quantity, 
            measurement: ingredient?.measurement
          })
        } else {
          ingredientsNotinStoresIds.push({
            value: ingredient?.ingredient_id, 
            quantity: ingredient?.quantity, 
            measurement: ingredient?.measurement
          })
        }
      })

      setCheckedIngredients(ingredientsNotinStoresIds)
      setIngredientsNotInStores(ingredientsNotinStoresIds)
      setIngredientsInStores(ingredientsInStoresIds)
    }
  }, [mealplan, loading, foodstores])
  

  useEffect(() => {
    if((shoppingLists && shoppingLists.length > 0 || sharedWithMeShoppinglists && sharedWithMeShoppinglists.length > 0) && currentUsersEmail) {
        const shoppingListsCopy = [...shoppingLists]
        const shoppingListOptions = shoppingListsCopy.map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
        let editableSharedWithMeLists = []

        if(sharedWithMeShoppinglists && sharedWithMeShoppinglists.length > 0 && currentUsersEmail) {
            editableSharedWithMeLists = [...sharedWithMeShoppinglists].filter((item) => {
                let canEdit = false

                for(const user of item?.shared_with) {
                    if(user?.email === currentUsersEmail && user?.canEdit) {
                        canEdit = true
                        break
                    }
                }

                return canEdit
            }).map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
        }

        const mergedOptions = [...shoppingListOptions, ...editableSharedWithMeLists]
        setShoppingListOptions(mergedOptions)
    }

    // @todo sharedWithMeShoppinglists including gives maxdepth error
  }, [shoppingLists, currentUsersEmail])
  
  const handleOnToggleCheckedIngredient = (e, ingredient) => {
    if (e.target.checked) {
      // @todo fix 'units()' being the measurement it should be 'unit' this is caused somewhere else in the app
      const newIngredients = [...checkedIngredients, {
        value: ingredient?.ingredient_id,
        quantity: ingredient?.quantity || 1,
        measurement: ingredient?.measurement || 1
      }]

      setCheckedIngredients(newIngredients)
    } else {
      const newIngredients = checkedIngredients.filter((item) => item?.value !== ingredient?.ingredient_id)
      setCheckedIngredients(newIngredients)    
    }
  }

  const handleSelectShoppingList = (e) => {    
    setSelectedShoppingListId(e.target.value)
  }

  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }

  const handleOnSendToList = () => {
    const recipe_ids = mealplan?.recipes.map(r => r.recipe_id)
    const recipesToAdd = []

    recipe_ids.forEach(id => {
      const recipeDetail = mealplan.recipes.find(r => r.recipe_id === id)
      recipesToAdd.push(recipeDetail)
    })

    let ingredientsToAdd = [] 
    
    recipesToAdd.forEach(recipe => {
      ingredientsToAdd = [...ingredientsToAdd, ...recipe.recipe_ingredients] 
    })

    let ingredientsToAddStr = ''
    ingredientsToAdd.forEach(i => ingredientsToAddStr += `${i.ingredient_id}|${i.quantity},`)

    updateSelectedShoppingList()
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
                                            
      <div className="fixed bottom-0 w-full px-2 z-50">
        <button 
            className="rounded-full z-50 w-8 h-8 mb-4 bg-white text-black block ml-auto"
            onClick={handleClose}
        >
          <XIcon className="w-4 h-4 block mx-auto" />
        </button>

        <div className="bg-white p-4 rounded h-[70vh]">
          { 
            loading && (<div className="h-[60vh] relative pt-40"><Loading /></div>) 
          }
          { 
            !loading && Object.keys(mealplan).length !== 0 &&
              <>
                <h2 className="text-xl font-semibold text-center mb-4 border-b border-black pb-2">
                  {title}
                </h2>
    
                <ul className="space-y-4" style={{ height: 'calc(70vh - 172px)', overflow: 'auto' }}>
                  {mealplan?.recipes.map((recipe, index) => (
                      <li key={index}>
                          <strong className="block pb-2">{recipe?.name}</strong>

                          {
                              recipe?.recipe_ingredients?.map((ingredient, i) => {
                                  const ingredientDetail = ingredient.ingredient                                
                                  const instoreCount = ingredientsInStores.find(i => i?.value === ingredientDetail.ingredient_id)
                                  const isChecked = checkedIngredients?.find(i => i?.value === ingredientDetail.ingredient_id) ?? false

                                  return (
                                      <div className="border-t border-black py-1 flex justify-between items-center" key={i}>
                                        <div className={`${!instoreCount && 'text-red-600'} pl-2`}>
                                          {ingredient?.quantity} {ingredient?.measurement} {ingredientDetail?.name}
                                        </div>

                                        <div className="flex py-2 items-center pr-2">
                                            <input 
                                                onChange={(e) => {handleOnToggleCheckedIngredient(e, ingredient, recipe )}}
                                                checked={isChecked} 
                                                id={`sendTolist_${i}`} 
                                                type="checkbox" 
                                                value={ingredientDetail.ingredient_id}
                                                className="w-4 h-4 text-black bg-white border-black rounded" 
                                            />
                                        </div>
                                      </div>
                                  )
                              })
                          }
                      </li>
                  ))}
                </ul>


                <div className="pt-1 mt-2 border-t border-black">
                    <label className="block py-2 text-xs w-full">Available Shopping Lists</label>
                    <select
                        disabled={false} 
                        id="shoppinglistMove"
                        value={selectedShoppingListId}
                        onChange={(e) => { handleSelectShoppingList(e)} }
                        className="border border-black text-black text-sm rounded block w-full p-2"
                    >
                        <option value="">Select ...</option>
                        {
                            shoppingListOptions.map((option, i) => {
                                return (
                                    <option 
                                      key={i} 
                                      value={option.value}
                                    >
                                      {option.name}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
              </>
          }
        </div>

        <button
          disabled={!selectedShoppingListId || saving}
          className={`${!selectedShoppingListId && 'opacity-20'} flex items-center bg-black text-sm text-white mx-auto my-3 rounded font-medium border border-black px-5 py-2`}
          onClick={(e) => { handleOnSendToList(e) }}
        >
          <span>Add Selected</span>
          {
              saving && (
                  <div className="animate-pulse ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                  </div>
              )
          } 
        </button>
      </div>    
    </div>
  )
}

export default MealplanIngredientSummaryDialog