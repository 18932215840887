/** vendor */
import React , { useState, useEffect } from 'react'

import { verifyEmail } from '../../services/auth.service'

/** lib */
import { Card } from '../../components/UI/Card'
import { Link } from 'react-router-dom'
import Loading from '../../components/UI/Loading'

/** components */

export default function Verify() {
  const [isValidating, setIsValidating] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const postVerifyEmail = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')

    verifyEmail({ token }).then((r) => {
      setErrorMessage('')
    }).catch((e) => {
      setErrorMessage(e?.error)
    }).finally(() => {
      setIsValidating(false)
    })    
  }

  const decodeJWT = (token) => {
    const [header, payload, signature] = token.split('.')
    const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')))
    const currentTime = Math.floor(Date.now() / 1000);
    
    if (decodedPayload.exp && currentTime >= decodedPayload.exp) {
      return null
    }
  
    return decodedPayload
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')

    if (token) {
      try {
        const decoded = decodeJWT(token)
        if(decoded) {
          postVerifyEmail()
        } else {
          setErrorMessage('This link has expired.')
        }
      } catch (error) {
        setErrorMessage('This link is invalid.')
      }
    }
  }, [])


  return (
    <div className="pt-16 w-full max-w-[600px]">
        <Card title="Email Verification" enableBack={false}>
            <div>

              { 
                isValidating && 
                  <div className="h-[60vh] relative pt-40">
                    <Loading />
                    <small className="block mx-auto my-2 text-center">Validating Email Address.</small>
                  </div>
              }

              { 
                (!isValidating && errorMessage?.length > 0) && 
                  <div className="pt-40">
                      <h1 className="text-center text-xl font-bold text-red-800">Email Verified Successfully</h1>
                      <p className="text-center font-light text-red-800">{errorMessage}</p>
                    </div> 
              } 

              { 
                (!isValidating && errorMessage?.length < 1) &&
                  <div className="pt-40">
                    <h1 className="text-center text-xl font-bold">Email Verified Successfully</h1>
                    <p className="text-center font-light">Welcome to Aisle Nine</p>
                  </div>
              }
            </div>
        </Card>

        <div className={`fixed bottom-0 left-0 w-screen py-4 flex justify-center ${(isValidating || errorMessage?.length > 0) && 'opacity-20'}`}>
          <Link to="/login">
            <button
              disabled={isValidating || errorMessage?.length > 0}
              type="button" 
              className="text-white bg-black rounded font-medium border border-black text-sm px-5 py-2"
            >
              Sign In
            </button>
          </Link>
        </div>
    </div>
  )
}