/** vendor */
import React, { useEffect, useState } from 'react'

/** state */
import { createShoppinglist } from '../../services/user.service'

const CreateShoppingListDialog = ({ 
  showDialog = false,
  onCreate = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [shoppinglistName, setShoppinglistName] = useState('')

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleChange = (e) => {
    setShoppinglistName(e.target.value)
  }

  const handleCancel = () => {
    setShoppinglistName('')
    setIsOpen(false)
    onCancel()
  }

  const validateName = () => {
    const validNamePattern = /^[a-zA-Z0-9\s]+$/;
    return shoppinglistName && validNamePattern.test(shoppinglistName);
  }

  const handleCreate = () => {
    setSaving(true)

    createShoppinglist({
        name: shoppinglistName,
        ingredients: []
    })
    .then((r) => {
        setSaving(false)
        onCreate(r?.user_shoppinglist_id)
    }).catch(() => {
        setSaving(false)
    })
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
      <div className="bg-white p-8 fixed bottom-16 rounded-md shadow-md w-[90vw] z-50">
        <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Create List</h2>

        <div className="flex min-w-full justify-center"> 
            <div className="w-full">
                <div className="mb-1 w-full">
                    <label htmlFor="shoppinglistName" className="block mb-2 text-sm font-medium text-black">Name</label>
                    <div className="relative">
                        <input 
                            disabled={saving}
                            type="text" 
                            id="shoppinglistName"
                            value={shoppinglistName}
                            onChange={handleChange} 
                            className={
                              `${shoppinglistName.length > 0 && !validateName() ? 'border-red-500' : ''} border rounded w-full text-xs py-2 px-3 text-black`
                            }                              
                            placeholder="List Name"
                        />
                    </div>
                    <label className="block text-xs font-light text-red-500 mt-1">
                        { 
                            shoppinglistName.length > 0 && !validateName()
                                ? 'No special characters allowed' 
                                : <>&nbsp;</>
                        }
                    </label>
                </div>
            </div>

        </div>
      </div>

      <div className="flex justify-between mt-6 w-full fixed bottom-3 px-6">
          <button
            disabled={saving}
            className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            disabled={saving || !validateName()}
            className={`${saving || !validateName() ? 'opacity-60' : ''} px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1`}
            onClick={handleCreate}
          >
            {
              saving ? 
                <div className="animate-pulse">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div> : 'Create'
            }
          </button>
      </div>

    </div>
  )
}

export default CreateShoppingListDialog
