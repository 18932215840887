import { measurement_options } from "../../constants"

/**
 * to add the quantities of two ingredients together and updates the unit of measurement
 * @param {*} quantity1 
 * @param {*} measurement1 
 * @param {*} quantity2 
 * @param {*} measurement2 
 * @returns 
 */
export const computeQuantity = (quantity1, measurement1, quantity2, measurement2) => {
    let quantity = 1 

    if(quantity1 && quantity2) {
        quantity = parseFloat(quantity1) + parseFloat(quantity2)
    }
    
    if(quantity1 && !quantity2)
    {
        quantity = quantity1
    }

    if(!quantity1 && quantity2)
    {
        quantity = quantity2
    }

    let measurement = 'unit'

    if(measurement1 === measurement2) {
        measurement = measurement1
    }
    else {
        // @todo for now just return unmatched measurements as a unit and make this smarter another time
    }
    
    return { quantity, measurement }
}

/**
 * figure out if the returned quantity and measurement should be the one from the recipe or the default quantity set in admin
 * return whichever value is higher
 * @param {*} quantity 
 * @param {*} measurement 
 * @param {*} default_quantity 
 * @param {*} default_measurement 
 * @returns 
 */
export const computeDefaultMeasurement = (quantity, measurement, default_quantity, default_measurement) => {
    if(measurement === default_measurement) {
        const qty = (parseFloat(quantity) > parseFloat(default_quantity)) ? quantity : default_quantity
        return {quantity: qty, measurement}
    }
    else 
    {
        if(['kg', 'g', 'mg'].includes(measurement)) {
            const convertedQuantity = convertMetricWeight(quantity, measurement, default_measurement)
            const qty = (parseFloat(convertedQuantity) > parseFloat(default_quantity)) ? convertedQuantity : default_quantity
            return { quantity: qty, default_measurement }
        }

        if(['l', 'ml'].includes(measurement)) {
            const convertedQuantity = convertMetricVolume(quantity, measurement, default_measurement)
            const qty = (parseFloat(convertedQuantity) > parseFloat(default_quantity)) ? convertedQuantity : default_quantity
            
            return { quantity: qty, default_measurement }
        }
    }
}

export const convertMetricWeight = (quantity, measurement, measurementToConvertBy) => {
    if (isNaN(quantity)) {
        throw new Error('Invalid quantity. Please provide a valid number.')
    }

    // Convert from grams (g) to kilograms (kg)
    if (measurement === 'g' && measurementToConvertBy === 'kg') {
        return quantity / 1000 // 1 kg = 1000 g
    }

    // Convert from milligrams (mg) to kilograms (kg)
    if (measurement === 'mg' && measurementToConvertBy === 'kg') {
        return quantity / 1000000 // 1 kg = 1,000,000 mg
    }

    // Convert from kilograms (kg) to grams (g)
    if (measurement === 'kg' && measurementToConvertBy === 'g') {
        return quantity * 1000 // 1 kg = 1000 g
    }

    // Convert from milligrams (mg) to grams (g)
    if (measurement === 'mg' && measurementToConvertBy === 'g') {
        return quantity / 1000 // 1 g = 1000 mg
    }

    // Convert from kilograms (kg) to milligrams (mg)
    if (measurement === 'kg' && measurementToConvertBy === 'mg') {
        return quantity * 1000000 // 1 kg = 1,000,000 mg
    }

    // Convert from grams (g) to milligrams (mg)
    if (measurement === 'g' && measurementToConvertBy === 'mg') {
        return quantity * 1000 // 1 g = 1000 mg
    }

    // If no conversion matches, return the original quantity
    return quantity
}

export const convertMetricVolume = (quantity, measurement, measurementToConvertBy) => {
    if (isNaN(quantity)) {
        throw new Error('Invalid quantity. Please provide a valid number.')
    }

    // Convert from milliliters (ml) to liters (l)
    if (measurement === 'ml' && measurementToConvertBy === 'l') {
        return quantity / 1000 // 1 liter = 1000 milliliters
    }

    // Convert from liters (l) to milliliters (ml)
    if (measurement === 'l' && measurementToConvertBy === 'ml') {
        return quantity * 1000 // 1 liter = 1000 milliliters
    }

    // If no conversion matches, return the original quantity
    return quantity
}

export const convertImperialWeightToMetricWeight = (quantity, measurement) => {
    if (isNaN(quantity)) {
        throw new Error('Invalid quantity. Please provide a valid number.')
    }

    const conversions = {
        lb: { label: 'lb', value: 'lb', factor: 453.592 }, // 1 lb ≈ 453.592 grams
        oz: { label: 'oz', value: 'oz', factor: 28.3495 }, // 1 oz ≈ 28.3495 grams
    };

    // Check if the provided measurement is supported for conversion
    if (conversions.hasOwnProperty(measurement)) {
        const { label, value, factor } = conversions[measurement]
        const convertedQuantity = quantity * factor

        return {
            quantity: convertedQuantity,
            measurement: 'g'
        }
    } else {
        throw new Error('Unsupported measurement unit. Please provide a valid imperial weight unit.')
    }
}

export const convertImperialVolumeToMetricVolume = (quantity, measurement) => {
    if (isNaN(quantity)) {
        throw new Error('Invalid quantity. Please provide a valid number.')
    }

    const conversions = {
        gal: { label: 'gal', value: 'gal', factor: 3785.41 }, // 1 gal ≈ 3785.41 milliliters
        qt: { label: 'qt', value: 'qt', factor: 946.353 }, // 1 qt ≈ 946.353 milliliters
        pt: { label: 'pt', value: 'pt', factor: 473.176 }, // 1 pt ≈ 473.176 milliliters
    }

    // Check if the provided measurement is supported for conversion
    if (conversions.hasOwnProperty(measurement)) {
        const { label, value, factor } = conversions[measurement];
        const convertedQuantity = quantity * factor;

        return {
            quantity: convertedQuantity,
            measurement: 'ml'
        };
    } else {
        throw new Error('Unsupported measurement unit. Please provide a valid imperial volume unit.')
    }

}

export const convertOtherMeasurement = (quantity, measurement) => {
    if (isNaN(quantity)) {
        throw new Error('Invalid quantity. Please provide a valid number.')
    }

    const conversions = {
        cup: { label: 'cup(s)', value: 'cup', factor: 236.588 }, // 1 cup ≈ 236.588 ml
        rasher: { label: 'rasher(s)', value: 'rasher', factor: 25 }, // Assuming 1 rasher ≈ 25 grams
        tsp: { label: 'tsp', value: 'tsp', factor: 5 }, // Assuming 1 tsp ≈ 5 grams
        tbsp: { label: 'tbsp', value: 'tbsp', factor: 15 }, // Assuming 1 tbsp ≈ 15 grams
        unit: { label: 'unit(s)', value: 'unit', factor: 1 } // Assuming 1 unit is equivalent to itself (no conversion)
    }

    // Check if the provided measurement is supported for conversion
    if (conversions.hasOwnProperty(measurement)) {
        const { label, value, factor } = conversions[measurement]
        const convertedQuantity = quantity * factor
        const convertedMeasurement = (['cup'].includes(value)) ? 'g' : 'ml'

        return {
            quantity: convertedQuantity,
            measurement: convertedMeasurement
        }
    } 

    return {
        quantity,
        measurement: 'unit'
    }  
}