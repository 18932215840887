/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import Loading from '../UI/Loading'

/** state */
import { getSavedRecipes } from '../../actions/user.actions'
import { removeSavedRecipe } from '../../services/user.service'

/** components */
import RecipeListItem from './RecipeListItem'

export default function SavedRecipeList() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const recipes = useSelector(state => state?.user_data?.saved_recipes)
  
    useEffect(() => {
        setLoading(true)

        dispatch(getSavedRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    const handleClick = (recipeId) => {
        navigate(`/recipes/${recipeId}`)
    }   

    const handleRemoveSavedRecipe = (savedRecipe) => {
        setLoading(true)

        removeSavedRecipe(savedRecipe.recipe_id).then(() => {
            dispatch(getSavedRecipes()).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        })
    }

    if(loading) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <div className="w-full h-[70vh]">
            <section className="antialiased text-gray-600 px-4">
                <div className="flex flex-col items-center justify-center pt-4">
                    <div className="p-3 w-full lg:w-2/3">
                        <div className="overflow-x-auto">
                            { 
                                (recipes) ?
                                    recipes.map((recipe, index) => {
                                        return (
                                            <div className="w-full p-1 px-2 mb-4 hover:bg-black hover:text-white" key={index}>
                                                <div className="flex w-full justify-between items-center pb-2">
                                                    <small className="text-left text-gray-400 block">A9 Recipes: {recipe.category?.name}</small>
                                                    <button
                                                        className="text-xs w-5 ml-1 text-black"
                                                        onClick={() => { handleRemoveSavedRecipe(recipe) }}
                                                    >
                                                        <XCircleIcon className="w-5 h-5 text-gray-300" />
                                                    </button>
                                                </div>
                                                
                                                <div className="flex-1 border-gray-200 flex cursor-pointer" 
                                                    onClick={() => { handleClick(recipe?.recipe_id) }}
                                                >    
                                                    <div>                                                
                                                        <div className="font-medium">{ recipe.name }</div>
                                                        <div className="text-left flex-wrap text-sm flex-shrink-1 py-1">{recipe.description}</div>
                                                        <small className="text-left text-xs pb-2 block">Serves: {recipe.serves}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <p className="text-center">No Recipes Found</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}