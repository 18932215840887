/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { excludeReceiptLineWords } from '../../constants/OCRExclusions'
import { XCircleIcon, SearchIcon } from '@heroicons/react/outline'

/** components */
import MatchIngredientForm from '../../components/Forms/MatchIngredientForm'
import SelectUnitForm from '../../components/Forms/SelectUnitForm'

const ScanResultDialog = ({ showDialog, scanResult, onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [selectedStoreId, setSelectedStoreId] = useState(null)
  const [saving, setSaving] = useState(false)
  const [storeOptions, setStoreOptions] = useState([])
  const foodstores = useSelector(state => state.user_data?.user_stores)
  const sharedWithMeFoodStores = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserFoodStore'))
  const currentUsersEmail = useSelector(state => state.auth?.user?.email)
  const ingredientOptions = useSelector(state => state.form_options?.ingredient_options || [])
  const [matchItems, setMatchItems] = useState([])
  const [ingredientMatchIndex, setIngredientMatchIndex] = useState(null)

  const processLines = (lines) => {
    const lineItems = []
    const englishWordRegex = /(?:[a-zA-Z]{3,}[aeiouAEIOU]+[a-zA-Z]+)(?:\s+[a-zA-Z]{3,}[aeiouAEIOU]+[a-zA-Z]+)*/

    for (let i = 0; i < lines.length; i++) {
      const currentLine = lines[i];
      const nextLine1 = lines[i + 1];
      const nextLine2 = lines[i + 2];

      if (excludeReceiptLineWords.some(word => currentLine.text.toLowerCase().includes(word.toLowerCase()))) {
        continue
      }
  
      // Combine lines up to 3 to get the full line item text
      let fullLineItemText = [currentLine.text, nextLine1?.text, nextLine2?.text].join(' ').trim();
  
      // Remove any text after the first occurrence of a price
      const priceRegex = /\$?\d+(\.\d{1,2})?|\£?\d+(\.\d{1,2})?/
      const priceMatch = fullLineItemText.match(priceRegex)

      if (priceMatch) {
        const priceIndex = priceMatch.index;
        if (priceIndex !== undefined && priceIndex !== null) {
          fullLineItemText = fullLineItemText.substring(0, priceIndex + priceMatch[0].length).trim()
        }
      }
      else 
      {
        continue
      }      

      const itemName = fullLineItemText.replace(/[^\w\s]/g, '').trim()

      if(englishWordRegex.test(itemName) && fullLineItemText) {
        const price = priceMatch ? parseFloat(priceMatch[0]) : null

        // Extracting SKU
        const skuRegex = /\d5,}/
        const skuMatch = fullLineItemText.match(skuRegex)
        const sku = skuMatch ? skuMatch[0] : null

        // Extracting currency (supporting $ and pound symbol)
        const currencyRegex = /(\$|£)/
        const currencyMatch = fullLineItemText.match(currencyRegex)
        const currency = currencyMatch ? currencyMatch[0] : null

        lineItems.push({
          name: itemName,
          matchedItem: null,
          quantity: 1,
          unit: 'unit',
          price,
          currency,
          sku,
          checked: false
        })
      }      
    }

    setMatchItems(lineItems)
  }

  const matchItemToIngredient = () => {
    return 'No Matches Found.'
  }

  /**
   * Hooks
   */
  useEffect(() => {
    setIsOpen(showDialog) 
  }, [showDialog])

  useEffect(() => {
    if(scanResult?.data?.lines?.length > 0) {
      processLines(scanResult?.data?.lines)
    }
  }, [scanResult])

  useEffect(() => {
    if(foodstores && foodstores.length > 0) {
        const foodstoresCopy = [...foodstores]
        const foodStoreOptions = foodstoresCopy.map(i => { return { value: i.id, name: i.name } })

        let editableSharedWithMeStores = []

        if(sharedWithMeFoodStores && sharedWithMeFoodStores.length > 0 && currentUsersEmail) {
            editableSharedWithMeStores = [...sharedWithMeFoodStores].filter((item) => {
                let canEdit = false

                for(const user of item?.shared_with) {
                    if(user?.email === currentUsersEmail && user?.canEdit) {
                        canEdit = true
                        break
                    }
                }

                return canEdit
            }).map(i => { return { value: i.id, name: i.name } })
        }
        const mergedOptions = [...foodStoreOptions, ...editableSharedWithMeStores]
        setStoreOptions(mergedOptions)
    }
}, [foodstores, currentUsersEmail])


  /**
   * Handlers
   */
  const handleFindItem = (item, index) => {
    setIngredientMatchIndex(index)
  }

  const handleStoreSelect = (e) => {
    setSelectedStoreId(e.target.value)
  }

  const handleRemoveItem = (item) => {    
    const matchItemsCopy = [...matchItems]        
    setMatchItems(matchItemsCopy.filter(i => i.name !== item.name))
  }

  const handleOnselectUnit = (newValue, item) => {
    const matchItemsCopy = [...matchItems]
    const indexToUpdate = matchItemsCopy.findIndex(i => item?.name === i?.name)
    
    matchItemsCopy[indexToUpdate]['unit'] = newValue
    setMatchItems(matchItemsCopy)
  }

  const handleOnUpdateQuantity = (newValue, item) => {
    const matchItemsCopy = [...matchItems]
    const indexToUpdate = matchItemsCopy.findIndex(i => item?.name === i?.name)

    matchItemsCopy[indexToUpdate].quantity = newValue
    setMatchItems(matchItemsCopy)
  }

  const handleOnSelectItem  = (newValue, option, item, index) => {
    const matchItemsCopy = [...matchItems]
    const indexToUpdate = matchItemsCopy.findIndex(i => item?.name === i?.name)
    matchItemsCopy[indexToUpdate].matchedItem = option

    setMatchItems(matchItemsCopy)
    setIngredientMatchIndex(null)
  }

  const handleOnCheckItem = (item) => {

  }

  const handleCreateCustomItem = (newValue, item, index) => {

  }

  const handleOnSearchFocus = () => {

  }

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div className="fixed bottom-3 px-2">
        <div className="bg-white flex flex-col p-4 rounded-md shadow-md w-full z-50 h-[80vh]">
          <h2 className="text-xl font-semibold mb-4">Scan Results</h2>
          <p className="text-black text-xs mb-2">Review and match items to add to your stores</p>

          <ul className="overflow-y-auto px-2">
            {
              matchItems && matchItems?.length > 0 
                ? matchItems.map((item, i) => (
                  <li className="border-b py-2" key={i}>
                    <div>
                      {/* {item?.currency} {item?.price} */}
                      <div className="flex justify-between">
                        <small className="text-xs text-black opacity-60">Result: {item?.name}</small>

                        <button
                            className="text-xs w-4 ml-auto text-black block mb-2"
                            onClick={() => { handleRemoveItem(item) }}
                        ><XCircleIcon className="w-4 h-4" /></button>
                      </div>
                      <div className="flex">
                        <div className={`flex-shrink-0 ${!item?.matchedItem && 'opacity-40'}`}>
                            <input 
                                className={`border w-12 py-0 border-gray-400 text-black text-center text-sm rounded-t`} 
                                id="quantity" 
                                type="number"
                                min="1"
                                disabled={!item?.matchedItem}
                                onChange={(e) => { handleOnUpdateQuantity(e.target.value, item) } }
                                value={item?.quantity || 1} 
                            />
                            <div className="w-12">
                                <SelectUnitForm 
                                    onSelectUnit={(newValue) => {handleOnselectUnit(newValue, item)}} 
                                    defaultUnit={item?.default_unit} 
                                    userUnit={item?.unit}
                                    canEdit={true} 
                                />
                            </div>
                        </div>

                        <label className="flex-grow flex items-center px-2 text-sm">
                          <span>{item?.matchedItem?.name || matchItemToIngredient(item?.name)}</span>
                        </label>

                        <div className="flex justify-end items-center">
                          <button
                              className="text-xs w-4 mr-2 text-black"
                              onClick={() => { handleFindItem(item, i) }}
                          ><SearchIcon className="w-4 h-4" /></button>

                          <input
                              disabled={!item?.matchedItem} 
                              onChange={(e) => { handleOnCheckItem(item) }}
                              checked={item?.checked && item?.matchedItem} 
                              type="checkbox" 
                              value={item?.name}
                              className={`w-4 h-4 text-black bg-white border-black rounded ${!item?.matchedItem && 'opacity-20'}`} 
                          />
                        </div>
                      </div>
                    </div>
                    {
                      ingredientMatchIndex === i &&
                        <MatchIngredientForm
                            label={item?.name}
                            options={ingredientOptions}
                            selectedOptions={[]}  
                            onSelectItem={(newValue, option) => { handleOnSelectItem(newValue, option, item, i) }}
                            handleOnSelectSearchFocus={handleOnSearchFocus}
                            showOptions={true}
                            showCreateCustom={true}
                            onCreateCustomItem={(newValue) => { handleCreateCustomItem(newValue, item, i) }}
                        />
                    }
                  </li>))
                : <li>Scan Failed</li>
            }
          </ul>

          <div className="pt-1 mt-2 border-t border-black">
              <label className="block py-2 text-xs w-full">Store Items</label>
              <select
                  disabled={saving} 
                  id="sendToStore"
                  onChange={(e) => { handleStoreSelect(e)} }
                  className="border border-black text-black text-sm rounded block w-full p-2"
              >
                  <option value="">Select ...</option>
                  {
                      storeOptions.map((option, i) => {
                          return (
                              <option key={i} value={option.value}>{option.name}</option>
                          )
                      })
                  }
              </select>
          </div>
        </div>

        <div className="flex justify-between mt-2">
            <button
              className="text-black bg-white mx-2 rounded font-medium border border-black text-sm px-5 py-2"              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="text-white bg-black mx-2 rounded font-medium border border-black text-sm px-5 py-2"              onClick={handleConfirm}
            >
              Confirm
            </button>
        </div>
        
      </div>
    </div>
  )
}

export default ScanResultDialog
