import React, { useState, useEffect } from 'react'
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'
import useStoreNotification from '../../hooks/useStoreNotification'
import { EyeIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

export default function Notifcations() {
  const [loading, setLoading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [showDanger, setShowDanger] = useState(false)
  const { storeNotificationLoadingState, storeNotifications } = useStoreNotification()

  useEffect(() => { console.warn(storeNotifications) }, [storeNotificationLoadingState, storeNotifications])

  if(storeNotificationLoadingState) {
    return (<div className="h-[60vh] relative pt-10"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
      <Card title="Notifications">
        <div className="h-[75vh]">
          <div className="min-w-full justify-between ">
          {
            storeNotifications?.danger && storeNotifications?.danger?.length > 0 && (
              <h2 className="flex justify-between text-md p-2 rounded bg-red-200 border-red-800">
                <span className="text-red-800">{storeNotifications?.danger?.length} Items(s) have Expired</span>
                <button
                  onClick={() => { setShowDanger(!showDanger) }}
                  className="text-xs w-6 h-6 px-1 text-gray-400 rounded-full"
                >
                  {showDanger ? <ChevronUpIcon className="text-red-800" /> : <ChevronDownIcon className="text-red-800" />}
                  
                </button>
              </h2>
            )
          }
          {
            storeNotifications?.danger && storeNotifications?.danger?.length > 0 && showDanger &&
              storeNotifications?.danger?.map((notification, i) => {
                      return (
                          <div key={i}>
                              <div key={i} className="flex justify-between px-2 items-center">
                                  <div>
                                      <div className="flex items-center">
                                          <label className="block text-red-800 font-light text-sm mr-1 mt-1">{notification?.message}</label>
                                      </div>
                                  </div>

                                  <div className="text-center">
                                      <button
                                          className="text-xs w-6 h-6 px-1 text-gray-400 rounded-full"
                                      >
                                      </button>
                                  </div>
                              </div>
                          </div>
                      )
                  })
          }


          {
            storeNotifications?.warning && storeNotifications?.warning?.length > 0 && (
              <h2 className="flex justify-between text-md p-2 rounded bg-orange-200 border-orange-800">
                <span className="text-orange-800">{storeNotifications?.warning?.length} Items(s) have Expired</span>
                <button
                  onClick={() => { setShowWarning(!showWarning) }}
                  className="text-xs w-6 h-6 px-1 rounded-full"
                >
                  {showDanger ? <ChevronUpIcon className="text-orange-800" /> : <ChevronDownIcon className="text-orange-800" />}
                  
                </button>
              </h2>
            )
          }
          {
            storeNotifications?.warning && storeNotifications?.warning?.length > 0 && showWarning &&
              storeNotifications?.warning?.map((notification, i) => {
                      return (
                          <div key={i}>
                              <div key={i} className="flex justify-between px-2 items-center">
                                  <div>
                                      <div className="flex items-center">
                                          <label className="block text-orange-800 font-light text-sm mr-1 mt-1">{notification?.message}</label>
                                      </div>
                                  </div>

                                  <div className="text-center">
                                      <button
                                          className="text-xs w-6 h-6 px-1 text-orange-400 rounded-full"
                                      >
                                      </button>
                                  </div>
                              </div>
                          </div>
                      )
                  })
                }
          </div>
        </div>
      </Card>
    </div>
  )
}