/** vendor */
import React, { useState, useEffect } from 'react'

export default function ConfigureIngredientsForm({onUpdate, recipe}) {
    const [focusIndex, setFocusindex] = useState(0)
    const [selectedIngredients, setSelectedIngredients] = useState([])

    /**
     * Hooks
     */
    useEffect(() => {
        if (recipe?.ingredients) {
            setSelectedIngredients(recipe?.ingredients)
        }
    }, [recipe])

    /**
     * Handlers
     */
    const handleQuantityChange = (e, i) => {
        const selectedIngredientsCopy = JSON.parse(JSON.stringify(recipe))
        selectedIngredientsCopy[i].quantity = e.target.value
        onUpdate({ingredients: selectedIngredientsCopy})
    }

    const handleMeasurementChange = (e, i) => {
        const selectedIngredientsCopy = JSON.parse(JSON.stringify(recipe))
        selectedIngredientsCopy[i].measurement = e.target.value
        onUpdate({ingredients: selectedIngredientsCopy})
    }

    return (
            <div className="min-w-full h-[60vh] pt-4">
                <div className="relative overflow-x-auto sm:rounded-lg w-full h-[58vh] overflow-y-scroll">

                    <ul role="list" className="divide-y divide-black min-w-full border-t border-b border-black">
                        {
                            selectedIngredients ? 
                                selectedIngredients.map((ingredient, index) => { return (
                                <li className={`py-2  ${ index !== focusIndex ? 'opacity-40' : 'border-x border-black' }`}>
                                    <div 
                                        className="w-full text-lg text-center text-black cursor-pointer"
                                        onClick={() => { setFocusindex(index) }}
                                    >
                                        {index !== focusIndex ? '1 kg - ' : ''} {ingredient?.name} 
                                    </div>
                                    {
                                        index === focusIndex ?
                                        <div className="flex justify-center">
                                            <div className="w-1/3 flex-grow-0">
                                                <label className="block py-2 w-24 text-xs text-center">Quantity</label>
                                                <input 
                                                    className="border w-24 border-black text-black text-center text-sm rounded" 
                                                    id="quantity" 
                                                    type="number"
                                                    min="1"
                                                    onChange={(e) => { handleQuantityChange(e, index)} }
                                                    value={ingredient?.quantity || 1} 
                                                />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="block py-2 text-xs w-24 text-center">measurement</label>
                                                <select 
                                                    id="measurement"
                                                    onChange={(e) => { handleMeasurementChange(e, index)} }
                                                    value={ingredient?.measurement || 'units'}  
                                                    className="border border-black text-black text-sm rounded block w-full p-2"
                                                >
                                                    {/* @todo */}
                                                    <option value="units">units</option>
                                                    <option value="grams">grams</option>
                                                    <option value="kilograms">kilograms</option>
                                                    <option value="litres">litres</option>
                                                    <option value="cups">cups</option>
                                                    <option value="millilitres">millilitres</option>
                                                </select>
                                            </div>
                                        </div> : ''
                                    }
                                   
                                </li>
                                )}) : <li>No Ingredients.</li>
                        }
                    </ul>
                </div>
            </div>
    )
}

