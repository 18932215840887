/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** state */
import { 
    getSavedRecipes, 
    getUserStores,
    getSharedWithMe 
} from '../actions/user.actions'

export default function useStoreNotification() {
    const [storeNotificationLoadingState, setLoading] = useState(true)
    const dispatch = useDispatch()

    const foodstores = useSelector(state => state.user_data.user_stores)
    const sharedWithMeStores = useSelector(state => state.user_data.shared_with_me.filter(m => m.type === 'UserFoodStore'))
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
    const [storeNotifications, setStoreNotifications]  = useState([])

    const getStoreNotifications = () => {
        const notifications = {
            danger: [],
            warning: [],
            info: []
        }

        const stores = [...foodstores, ...sharedWithMeStores]

        console.warn('stores', stores)
        
        stores.forEach((store, i) => {
            if (store?.items?.length > 0) {
                // @todo
                const storeItem = {
                    name: store?.name,
                    store_id: store?.id,
                    items: []
                }

                store?.items.forEach((item, i) => {
                    const twoDaysAgo = new Date()
                    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)

                    const today = new Date()
                    const addedDate = new Date(item?.created_at)
                    const expDate = new Date()

                    expDate.setDate(addedDate.getDate() + item?.estimated_expiry_days || 30)
            
                    if(expDate >= twoDaysAgo && expDate < today) {
                        notifications.warning.push({
                            message: item?.name + ' is about to expire',
                            item
                        })
                    } else if(expDate >= today) {
                        notifications.danger.push({
                            message: item?.name + ' has expired',
                            item
                        })
                    }
                })
            }
        })

        setStoreNotifications(notifications)
    }

    useEffect(() => {
        if (!storeNotificationLoadingState) {
            getStoreNotifications()
        }
    }, [storeNotificationLoadingState])

    useEffect(() => {
        const fetchData = async () => {
          try {
            await Promise.all([
              dispatch(getUserStores()),
              dispatch(getSharedWithMe())
            ]);
            
            setLoading(false)
          } catch (error) {
            console.error('Error fetching user data:', error)
            setLoading(false)
          }
        };
    
        fetchData()
      }, [dispatch])

    return { storeNotificationLoadingState, storeNotifications }
}