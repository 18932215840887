/** vendor */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PencilIcon } from '@heroicons/react/solid'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'

/** state */
import { 
  createUserStore, 
  deleteUserStore, 
  getUserStore, 
  getUserStores, 
  updateUserStore 
} from '../../actions/user.actions'

/** components */
import SearchSelectForm from '../../components/Forms/SearchSelectForm'
import ChangeStoreItemDateDialog from '../../components/Dialogs/ChangeStoreItemDateDialog'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import FoodStoreOptions from '../../components/FoodStore/FoodStoreOptions'
import SelectUnitForm from '../../components/Forms/SelectUnitForm'

export default function FoodStore() {
  // vendor utils
  const dispatch = useDispatch()
  const location = useLocation()

  // ui states
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [storeItemsEdit, setStoreItemsEdit] = useState([])

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const [focusSearch, setFocusSearch] = useState(false)
  const [showShare, setShowShare] = useState(false)

  // current food store
  const [foodstore, setFoodstore] = useState(null)
  const myFoodstore = useSelector(state => state.user_data?.user_stores.find(s => s?.id == location.pathname.split('/')[2]))
  const sharedWithMeStore = useSelector(state => state.user_data.shared_with_me.find(m => m.type === 'UserFoodStore' && m.id == location.pathname.split('/')[2]))

  // foodstore formatted for display
  const [groupedStoreItemsState, setGroupedStoreItemsState] = useState([])

  // shared with me store permissions
  const [isSharedStore, setIsSharedStore] = useState(false)
  const [canEditStore, setCanEditStore] = useState(true)
  const currentUsersEmail = useSelector(state => state.auth?.user?.email)

  // form options
  const ingredientOptions = useSelector(state => state.form_options?.ingredient_options || [])
  const categoryOptions = useSelector(state => state?.form_options?.category_options)

  /**
   * Utils
   */
  const formatDate = (dateStr) => {
    return new Date(dateStr).toDateString()
  }

  const isExpired = (dateStr, estimated_expiry_days = 14) => {
    const date = new Date(dateStr)
    const today = new Date()
    date.setDate(date.getDate() + estimated_expiry_days)
    return  date < today 
  }

  const calcExpDate = (dateStr, estimated_expiry_days = 14) => {
    const date = new Date(dateStr)
    date.setDate(date.getDate() + estimated_expiry_days)

    return date
  }

  const formatExpDate = (dateStr, estimated_expiry_days = 14) => {
    return calcExpDate(dateStr, estimated_expiry_days = 14).toDateString()
  }

  const sortByDate = (itemsArray) => {
    return [...itemsArray].sort((a, b) => {
      if (new Date(a.date_added) !== new Date(b.date_added)) {
        return new Date(a.date_added) - new Date(b.date_added)
      } else {
        return a.id - b.id
      }
    })
  }
  
  const groupAndSortItems = (items, itemToAdd) => {
    const itemsCopy = [...items]
    const foundItem = itemsCopy.find((item) => 
      item.value === itemToAdd.value && 
      item.date_added.split('T')[0] === itemToAdd.date_added.split('T')[0]
    );

    if (foundItem) {
      foundItem.quantity++
    } else {
      itemToAdd['quantity'] = 1
      itemsCopy.push(itemToAdd)
    }

    return itemsCopy.sort((a, b) => {
      if (new Date(a.date_added) !== new Date(b.date_added)) {
        return new Date(a.date_added) - new Date(b.date_added)
      } else {
        return a.id - b.id
      }
    })
  }

  const updateStoreItems = (updatesItemsArray) => {
    setStoreItemsEdit(updatesItemsArray)

    let timerId = null
    const delay = 500
  
    clearTimeout(timerId)
  
    timerId = setTimeout(() => {
      setSaving(true)

      dispatch(updateUserStore(foodstore?.id, {items: updatesItemsArray})).then(() => {
        setEditItem(null)
        setShowEditDialog(false)
        setSaving(false)
      })
    }, delay)
  }

  const setAllowedActions = () => {
    setIsSharedStore(true)

    let canEdit = false

    for(const user of sharedWithMeStore?.shared_with) {
        if(user?.email === currentUsersEmail && user?.canEdit) {
            canEdit = true
            break
        }
    }

    setCanEditStore(canEdit)
  } 

  /**
   * Hooks
   */
  useEffect(() => {
    if (sharedWithMeStore) {
      setAllowedActions()
    }

    setFoodstore(myFoodstore ?? sharedWithMeStore)
  }, [myFoodstore, sharedWithMeStore])

  useEffect(() => {
    if(foodstore?.items) {
      const listCategoryGroup = [];

      foodstore?.items.forEach((ingredient) => {
        const groupItem = { category_name: '', category_id: null, ingredients: [] }

        if (ingredient?.id?.includes('CUSTOM')) {
            // it's a user created ingredient
            groupItem.category_id = 'CUSTOM'
            groupItem.category_name = 'Custom'
        } else {
            const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
            
            if (ingredientOption?.category_id) {
                // it's a9 ingredient with a category id
                const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
                groupItem.category_id = categoryOption?.value
                groupItem.category_name = categoryOption?.name
            } else {
                // it's a9 ingredient with no category id
                groupItem.category_id = 'MISC'
                groupItem.category_name = 'Misc'
            }
        }

        if (!listCategoryGroup?.find(i => i.category_id === groupItem.category_id))
        {
            listCategoryGroup.push(groupItem)
        }
      })

      foodstore?.items.forEach((ingredient) => {         
        const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
        const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
        const listCategoryGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === categoryOption?.value);
    
        if (listCategoryGroupItemIndex > -1) {
            listCategoryGroup[listCategoryGroupItemIndex].ingredients.push(ingredient)
        } else {
            if (ingredient?.id?.includes('CUSTOM')) {
                const customGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'CUSTOM');
                listCategoryGroup[customGroupItemIndex].ingredients.push(ingredient)
            }
            else if (!ingredient?.category_id) {
                const miscGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'MISC');
                listCategoryGroup[miscGroupItemIndex].ingredients.push(ingredient)
            }
        }
      })

      setGroupedStoreItemsState(listCategoryGroup)

      const items = foodstore?.items.sort((a, b) => {
        if (new Date(a.date_added) !== new Date(b.date_added)) {
          return new Date(a.date_added) - new Date(b.date_added)
        } else {
          return a.id - b.id
        }
      })

      setStoreItemsEdit(items)
    }
  }, [foodstore])
    
  useEffect(() => {
    setLoading(true)
    // location.pathname.split('/')[2]
    dispatch(getUserStores()).then(() => {
      setLoading(false)
    })
  }, [])

  /**
   * Handlers
   */
  const handleOnselectUnit = (newValue, item) => {
    const storeItems = [...storeItemsEdit]

    const indexToUpdate = storeItems.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)
    
    storeItems[indexToUpdate]['unit'] = newValue
    updateStoreItems(storeItems)
  }

  const handleShowShareDialog = () => {
    setShowShare(true)
  }

  const handleOnUpdateQuantity = (newValue, item) => {
    const storeItems = [...storeItemsEdit]
    const indexToUpdate = storeItems.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)

    storeItems[indexToUpdate].quantity = newValue
    updateStoreItems(storeItems)
  }

  const handleOnSelectItem = (newValue, option) => {
    const itemToAdd = option
    
    itemToAdd['date_added'] = new Date().toISOString()

    const addItemArray = [...storeItemsEdit]
    const mappedItems = groupAndSortItems(addItemArray, itemToAdd)

    updateStoreItems(mappedItems)
  }
  
  const handleOnItemDateUpdate = (item, newDate) => {
    let updateItemArray = [...storeItemsEdit]

    const updateIndex = updateItemArray?.findIndex(
      i => i?.value === item?.value && i?.date_added === item?.date_added
    )

    console.warn(item, newDate, updateIndex)

    updateItemArray[updateIndex]['estimated_expiry_days'] = 100
    // updateItemArray = sortByDate(updateItemArray)

    updateStoreItems(updateItemArray)
  }

  const handleEditItem = (index, categoryIndex, item) => {
    setEditItem(item)
    setShowEditDialog(true)
  }

  const handleRemoveItem = (index, categoryIndex, ingredient) => {
    const removedItemArray = [...storeItemsEdit]
    const removeIndex = removedItemArray?.findIndex(
      i => i?.value === ingredient?.value && i?.date_added === ingredient?.date_added
    )

    if(removeIndex || removeIndex === 0) {
      removedItemArray.splice(removeIndex, 1)
      updateStoreItems(removedItemArray)
    }

  }

  const handleOnSearchFocus = () => {
    setFocusSearch(true)
  }
  
  const handleShowOptions = () => {
    setShowOptions(true)
  }

  const handleOnAction = (action, id) => {
    if (action === 'delete') {
      setLoading(true)

      dispatch(deleteUserStore(id)).then(() => {
        dispatch(getUserStores()).then(() => {
          setLoading(false)
          setShowOptions(false)
        })
      }).catch(() => {
        setLoading(false)
      })
    }

    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'default') {
      console.warn('set default')
      return
    } 
  }


  const handleOnShareUpdate = (share_with) => {
    // @todo use new share controller
    // dispatch().then(() => {
    // }).catch(() => {
    // })
  }

  if (loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }


  let storeName = `${foodstore ? foodstore?.name : 'Storage'}` 
  storeName += `${foodstore?.default ? ` - (default)` : ''}`

  return (
    <div className="pt-16 min-w-full">
      <Card   
        title={storeName}
        status={
          <>
            {
              saving &&
              <div className="animate-pulse">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
            }
            {
              canEditStore &&
                <button
                  className="text-xs h-6 w-6 text-black rounded-full"
                  onClick={ (e) => { handleShowOptions() } }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                </button>
            }
          </>
        }   
      >
        <div>
          <div className="min-w-full">
            { storeItemsEdit && foodstore?.name ? 
              <ul role="list" className="min-w-full">
                {
                  groupedStoreItemsState &&
                    groupedStoreItemsState.map((category, categoryIndex) => { 
                      if (category?.ingredients?.length < 1) {
                          return <></>
                      }

                      return (
                          <li className="py-3 sm:py-4 min-w-full" key={categoryIndex}>
                              <h2>{category?.category_name}</h2>
                              <ul role="list" className="divide-y divide-gray-200 min-w-full">
                                {
                                  category?.ingredients?.map((ingredient, index) => { 
                                    return (
                                      <li className="py-3 sm:py-4 border-b border-gray-400 min-w-full" key={index}>
                                          <div className="flex items-center space-x-4">
                                              <div className="flex-shrink-0">
                                                  <input 
                                                      className="border w-12 py-0 border-gray-400 text-black text-center text-sm rounded-t" 
                                                      id="quantity" 
                                                      type="number"
                                                      min="1"
                                                      disabled={!canEditStore}
                                                      onChange={(e) => { handleOnUpdateQuantity(e.target.value, ingredient) } }
                                                      value={ingredient?.quantity || 1} 
                                                  />
                                                  <div className="w-12">
                                                    <SelectUnitForm 
                                                      onSelectUnit={(newValue) => {handleOnselectUnit(newValue, ingredient)}} 
                                                      defaultUnit={ingredient?.default_unit} 
                                                      userUnit={ingredient?.unit}
                                                      canEdit={canEditStore} 
                                                    />
                                                  </div>
                                              </div>
                                              <div className="flex-1 min-w-0">
                                                  <p className={`${isExpired(ingredient?.date_added, ingredient?.estimated_expiry_days) ? 'text-red-800' : 'text-gray-500'} font-medium text-black`}>
                                                      {ingredient.name}
                                                  </p>

                                                  <small className={`${isExpired(ingredient?.date_added, ingredient?.estimated_expiry_days) ? 'text-red-800' : 'text-gray-500'} text-xs flex`}>
                                                    <span>expires - {formatExpDate(ingredient?.date_added, ingredient?.estimated_expiry_days)} </span>
                                                    {
                                                      canEditStore &&
                                                        <PencilIcon 
                                                          className="h-3 w-3 text-gray-200 ml-2 mt-[1px] cursor-pointer"  
                                                          onClick={() => { handleEditItem(index, categoryIndex, ingredient) }} 
                                                        />
                                                    }
                                                  </small>
                                              </div>
                                              {
                                                canEditStore &&
                                                  <div className="inline-flex items-center text-base font-semibold text-black">
                                                    <XCircleIcon 
                                                      className="h-5 w-5 text-black mr-2 mt-1 cursor-pointer" 
                                                      onClick={() => { handleRemoveItem(index, categoryIndex, ingredient) }} 
                                                    />
                                                  </div>
                                              }
                                          </div>
                                      </li>              
                                    )
                                  }) 


                                }
                              </ul>
                          </li>
                      )
                    })
                }
              </ul> : <></>
            }
          </div>
        </div>
      </Card>

      {
        canEditStore &&
          <div className={`${focusSearch ? 'h-screen bg-black' : ''} fixed bg-opacity-30 bottom-0 left-0 w-screen flex justify-center items-end pb-4 z-40`}>
            <div className="w-screen">
                <div className="fixed left-0 bottom-0 w-full">
                    {
                      focusSearch ?                                            
                          <button 
                              className="rounded-full w-8 h-8 mb-4 mr-4 bg-white text-black block ml-auto"
                              onClick={(e) => { setFocusSearch(false) }}
                          >&times;</button>
                          : ''
                    }

                    <SearchSelectForm 
                        label="Add Items"
                        options={ingredientOptions}
                        selectedOptions={storeItemsEdit} 
                        onSelectItem={(newValue, option) => { handleOnSelectItem(newValue, option) }}
                        handleOnSelectSearchFocus={handleOnSearchFocus}
                        showOptions={focusSearch}
                    />
                </div> 
            </div> 

            <ChangeStoreItemDateDialog 
              onUpdate={(item, newDate) => handleOnItemDateUpdate(item, newDate)}
              showDialog={showEditDialog} 
              item={editItem} 
              currentExpiryDate={calcExpDate(editItem?.date_added, editItem?.estimated_expiry_days)} 
              onCancel={() => { setShowEditDialog(false) }}
            />

            <ShareDialog
              showDialog={showShare}
              title={`Share my store`}
              shareWith={foodstore?.shared_with || []}
              entityId={foodstore?.id}
              entityType="UserFoodStore"
              onUpdate={handleOnShareUpdate}
              onCancel={() => { setShowShare(false) }}
            />

            <FoodStoreOptions
              isSharedWithUser={isSharedStore}
              listId={foodstore?.id}
              title={foodstore?.name}
              isDefault={foodstore?.default} 
              showDialog={showOptions}
              onAction={handleOnAction}
              onCancel={() => { setShowOptions(false) }}
            />
          </div>
      }
    </div>
  )
}