/** vendor */
import React, { useEffect, useState } from 'react'

const ChangeStoreItemDateDialog = ({
    showDialog = false,
    item,
    currentExpiryDate = new Date(),
    onUpdate = () => { },
    onCancel = () => { } 
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [newDateAdded, setNewDateAdded] = useState('')

    if(currentExpiryDate && showDialog) {
        console.warn(currentExpiryDate)

        setIsOpen(showDialog)

        const exp = new Date( 
            currentExpiryDate.getTime() + Math.abs(currentExpiryDate.getTimezoneOffset()*60000) 
        ).toISOString().split('T')[0]
   
        setNewDateAdded(exp)  
    }

    /**
     * Hooks
     */
    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    /**
     * Handlers
     */
    const handleChange = (e) => {
        setNewDateAdded(e.target.value)
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    const handleConfirm = () => {
        setIsOpen(false)
        onUpdate(item, newDateAdded)
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'} items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="bg-white p-8 fixed bottom-3 rounded-md shadow-md w-[95vw] z-50">
                <button
                    className="rounded-full w-8 h-8 absolute top-[-50px] right-0 bg-white text-black block ml-auto z-50 mr-2"
                    onClick={(e) => { handleCancel(e) }}
                >&times;</button>

                <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">{item?.name}</h2>

                <div className="flex min-w-full justify-center">
                    <div className="w-full">
                        <div className="w-full">
                            <label htmlFor="date_added" className="block mb-2 text-sm font-medium text-black">
                                Update Expiry 
                            </label>
                            <div className="relative flex">
                                <input
                                    type="date"
                                    id="date_added"
                                    value={newDateAdded}
                                    onChange={handleChange}
                                    className="border border-black text-black text-sm rounded block w-full p-2.5"
                                />

                                <button
                                    className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                                    onClick={handleConfirm}
                                >
                                    Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeStoreItemDateDialog
