/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import Loading from '../UI/Loading'
import { removeImportedRecipe } from '../../services/user.service'

/** state */
import { getImportedRecipes } from '../../actions/user.actions'

/** components */
import ImportedRecipeListItem from './ImportedRecipeListItem'
import ImportForm from '../Forms/ImportForm'

export default function ImportedRecipeList() {
    const dispatch = useDispatch()

    const recipes = useSelector(state => state?.user_data?.imported_recipes)

    const [loading, setLoading] = useState(false);
    
    /**
     * Utils
     */
    const getRecipes = () => {
        setLoading(true)

        dispatch(getImportedRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    /**
     * Hooks
     */
    useEffect(() => {
        getRecipes()
    }, [])

    /**
     * Handlers
     */
    const handleOnImportSuccess = () => {
        getRecipes()
    }

    const handleOnImportFail = () => {
        getRecipes()
    }

    const handleOnRemoveImportedRecipe = (importedRecipe) => {
        setLoading(true)
        removeImportedRecipe(importedRecipe.import_recipe_id).then(() => {
            getRecipes()
        }).catch(() => { 
            setLoading(false) 
        })
    }


    if(loading) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <div className="w-full">
            <section className="antialiased text-black px-4">
                <div className="flex flex-col items-center justify-center">
                    <div className="p-3 w-full lg:w-2/3">
                        <ImportForm 
                            onImportSuccess={handleOnImportSuccess} 
                            onImportFail={handleOnImportFail} 
                        />

                        <div className="overflow-x-auto">
                            { 
                                (recipes) ?
                                    recipes.map((r, index) => {
                                        return (
                                            <div key={index}>
                                                <ImportedRecipeListItem   
                                                    importedRecipe={r}
                                                    onHandleRemove={handleOnRemoveImportedRecipe} 
                                                />
                                            </div> 
                                        )
                                    })
                                    : <p className="text-center">...</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}