/** vendor */
import React, { useState } from 'react'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'


export default function Contact() {
  const [loading, setLoading] = useState(false)

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
        <Card title="Contact" autoHeight="true">
          <div className="w-full flex flex-col items-center" style={{ height: 'calc(100vh - 140px)' }}>

          </div>
        </Card>
    </div>
  )
}