/** vendor */
import React from 'react'

/** lib */
import { Card } from '../../components/UI/Card'

/** components */
import RegisterForm from '../../components/Forms/RegisterForm'
import Logo from '../../components/UI/Logo'

export default function Register() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black">
      <div className="pt-4"><Logo /></div>
      <h1 className="block text-white text-2xl font-light text-center w-full mb-4">Register</h1>
      <hr /> 
      <RegisterForm />  
    </div>
  )
}